import React, { useEffect, useState, useRef } from "react";
import { Grid, ThemeProvider } from "@material-ui/core";
import { lightTheme } from "../../../Themes/LightTheme";
import RoleMaster from "./MasterStructure/RoleMaster";
import FinYearMaster from "./MasterStructure/FinYearMaster";
import Loading from "../../../Components/Loader/Loader";

import Switch from "@material-ui/core/Switch";
import { useSelector, useDispatch } from "react-redux";
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";
import { saveLock, getLock } from "../../../Redux/APIs/api_Common";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
    Button,
    makeStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
    TextField,
    Input,
    IconButton,
    CircularProgress,
} from "@material-ui/core";
export default function Miscellaneous() {
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const token = useSelector((state) => state.saveTokenReducer.token);

    const [isLoading, setIsLoading] = useState(false);
    const [currentFY, setCurrentFY] = useState(false);
    const [prevFY, setPrevFY] = useState(false);
    const [nextFY, setNextFY] = useState(false);
    const [currentMonth, setCurrentMonth] = useState(false);
    const [lastMonth, setLastMonth] = useState(false);
    const [allMonth, setAllMonth] = useState(false);
    const [all, setAll] = useState(false);

    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };

    const handleClose = (e) => {
        setOpen(false);
    };
    const handleChangeAll = (e) => {
        setAll(e.target.checked);
        setCurrentFY(e.target.checked);
        setPrevFY(e.target.checked);
        setNextFY(e.target.checked);
        setCurrentMonth(e.target.checked);
        setLastMonth(e.target.checked);
        setAllMonth(e.target.checked);
    };
    const handleChangeCurrentFY = (e) => {
        setCurrentFY(e.target.checked);
    };
    const handleChangePrevFY = (e) => {
        setPrevFY(e.target.checked);
    };
    const handleChangeNextFY = (e) => {
        setNextFY(e.target.checked);
    };
    const handleChangeCurrentMonth = (e) => {
        setCurrentMonth(e.target.checked);
    };
    const handleChangeLastMonth = (e) => {
        setLastMonth(e.target.checked);
    };
    const handleChangeAllMonth = (e) => {
        setAllMonth(e.target.checked);
    };
    function getLocking() {
        getLock(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId
        )
            .then((response) => {
                console.log(response);
                setCurrentFY(response.data.result[0].isPotBudLockedForCurrentFy);
                setPrevFY(response.data.result[0].isPotBudLockedForPreviousFy);
                //setNextFY(response.next);
                setCurrentMonth(response.data.result[0].isMonthlyActualsOpenForCurrentMonth);
                setLastMonth(response.data.result[0].isMonthlyActualsOpenForLastMonth);
                setAllMonth(response.data.result[0].isMonthlyActualsOpenForAllMonth);
                setAll(response.data.result[0].isLocked);
            })
            .catch((error) => {
                console.log("Error: " + error, "error");
            });
    }
    function lock() {
        setIsLoading(true);
        let formData = {
            IsPotBudLockedForCurrentFy: currentFY,
            IsPotBudLockedForPreviousFy: prevFY,
            IsPotBudLockedForNextFy: nextFY,
            IsMonthlyActualsOpenForCurrentMonth: currentMonth,
            IsMonthlyActualsOpenForLastMonth: lastMonth,
            IsMonthlyActualsOpenForAllMonth: allMonth,
            IsLocked: all,
            LoggedInUserId: userDetails.userId,
        };

        saveLock(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId,
            formData
        )
            .then((response) => {
                if (response) {
                    handleSnackOpen("Data is saved successfully", "success");
                    console.log("res", response);

                    setIsLoading(false);
                } else {
                    handleSnackOpen("Error while saving data", "error");
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                setIsLoading(false);
            });
    }
    useEffect(() => {
        //if (userDetails.roleId != 1) {
        //  setFlag(false);
        //} else {
        //  setFlag(true);
        //}
        getLocking();
    }, [userDetails]);
    return (
        <div>
            <ThemeProvider theme={lightTheme}>
                {userDetails.roleId == 1 ? <h4> Locking and Unlocking</h4> : <></>}

                {userDetails.roleId == 1 ? (
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={3}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={all}
                                        onChange={handleChangeAll}
                                        name="checkedB"
                                        color="primary"
                                        fullWidth
                                    />
                                }
                                label="All"
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={currentFY}
                                        onChange={handleChangeCurrentFY}
                                        name="checkedB"
                                        color="primary"
                                        fullWidth
                                    />
                                }
                                label="Potential /Budget for current FY"
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={prevFY}
                                        onChange={handleChangePrevFY}
                                        name="checkedB"
                                        color="primary"
                                        fullWidth
                                    />
                                }
                                label="Potential Budget Previous FY"
                            />
                        </Grid>
                        {/*<Grid item xs={6} md={3}>*/}
                        {/*  <FormControlLabel*/}
                        {/*    control={*/}
                        {/*      <Switch*/}
                        {/*        checked={nextFY}*/}
                        {/*        onChange={handleChangeNextFY}*/}
                        {/*        name="checkedB"*/}
                        {/*        color="primary"*/}
                        {/*        fullWidth*/}
                        {/*      />*/}
                        {/*    }*/}
                        {/*    label="Potential Budget Next FY"*/}
                        {/*  />*/}
                        {/*</Grid>*/}
                        <Grid item xs={6} md={3}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={currentMonth}
                                        onChange={handleChangeCurrentMonth}
                                        name="checkedB"
                                        color="primary"
                                        fullWidth
                                    />
                                }
                                label="Monthly Actuals-Only For Current Month"
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={lastMonth}
                                        onChange={handleChangeLastMonth}
                                        name="checkedB"
                                        color="primary"
                                        fullWidth
                                    />
                                }
                                label="Monthly Actuals-Only For Last Month"
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={allMonth}
                                        onChange={handleChangeAllMonth}
                                        name="checkedB"
                                        color="primary"
                                        fullWidth
                                    />
                                }
                                label="Monthly Actuals-All The Months Of Current FY"
                            />
                        </Grid>
                        <Grid item xs={6} md={1}>
                            <Button className="pr-button--primary " onClick={lock} fullWidth >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                ) : (
                    <></>
                )}

                <div className="master-paperSty mt-1">
                    <div className="master-data">
                        <Grid container spacing={2} className="master-div">
                            <Grid item xs={12} md={6} className="d-flex fd-col pd-1">
                                <h4 className="d-flex jc-center">Role </h4>
                                <RoleMaster />
                            </Grid>
                            <Grid item xs={12} md={6} className="d-flex fd-col pd-1">
                                <h4 className="d-flex jc-center">FinYear </h4>
                                <FinYearMaster />
                            </Grid>
                        </Grid>
                    </div>
                </div>

                <>
                    <SnackbarCustom
                        open={open}
                        message={snackMessage}
                        alertType={alertType}
                        handleClose={handleClose}
                    />
                </>
                {isLoading ? <Loading loaderText="Loading...." /> : <></>}
            </ThemeProvider>
        </div>
    );
}
