import React, { useEffect, useState } from "react";
import {
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    InputAdornment,
    makeStyles,
    Radio,
    RadioGroup,
    TextField,
    CircularProgress,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Loading from "../../Components/Loader/Loader";
import UploadIcon from "@material-ui/icons/CloudUploadRounded";
import "./Uploads.scss";
import CustomerSampleTemplate from "../../assets/template/CustomerMasterSampleTemplate.xlsx";
import GckSampleTemplate from "../../assets/template/GckSampleTemplate.xlsx";
import SBranchSampleTemplate from "../../assets/template/SBranchSampleTemplate.xlsx";
import SEngineerSampleTemplate from "../../assets/template/SEngineerSampleTemplate.xlsx";
import STeritorySampleTemplate from "../../assets/template/STeritorySampleTemplate.xlsx";
import { useSelector } from "react-redux";
import { CustomerUpload, GCKUpload } from "../../Redux/APIs/api_Uploads";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import { getCurrentFiscalYear } from "../../Utilities/Utility";
import { getYearDDL } from "../../Redux/APIs/api_Common";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import * as XLSX from "xlsx";

const useStyles = makeStyles(() => ({
    downloadButonText: {
        color: "#008aa6",
    },
    downloadText: {
        marginRight: "10px",
    },
}));

export default function AttributeUploads(props) {
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    function getProgressComponent() {
        return (
            <h3
                style={{
                    fontStyle: "italic",
                    color: "var(--circular-progress-color)",
                    textAlign: "center",
                }}
            >
                <CircularProgress style={{ height: `25px`, width: `25px` }} />
                {" " + selectedValue + " data upload in progress...."}
            </h3>
        );
    }
    const classes = useStyles();
    let [errorData, setErrorData] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    let [inputFileValue, setInputFileValue] = useState(Date.now);
    const [selectedValue, setSelectedValue] = useState("");
    const [disable, setDisable] = useState(true);
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
        setDisable(false);
    };
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogContent, setDialogContent] = React.useState("");
    const [isLoader, setIsLoader] = useState(false);
    const handleDialogMessage = (text) => {
        setDialogContent(text);
        setDialogOpen(true);
    };
    const handleClose = (e, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        if (
            typeof dialogContent === "string" &&
            dialogContent.toLowerCase().includes("success")
        ) {
            setInputFileValue(Date.now);
            setSelectedFile(null);
        }
        setDialogOpen(false);
        setInputFileValue(Date.now);
        setErrorData([]);
    };
    const exportErrorData = () => {
        switch (selectedValue) {
            case "Sales Engineer":
                //ExportSalesEngineerDataToExcel();
                break;
            case "BU":
                //ExportBUErrorDataToExcel();
                break;
            case "Sales Office":
                //ExportSalesOfficeDataToExcel();
                break;
            default:
                return;
        }
    };
    function ExportCustomerErrorDataToExcel() {
        const dataToDownload = errorData.map((row) => {
            return {
                ifaNumber: row.ifaNumber,
                customerName: row.customerName,
                remarks: row.remarks?.replace(".", ".\n "),
            };
        });
        let headers = ["IFA Number", "Customer Name", "Remarks"];
        //const workSheet = XLSX.utils.json_to_sheet(newData);
        let ws = XLSX.utils.book_new();
        let wb = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(ws, [headers]);
        XLSX.utils.sheet_add_json(ws, dataToDownload, {
            origin: "A2",
            skipHeader: true,
            cellStyles: true,
        });
        ws["!cols"] = cellAdjustToContentsCustomer(dataToDownload);
        XLSX.utils.book_append_sheet(wb, ws, "ErrorData");
        XLSX.write(wb, { bookType: "xlsx", type: "binary" });
        //Download
        XLSX.writeFile(wb, "CustomerUploadErrors.xlsx");
    }
    function cellAdjustToContentsCustomer(data) {
        return [
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.ifaNumber ? c.ifaNumber?.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.customerName ? c.customerName?.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) => (c.remarks ? c.remarks?.toString()?.length : 0))
                ),
            },
        ];
    }
    function ExportGCKErrorDataToExcel() {
        const dataToDownload = errorData.map((row) => {
            return {
                gck: row.gck,
                bu: row.businessUnit,
                bs: row.businessSegment,
                bss: row.businessSubSegment,
                remarks: row.remarks?.replace(".", ".\n "),
            };
        });
        let headers = [
            "GCK",
            "Business Unit",
            "Business Segment",
            "Business Sub Segment",
            "Remarks",
        ];
        //const workSheet = XLSX.utils.json_to_sheet(newData);
        let ws = XLSX.utils.book_new();
        let wb = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(ws, [headers]);
        XLSX.utils.sheet_add_json(ws, dataToDownload, {
            origin: "A2",
            skipHeader: true,
            cellStyles: true,
        });
        ws["!cols"] = cellAdjustToContentsGCK(dataToDownload);
        XLSX.utils.book_append_sheet(wb, ws, "ErrorData");
        XLSX.write(wb, { bookType: "xlsx", type: "binary" });
        //Download
        XLSX.writeFile(wb, "GCKUploadErrors.xlsx");
    }
    function cellAdjustToContentsGCK(data) {
        return [
            {
                wch: Math.max(
                    ...data?.map((c) => (c.gck ? c.gck?.toString()?.length : 0))
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) => (c.bu ? c.bu?.toString()?.length : 0))
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) => (c.bs ? c.bs?.toString()?.length : 0))
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) => (c.bss ? c.bss?.toString()?.length : 0))
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) => (c.remarks ? c.remarks?.toString()?.length : 0))
                ),
            },
        ];
    }
    const updateExcelHandler = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    let [defaultyearInput, setDefaultYearInput] = useState({
        year: {},
        yearText: "",
    });
    let [yearInput, setYearInput] = useState({
        yearId: 0,
    });
    const handleYearChange = (e, obj) => {
        setDefaultYearInput({
            year: obj,
            yearText: obj?.name,
        });
        setYearInput({
            yearId: obj?.id,
        });
    };
    const handleYearInputChange = (e, text) => {
        setDefaultYearInput({
            yearText: text,
        });
        let yearId = yearData.find((x) => x.name === text)?.id;
        setYearInput({
            yearId: yearId ? yearId : 0,
        });
    };
    //Year Api

    let [yearData, setYearData] = useState([]);
    let [yearId, setYearId] = useState(0);

    async function getYearDetails() {
        let year = getCurrentFiscalYear();
        const response = await getYearDDL(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId);
        if (response && response.length > 0) {
            setYearData(response.map((item) => ({ id: item.id, name: item.name })));
            let obj = response?.find((x) => x.name == year);
            setYearId(obj.id);
            setDefaultYearInput({
                year: obj,
                yearText: obj?.name,
            });
        } else {
            setYearData([]);
        }
    }
    let [radioGroup] = useState([
        {
            name: "sales",
            value: "Sales Engineer",
            label: "Sales Engineer",
        },
        {
            name: "bu",
            value: "BU",
            label: "BU",
        },
        {
            name: "office",
            value: "Sales Office",
            label: "Sales Office",
        },
        // {
        //   name: "salesDistrict",
        //   value: "Sales District Master",
        //   label: "Sales District",
        // },
        // {
        //   name: "salesBranch",
        //   value: "Sales Branch Master",
        //   label: "Sales Branch",
        // },
        // {
        //   name: "salesTeritory",
        //   value: "Sales Teritory Master",
        //   label: "Sales Teritory",
        // },
    ]);

    function getUploadAddornment() {
        return (
            <IconButton
                title={"Upload " + selectedValue}
                //onClick={handleUploadFile}
                disabled={disable}
            >
                <UploadIcon fontSize="large" />
            </IconButton>
        );
    }
    function getDownloadAddornment() {
        return (
            <IconButton
                title={
                    selectedValue
                        ? "Sample " + selectedValue + " Template"
                        : "Select Type"
                }
                //onClick={downloadTemplate}
                disabled={disable}
            >
                <CloudDownloadIcon fontSize="large" />
            </IconButton>
        );
    }

    const downloadTemplate = (e) => {
        switch (selectedValue) {
            case "Customer Master":
                window.open(CustomerSampleTemplate);
                break;
            case "GCK Master":
                window.open(GckSampleTemplate);
                break;
            case "Sales District Master":
                window.open(SEngineerSampleTemplate);
                break;
            case "Sales Branch Master":
                window.open(SBranchSampleTemplate);
                break;
            case "Sales Teritory Master":
                window.open(STeritorySampleTemplate);
                break;
            default:
                e.preventDefault();
                return false;
        }
    };

    const handleUploadFile = (e) => {
        if (!selectedFile) {
            setIsLoader(false);
            setDialogContent("Please select a file");
            setDialogOpen(true);
        } else if (
            selectedFile &&
            !selectedFile?.type?.includes(
                "vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ) &&
            !selectedFile?.type?.includes("vnd.ms-excel")
        ) {
            setIsLoader(false);
            setDialogContent("Only .xlsx or .xls file is allowed");
            setDialogOpen(true);
        } else {
            setIsLoader(true);
            const formData = new FormData();
            formData.append("file", selectedFile);
            formData.append("UserId", userDetails.userId);
            let yearValue = yearInput.yearId ? yearInput.yearId : yearId;
            formData.append("yearId", yearValue);
            switch (selectedValue) {
                case "Customer Master":
                    CustomerUpload(siteToken,
                        azureToken.token,
                        UID.id,
                        userDetails.userEmail,
                        userDetails.gid,
                        userDetails.role,
                        userDetails.roleId,formData)
                        .then((response) => {
                            if (response.response) {
                                setIsLoader(false);
                                handleDialogMessage(response.responseMsg);
                            } else {
                                setIsLoader(false);
                                setSelectedFile(null);
                                setInputFileValue(Date.now);
                                setErrorData(response.objResult);
                                handleDialogMessage(
                                    response.responseMsgs && response.responseMsgs.length > 0
                                        ? response.responseMsgs
                                        : response.responseMsg
                                );
                            }
                        })
                        .catch((error) => {
                            setIsLoader(false);
                            setSelectedFile(null);
                            setInputFileValue(Date.now);
                            handleDialogMessage("Exception in upload");
                        });
                    break;
                case "GCK Master":
                    GCKUpload(siteToken,
                        azureToken.token,
                        UID.id,
                        userDetails.userEmail,
                        userDetails.gid,
                        userDetails.role,
                        userDetails.roleId,formData)
                        .then((response) => {
                            if (response.response) {
                                setIsLoader(false);
                                handleDialogMessage(response.responseMsg);
                            } else {
                                setIsLoader(false);
                                setSelectedFile(null);
                                setInputFileValue(Date.now);
                                setErrorData(response.objResult);
                                handleDialogMessage(
                                    response.responseMsgs && response.responseMsgs.length > 0
                                        ? response.responseMsgs
                                        : response.responseMsg
                                );
                            }
                        })
                        .catch((error) => {
                            setIsLoader(false);
                            setSelectedFile(null);
                            setInputFileValue(Date.now);
                            handleDialogMessage("Exception in upload");
                        });
                    break;
                default:
                    e.preventDefault();
                    return false;
            }
        }
    };
    useEffect(() => {
        getYearDetails();
    }, []);
    return (
        <>
            <Grid className="bulk-upload-paperSty">
                <Grid item xs={12} className="d-flex jc-center">
                    <div className="bulk-upload-block">
                        <h3 className="bulk-upload-title"> Attribute Data Upload</h3>
                    </div>
                </Grid>
                {isLoader ? (
                    getProgressComponent()
                ) : (
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl component="fieldset">
                                <RadioGroup row>
                                    {radioGroup.map((item, index) => (
                                        <FormControlLabel
                                            key={index}
                                            name={item.name}
                                            value={item.value}
                                            checked={selectedValue === item.value}
                                            control={<Radio />}
                                            label={item.label}
                                            onChange={handleChange}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <Autocomplete
                                disableClearable
                                fullWidth
                                name="year"
                                value={defaultyearInput.year}
                                inputValue={defaultyearInput?.yearText}
                                select="true"
                                loading
                                loadingText="No Options"
                                id="year"
                                freeSolo
                                margin="normal"
                                options={yearData}
                                onChange={handleYearChange}
                                onInputChange={handleYearInputChange}
                                getOptionLabel={(option) => (option.name ? option.name : "")}
                                // getOptionDisabled={(option) =>
                                //   option.name !== getCurrentFiscalYear()
                                // }
                                renderInput={(params) => (
                                    <TextField {...params} label="Fiscal Year" variant="filled" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <form className="bulk-upload-form">
                                <TextField
                                    id="fileUploadMaster"
                                    variant="filled"
                                    label={selectedValue + " Upload File"}
                                    fullWidth
                                    type="file"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment
                                                position="end"
                                                className="btn-download-template"
                                            >
                                                {getUploadAddornment()}
                                                {getDownloadAddornment()}
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={updateExcelHandler}
                                    key={inputFileValue}
                                        InputLabelProps={{ shrink: true }}
                                        disabled={disable || userDetails.roleId===10}
                                />
                            </form>
                            <span>
                                Upload file format in excel i.e .xlsx, .xls format only
                            </span>
                        </Grid>
                        {/* <Grid
            item
            xs={12}
            sm={6}
            md={3}
            className="text-center btn-download-template"
          >
            <span className={classes.downloadText}>
              {"Sample " + selectedValue + " Template"}
            </span>
            <Button
              onClick={downloadTemplate}
              disabled={disable}
              className="pt-button--secondary bulk-upload-submit-btn"
            >
              Download
            </Button>
          </Grid> */}
                    </Grid>
                )}
            </Grid>
            <div>
                <Dialog
                    open={dialogOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {selectedValue + " Data Upload Alert"}
                    </DialogTitle>
                    <DialogContent>
                        {dialogContent && typeof dialogContent === "object" ? (
                            <DialogContentText id="alert-dialog-description">
                                <h5>
                                    {dialogContent
                                        ?.filter((x, index) => index === 0)
                                        ?.map((c) => (
                                            <>&emsp;&emsp;{c.join(" ")}</>
                                        ))}
                                </h5>
                                <ol className="ol-bulkUpload-Error">
                                    {dialogContent
                                        ?.filter((x, index) => index !== 0)
                                        ?.map((c) => (
                                            <li>
                                                {c.map((x) =>
                                                    x.includes("--") && x.includes(":") ? (
                                                        x
                                                    ) : (
                                                        <ul className="ul-bulkUpload-Error">
                                                            <li>{x}</li>
                                                        </ul>
                                                    )
                                                )}
                                            </li>
                                        ))}
                                </ol>
                            </DialogContentText>
                        ) : (
                            <DialogContentText
                                id="alert-dialog-description "
                                className="dialog-center"
                            >
                                {dialogContent?.includes("successfully.") ? (
                                    <>
                                        {dialogContent?.split("successfully.")[0] +
                                            " successfully."}
                                        {dialogContent?.split("successfully.")?.length > 0 ? (
                                            <>
                                                <br />
                                                {dialogContent?.split("successfully.")[1]}
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </>
                                ) : (
                                    dialogContent
                                )}
                            </DialogContentText>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleClose}
                            className="pt-button--secondary"
                            title="Close"
                        >
                            Close
                        </Button>
                        {errorData && errorData.length > 0 ? (
                            <Button
                                onClick={exportErrorData}
                                className="pt-button--secondary"
                                title={
                                    "Export " + selectedValue + " upload error data to excel"
                                }
                            >
                                Export Error Data
                            </Button>
                        ) : (
                            <></>
                        )}
                    </DialogActions>
                </Dialog>
            </div>
            {/* {isLoader ? <Loading loaderText="Upload in progress...." /> : <></>} */}
        </>
    );
}
