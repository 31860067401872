import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Paper,
  makeStyles,
  TextField,
  MenuItem,
  ThemeProvider,
} from "@material-ui/core";
import FilterInputs from "./InputComponents/FilterInputs";
import { lightTheme } from "../../Themes/LightTheme";
import Dashboard from "./InputComponents/Dashboard";
import "./OrderInput.scss";
import PotentialGrid from "./InputComponents/PotentialGrid";
import BudgetGrid from "./InputComponents/BudgetGrid";
import MonthlyGrid from "./InputComponents/MonthlyGrid";
import { getSalesOrderInputData } from "../../Redux/APIs/api_OrderInputDetails";
import { getMonthDDL, getYearDDL } from "../../Redux/APIs/api_Common";
import Loader from "../../Components/Loader/Loader";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  getCurrentFiscalYear,
  getRoundUptoXDecimals,
  getURLParameter,
} from "../../Utilities/Utility";
import { Alert } from "@material-ui/lab";
const Buffer = require('buffer').Buffer;
export default function OrderInput(props) {
  const token = useSelector((state) => state.saveTokenReducer.token);
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
  let [potentialData, setPotentialData] = useState([]);
  let [budgetData, setBudgetData] = useState([]);
  let [alertTexts, setAlertTexts] = useState({});
  let [isLoader, setIsLoader] = useState(false);
  const useStyles = makeStyles(() => ({
    paperSty: {
      padding: "10px",
      marginTop: "40px",
    },
    childGrid: {
      //marginTop: "10px",
    },
    btnGrid: {
      justifyContent: "center",
      marginTop: "2rem",
    },
  }));
  const classes = useStyles();

  let [filters, setFilters] = useState({
    yearId: parseInt(getURLParameter("yearId")),
    customer: [
      {
        id: parseInt(getURLParameter("customerId")),
        name: getURLParameter("customer"),
      },
    ],
    gck: [
      { id: parseInt(getURLParameter("gckId")), name: getURLParameter("gck") },
    ],
    engineer: [
      {
        id: parseInt(getURLParameter("sdistrictId")),
        name: getURLParameter("sdistrict"),
      },
    ],
  });

  let [FYs, setFYs] = useState([]);
  function getFYs(token) {
      getYearDDL(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId)
      .then((response) => {
        setFYs(response);
      })
      .catch((error) => {
        console.log("Error : ", error);
      });
  }
  let [months, setMonths] = useState([]);
  function getMonths(token) {
      getMonthDDL(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId)
      .then((response) => {
        setMonths(response);
      })
      .catch((error) => {
        console.log("Error : ", error);
      });
  }
  useEffect(() => {
    if (token) {
      getFYs(token);
      getMonths(token);
    }
  }, [token]);

  function generateAlertText(potentialData, budgetData) {
    let ret = budgetData.map((c) => {
      let obj = potentialData.find((x) => x.yearId === c?.yearId);
      return {
        year: FYs?.find((x) => x.id === c?.yearId)?.name,
        flag: c?.total > obj?.total,
        sow:
          obj?.total > 0
            ? getRoundUptoXDecimals((c?.total / obj?.total) * 100, 3)
            : 0,
      };
    });
    let alertPotBud = "";
    let alertSOW = "";
    if (ret?.find((x) => !x.flag)) {
      ret?.map((c) => {
        if (c?.flag) {
          alertPotBud = "Please Note - Budget > Potential for FY " + c?.year;
        }
        return c;
      });
    } else {
      alertPotBud =
        "Please Note - Budget > Potential for both FY " +
        ret[0]?.year +
        " & " +
        ret[1]?.year;
    }
    if (
      ret.length === 2 &&
      ret?.find((x) => x.year === getCurrentFiscalYear())?.sow <
        ret?.find((x) => x.year !== getCurrentFiscalYear())?.sow
    ) {
      alertSOW =
        "Please note - Share Of Wallet has fallen. %SOW FY " +
        ret[0]?.year +
        " < %SOW FY " +
        ret[1]?.year;
    }
    setAlertTexts({
      ...alertTexts,
      potbudAlert: alertPotBud,
      sowAlert: alertSOW,
    });
  }
  useEffect(() => {
    if (potentialData?.length > 0 && budgetData?.length > 0) {
      generateAlertText(potentialData, budgetData);
    }
  }, [potentialData, budgetData]);

  return (
    <div>
      <ThemeProvider theme={lightTheme}>
        {isLoader ? (
          <Loader />
        ) : (
          <>
            <div className="order-input-paperSty">
              <h3 className="text-center m-0 page-header">Detailed View</h3>
              <div className="filter-grid">
                <Grid container item xs={12} className="d-flex jc-center ">
                  <FilterInputs filters={filters} changeFilters={setFilters} />
                </Grid>
              </div>
              <div className="order-input-div">
                <Grid container spacing={2} className="">
                  <Grid item xs={12} md={4} className="d-flex fd-col pd-1">
                    <h4 className="d-flex jc-center">Potential</h4>
                    <PotentialGrid
                      fys={FYs}
                      filters={filters}
                      setPotentialData={setPotentialData}
                    />
                  </Grid>
                  <Grid item xs={12} md={5} className="d-flex fd-col pd-1">
                    <h4 className="d-flex jc-center">Budget</h4>
                    <BudgetGrid
                      fys={FYs}
                      filters={filters}
                      setBudgetData={setBudgetData}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} className="d-flex jc-center">
                    <Dashboard filters={filters} />
                  </Grid>
                  {alertTexts?.potbudAlert ? (
                    <Grid item xs={12} sm={6} className="jc-center">
                      <Alert
                        severity="error"
                        style={{ fontWeight: "bold" }}
                        className="jc-center text-italic"
                      >
                        {alertTexts?.potbudAlert}
                      </Alert>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  {alertTexts?.sowAlert ? (
                    <Grid item xs={12} sm={6} className="jc-center">
                      <Alert
                        severity="error"
                        style={{ fontWeight: "bold" }}
                        className="jc-center text-italic"
                      >
                        {alertTexts?.sowAlert}
                      </Alert>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Grid item xs={12} className="d-flex fd-col pd-1">
                    <h4 className="d-flex jc-center">Monthly View</h4>
                    <MonthlyGrid months={months} filters={filters} />
                  </Grid>
                </Grid>
              </div>
            </div>
          </>
        )}
      </ThemeProvider>
    </div>
  );
}
