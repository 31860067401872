import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MaterialTable, { MTableBody, MTableHeader } from "material-table";
import Grid from "@material-ui/core/Grid";
import {
  tableIconsInputScreen,
  thInputCurrentFY,
  thInputPreviousFY,
  tdInputCurrentFY,
  tdInputPreviousFY,
  getCurrentFiscalYear,
  getRoundUptoXDecimals,
  tdTotalInPut,
} from "../../../Utilities/Utility";
import {
  makeStyles,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {
  getSalesOrderInputData,
  updateManualIndirect,
} from "../../../Redux/APIs/api_OrderInputDetails";
import { InsertEmoticon } from "@material-ui/icons";
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";
import { storeUpdateManualIndirectResponse } from "../../../Redux/Actions/actions";

export default function MonthlyGrid(props) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.saveTokenReducer.token);
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const id = JSON.parse(localStorage.getItem("toggle"));
    const accessable = useSelector((state) => state.isEditReducer.editables);
  const useStyles = makeStyles(() => ({
    tableHeadPreviousFY: thInputPreviousFY,
    tableHeadCurrentFY: thInputCurrentFY,
    tableCellPreviousFY: tdInputPreviousFY,
    tableCellCurrentFY: tdInputCurrentFY,
    tableTotalInput: tdTotalInPut,
  }));
  const classes = useStyles();
  let [data, setData] = useState([]);
  let [totalData, setTotalData] = useState([]);
  let [months, setMonths] = useState([]);
  let [currentFY, setCurrentFY] = useState("");
  let [previousFY, setPreviousFY] = useState("");
  let [isLoader, setIsLoader] = useState(true);

  //for Snack Alert
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpen(true);
  };
  const handleClose = (e) => {
    setOpen(false);
  };
  function renderMonthValue(value) {
    let obj = {};
    if (months && months.length > 0) {
      obj = months.find((c) => c.id === value);
    }
    if (obj) {
      return obj?.name?.toString()?.toUpperCase()?.substr(0, 3);
    } else {
      return "";
    }
  }
  function renderTotalActualsPrevious(data) {
    return getRoundUptoXDecimals(((data?.directPrevious) + (data?.indirectPrevious)),3);
  }
  function renderTotalIndirectCurrent(data) {
    return getRoundUptoXDecimals(((data?.o2OIndirectCurrent) + (data?.manualIndirectCurrent)),3);    
  }
  function renderTotalActualsCurrent(data) {
    return (   
      <>        
    {getRoundUptoXDecimals(((data?.directCurrent) +
     ( data?.o2OIndirectCurrent) +
     ( data?.manualIndirectCurrent)),3)}
     </>
    );
  }
  var columns = [
    {
      title: "MonthlyActualsId",
      field: "monthlyOrderActualsId",
      hidden: true,
    },
    {
      title: "SalesOrderId",
      field: "salesOrderDetailsId",
      hidden: true,
    },
    {
      title: "Month",
      field: "monthId",
      editable: "never",
      render: (rowData) => <>{renderMonthValue(rowData.monthId)}</>,
      cellStyle: tdInputCurrentFY,
      headerStyle: thInputCurrentFY,
    },
    {
      title: "Direct",
      field: "directPrevious",
      editable: "never",
      cellStyle: tdInputPreviousFY,
      headerStyle: thInputPreviousFY,
    },
    {
      title: "Indirect",
      field: "indirectPrevious",
      editable: "never",
      cellStyle: tdInputPreviousFY,
      headerStyle: thInputPreviousFY,
    },
    {
      title: "Total",
      field: "total",
      editable: "never",
      render: (rowData) => (<span>{renderTotalActualsPrevious(rowData)}</span>),
      cellStyle: tdInputPreviousFY,
      headerStyle: thInputPreviousFY,
    },
    {
      title: "Direct",
      field: "directCurrent",
      editable: "never",
      cellStyle: tdInputCurrentFY,
      headerStyle: thInputCurrentFY,
    },
    {
      title: "O2O Upload",
      field: "o2OIndirectCurrent",
      editable: "never",
      cellStyle: tdInputCurrentFY,
      headerStyle: thInputCurrentFY,
    },
    {
      title: "Manual Entry",
      field: "manualIndirectCurrent",
      type: "number",
      cellStyle: {
        minWidth: "12rem",
      },
      headerStyle: {
        minWidth: "12rem",
      },
    },
    {
      title: "Total Indirect",
      field: "totalIndirect",
      editable: "never",
      render: (rowData) => (<span>{renderTotalIndirectCurrent(rowData)}</span>),
      cellStyle: tdInputCurrentFY,
      headerStyle: thInputCurrentFY,
    },
    {
      title: "Total",
      field: "totalNew",
      editable: "never",
      render: (rowData) => <>{renderTotalActualsCurrent(rowData)}</>,
      cellStyle: tdInputCurrentFY,
      headerStyle: thInputCurrentFY,
    },
  ];
  const handleRowUpdate = (newData, oldData, resolve, reject) => {
    let dataToSend = {
      monthlyOrderActualsId: newData.monthlyOrderActualsId,
      salesOrderDetailsId: newData.salesOrderDetailsId,
      monthId: newData.monthId,
      manualIndirect: newData.manualIndirectCurrent,
      loggedInUserId: userDetails.userId,
      };
      if (newData.manualIndirectCurrent != "") {
          updateManualIndirect(siteToken,
              azureToken.token,
              UID.id,
              userDetails.userEmail,
              userDetails.gid,
              userDetails.role,
              userDetails.roleId, dataToSend)
              .then((response) => {
                  if (response.response) {
                      handleSnackOpen(response.responseMsg, "success");
                      resolve();
                      setIsLoader(false);
                      dispatch(storeUpdateManualIndirectResponse(response.response));
                      getDataOnLoad(props.filters);
                  } else {
                      handleSnackOpen(response.responseMsg, "error");
                      reject();
                      setIsLoader(false);
                  }
              })
              .catch((error) => {
                  handleSnackOpen("Exception : " + error, "error");
                  reject();
                  setIsLoader(false);
              });
      }
      else {
          
          handleSnackOpen("Monthly Indirect cannot be empty", "error");
          reject();
          setIsLoader(false);
      }
  };
  function getMonths(data) {
    setMonths(data);
  }
  function getCustomTHead() {
    return (
      <TableHead>
        <TableRow>
          <TableCell colSpan={2} rowSpan={2} align="center"></TableCell>
          <TableCell
            className={classes.tableHeadPreviousFY}
            colSpan={3}
            rowSpan={2}
            align="center"
            style={{
              fontWeight: "bold",
              verticalAlign: "top",
            }}
          >
            {previousFY}
          </TableCell>
          <TableCell
            className={classes.tableHeadCurrentFY}
            colSpan={5}
            align="center"
            style={{
              fontWeight: "bold",
              verticalAlign: "top",
              borderBottom: "none",
            }}
          >
            {currentFY}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            colSpan={1}
            align="center"
            className={classes.tableHeadCurrentFY}
          ></TableCell>
          <TableCell
            className={classes.tableHeadCurrentFY}
            colSpan={3}
            align="center"
            style={{
              backgroundColor: "var(--gold-type-2)",
              fontWeight: "bold",
            }}
          >
            INDIRECT
          </TableCell>
          <TableCell
            className={classes.tableHeadCurrentFY}
            colSpan={2}
            align="center"
          ></TableCell>
        </TableRow>
      </TableHead>
    );
  }
  function getCustomTBodyForTotal(data) {
    return (
      <TableBody style={{ position: "sticky", top: 45 }}>
        {data.map((row) => (
          <TableRow>
            <TableCell
              colSpan={1}
              align="center"
              className={classes.tableTotalInput}
            ></TableCell>
            <TableCell
              className={classes.tableTotalInput}
              style={{ fontWeight: "bold" }}
            >
              {row.total.toUpperCase()}
            </TableCell>
            <TableCell
              className={classes.tableTotalInput}
              style={{ fontWeight: "bold" }}
            >
              {getRoundUptoXDecimals(row.totalDirectPrevious, 3)}
            </TableCell>
            <TableCell
              className={classes.tableTotalInput}
              style={{ fontWeight: "bold" }}
            >
              {getRoundUptoXDecimals(row.totalIndirectPrevious, 3)}
            </TableCell>
            <TableCell
              className={classes.tableTotalInput}
              style={{ fontWeight: "bold" }}
            >
              {getRoundUptoXDecimals(row.totalActualPrevious,3)}
            </TableCell>
            <TableCell
              className={classes.tableTotalInput}
              style={{ fontWeight: "bold" }}
            >
              {getRoundUptoXDecimals(row.totalDirectCurrent, 3)}
            </TableCell>
            <TableCell
              className={classes.tableTotalInput}
              style={{ fontWeight: "bold" }}
            >
              {getRoundUptoXDecimals(row.totalO2OCurrent, 3)}
            </TableCell>
            <TableCell
              className={classes.tableTotalInput}
              align="right"
              style={{ fontWeight: "bold" }}
            >
              {getRoundUptoXDecimals(row.totalManualCurrent, 3)}
            </TableCell>
            <TableCell
              className={classes.tableTotalInput}
              style={{ fontWeight: "bold" }}
            >
              {getRoundUptoXDecimals(row.totalIndirectCurrent, 3)}
            </TableCell>
            <TableCell
              className={classes.tableTotalInput}
              style={{ fontWeight: "bold" }}
            >
              {getRoundUptoXDecimals(row.totalActualCurrent, 3)}
            </TableCell>
          </TableRow>
        
        ))}
      </TableBody>
    );
  }
  function getTotalDatae(data) {
    setIsLoader(true);
    let totalObject = {
      total: "Total",
      totalDirectPrevious: 0.0,
      totalIndirectPrevious: 0.0,
      totalActualPrevious: 0.0,
      totalDirectCurrent: 0.0,
      totalO2OCurrent: 0.0,
      totalManualCurrent: 0.0,
      totalIndirectCurrent: 0.0,
      totalActualCurrent: 0.0,
    };
    data?.map((c) => {
      totalObject.totalDirectPrevious =
        totalObject.totalDirectPrevious + c?.directPrevious;
      totalObject.totalIndirectPrevious =
        totalObject.totalIndirectPrevious + c?.indirectPrevious;
      totalObject.totalActualPrevious =
        totalObject.totalActualPrevious +
        c?.directPrevious +
        c?.indirectPrevious;
      totalObject.totalDirectCurrent =
        totalObject.totalDirectCurrent + c?.directCurrent;
      totalObject.totalO2OCurrent =
        totalObject.totalO2OCurrent + c?.o2OIndirectCurrent;
      totalObject.totalManualCurrent =
        totalObject.totalManualCurrent + c?.manualIndirectCurrent;
      totalObject.totalIndirectCurrent =
        totalObject.totalIndirectCurrent +
        c?.o2OIndirectCurrent +
        c?.manualIndirectCurrent;
      totalObject.totalActualCurrent =
        totalObject.totalActualCurrent +
        c?.directCurrent +
        c?.o2OIndirectCurrent +
        c?.manualIndirectCurrent;
      return c;
    });
    setTotalData([{ ...totalObject }]);
    setIsLoader(false);
  }
  function getDataOnLoad(filters) {
    setIsLoader(true);
      getSalesOrderInputData(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId, filters)
      .then((response) => {
          if (response.response) {
           
              const obj = response.objResult;
              console.log("monthly", obj);
          setData(obj.monthlyViewDataList);
          setCurrentFY(obj.year);
          setPreviousFY(obj.previousYear);
          getTotalDatae(obj.monthlyViewDataList);
          setIsLoader(false);
        } else {
          setData([]);
          setCurrentFY(getCurrentFiscalYear());
          setIsLoader(false);
          handleSnackOpen(response.responseMsg, "info");
        }
      })
      .catch((error) => {
        console.log("Error : ", error);
        handleSnackOpen(
          "Exception occured while fetching monthly data",
          "error"
        );
        setIsLoader(false);
      });
  }
  useEffect(() => {
    if (props) {
      getMonths(props?.months);
    }
  }, [props]);
  useEffect(() => {
    if (props.filters) {
      getDataOnLoad(props.filters);
    }
  }, [props.filters]);

  function getDisableTooltip(value, type) {
    return value === true
        ? "The action is restricted"
      : type === "Edit"
      ? "Edit"
      : "Delete";
  }
  return (
      <>
          {userDetails.roleId !== 10 && accessable != false || (userDetails.roleId != 1 && accessable != false) || userDetails.roleId == 1 ?
              <MaterialTable
                  title="Monthly View"
                  columns={columns}
                  data={data}
                  icons={tableIconsInputScreen}
                  isLoading={isLoader}
                  options={{
                      sorting: true,
                      draggable: false,
                      pageSize: 13,
                      toolbar: false,
                      paging: false,
                      maxBodyHeight: "500px",
                  }}
                  components={{
                      Header: (props) => (
                          <>
                              {getCustomTHead()}
                              <MTableHeader {...props} />
                              {getCustomTBodyForTotal(totalData)}
                          </>
                      ),
                      // Body: (props) => (
                      //   <>
                      //     <MTableBody {...props} />
                      //     {getCustomTBodyForTotal(totalData)}
                      //   </>
                      // ),
                  }}
                  localization={{
                      header: {
                          actions: "Edit",
                      },
                  }}
                  editable={{
                      isEditable: (rowData) => userDetails.roleId !== 10 ? rowData.monthToBeLocked === false ? true : false : false, 
                      editTooltip: (rowData) =>
                          getDisableTooltip("true", "Edit"),
                      onRowUpdate: (newData, oldData) =>
                          new Promise((resolve, reject) => {
                              handleRowUpdate(newData, oldData, resolve, reject);
                          }),
                  }}
              /> : <MaterialTable
                  title="Monthly View"
                  columns={columns}
                  data={data}
                  icons={tableIconsInputScreen}
                  isLoading={isLoader}
                  options={{
                      sorting: true,
                      draggable: false,
                      pageSize: 13,
                      toolbar: false,
                      paging: false,
                      maxBodyHeight: "500px",
                  }}
                  components={{
                      Header: (props) => (
                          <>
                              {getCustomTHead()}
                              <MTableHeader {...props} />
                              {getCustomTBodyForTotal(totalData)}
                          </>
                      ),
                      // Body: (props) => (
                      //   <>
                      //     <MTableBody {...props} />
                      //     {getCustomTBodyForTotal(totalData)}
                      //   </>
                      // ),
                  }}
                  localization={{
                      header: {
                          actions: "Edit",
                      },
                  }}
                 
              />}
      <>
        <SnackbarCustom
          open={open}
          message={snackMessage}
          alertType={alertType}
          handleClose={handleClose}
        />
      </>
    </>
  );
}
