import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import MaterialTable, { MTableToolbar } from "material-table";
import { useSelector } from "react-redux";
import { TextField } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { FormControl } from "@material-ui/core";
import Select from "react-select";
import * as XLSX from "xlsx";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import InputLabel from '@material-ui/core/InputLabel';
import { getCurrentFiscalYear } from "../../Utilities/Utility";
import {
    getMonthDDL, getYearDDL,
} from "../../Redux/APIs/api_Common";
import { getBusinessSegmentData, getBusinessUnitData, addSales } from "../../Redux/APIs/api_MasterData";
import {
    getCustomerDDL,
    getEngineerDDL,
    getGckDDL,
    getSalesBranchDDL,
    getSalesOfficeDDL,
    getSalesTeritoryDDL,
    getBusinessSubSegmentDDL,
    getGck,
    getCustomer
} from "../../Redux/APIs/api_Common";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import {
  
    FormControlLabel
    
} from "@material-ui/core";
import FileSaver from "file-saver";
import {
    GetSalesOrderPotentialsReport,
    GetSalesOrderActualsReport,
} from "../../Redux/APIs/api_dumpReports";

import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Loader from "../../Components/Loader/Loader";
const icon = <CheckBoxOutlineBlankIcon />;
const checkedIcon = <CheckBoxIcon />;
export default function DumpReports(props) {
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpen(false);
    };
    const filterOptions = createFilterOptions({
        matchFrom: "any",
        limit: 200,
    });
    const [yearData, setYearData] = useState([]);
    const [budgetDDL, setBudgetDDL] = useState(null);
    const [yearName, setYearName] = useState("");
   // const siteToken = useSelector((state) => state.saveTokenReducer.token);
    const userdetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const [engineerData, setEngineerData] = useState([]);
    const [salesBranchData, setSalesBranchData] = useState([]);
    const [businessUnit, setBusinessUnit] = useState([]);
    const [businessSegment, setBusinessSegment] = useState([]);
    const [businessSubSegment, setBusinessSubSegment] = useState([]);
    const [salesTeritoryData, setSalesTeriotoryData] = useState([]);
    const [flag, setFlag] = useState(false);
    const [yearid, setYearId] = useState(0);
    let [filterData, setFilterData] = useState({
       yearId: yearid,
       // yearId: filterInput?.year?.id,
        customer: [],
        gck: [],
        engineer:
            userdetails?.roleId === 6
                ? [{ id: userdetails?.sdistrictId, name: userdetails?.sdistrict }]
                : [],
        office:
            userdetails?.roleId === 5
                ? [{ id: userdetails?.sbranchId, name: userdetails?.sbranch }]
                : [],
        teritory: [],
        loggedInUserId: userdetails.userId,
    });
    let [isLoader, setIsLoader] = useState(false);
    const [withoutSIEP, setWithoutSIEP] = useState(false);
    //const handleWthoutSIEP = (e ) => {
    //    console.log("checkedvalue", e.target.checked);
    //   setWithoutSIEP(e.target.checked);

    //    setReportInputs({
    //        ...reportInputs,
    //        WithoutSIEP: withoutSIEP,
    //    });
    //}

    const handleWthoutSIEP = (e, checked) => {
        setReportInputs({
            ...reportInputs,
            withoutSIEP: checked,
        });
        setWithoutSIEP(checked);
    };
    let [filterInput, setFilterInput] = useState({
        year: { id: 0, name: "" },
       // year:[],
        yearText: "",
        customer: [],
        gck: [],

        gckText: "",
        engineer: "",
        office: "",
        branch: "",
        teritory: "",
        segment: "",
        subsegment: 0,
        business: "",
    });
    let [year, setYear] = useState({
        id: props.filterData?.yearid,
        name: props.filterData?.yearName,
    });

    
    let eng = filterInput.engineer.id;
    let branch = filterInput.branch.id;
    let teritory = filterInput.teritory.id;
    let unit = filterInput.business.id;
    let seg = filterInput.segment.id;
    let subseg = filterInput.subsegment.id;
    let y = filterInput?.year?.id;
    //const [y, setY] = useState(filterInput?.year?.id);
    const [reportInputs, setReportInputs] = useState({

        fromYear: filterInput?.year?.id,
       // fromYear: y,
        budgetDDL: null,
        loggedinuserId: userdetails.userId,
        MonthId: 0,
        Sdistrict: 0,
        Sbranch: 0,
        Sterritory: 0,
        bunit: 0,
        bsegment: 0,
        bsubsegment: 0,
        WithoutSIEP: withoutSIEP

    });

    let [yearText, setYearText] = useState(props.filterData?.yearName);

    const handleYearChange = (e, newValue) => {
        setReportInputs({
            ...reportInputs,
            fromYear: newValue.id,
        });

        setFilterInput({
            ...filterInput,
            year: { id: newValue?.id, name: newValue?.name },
            yearText: newValue?.name,
        });

        setYear({ id: newValue?.id, name: newValue?.name });
        setYearText(newValue?.name);


    };


    const handleYearInputChange = (e, text) => {
        setFilterInput({
            ...filterInput,
            yearText: text,
        });
        setYearText(text);
    };
    const handleBudgetDDLValue = (e) => {
        setReportInputs({
            ...reportInputs,
            budgetDDL: e.target.value,
        });


        if (e.target.value === 1) {
            setFlag(false);
            //setReportInputs({
            //    ...reportInputs,
            //    MonthId: 0,
            //});
        }
        else {
            setFlag(true);
        }

    }

    let [monthData, setMonthData] = useState([]);

    async function getMonthDetails() {
        const response = await getMonthDDL(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId);
        if (response && response.length > 0) {
            setMonthData(response.map((item) => ({ id: item.id, name: item.name })));
        } else {
            setMonthData([]);
        }
    }
    const [errorTexts, setErrorTexts] = useState({
        year: "",
        month: "",
    });
    let [defaultInput, setDefaultInput] = useState({
        year: {},
        yearText: "",
        month: {},
        monthText: "",
    });
    let [input, setInput] = useState({
        yearId: 0,
        monthId: 0,
    });
    const handleAutocompleteChange = (event, obj) => {
        let elem = event.target.id;
        let elemId = !elem
            ? ""
            : elem.includes("-")
                ? elem.substr(0, elem.indexOf("-"))
                : elem;
        switch (elemId) {
            case "year":
                setDefaultInput({
                    ...defaultInput,
                    [elemId]: obj,
                    [elemId + "Text"]: obj?.name,
                });
                setInput({
                    [elemId + "Id"]: obj?.id,
                });
                setReportInputs({
                    ...reportInputs,
                    fromYear: obj?.id
                })
                break;
            case "month":
                setDefaultInput({
                    ...defaultInput,
                    [elemId]: obj,
                    [elemId + "Text"]: obj?.name,
                });
                setInput({
                    ...input,
                    [elemId + "Id"]: obj?.id,
                });
                setReportInputs({
                    ...reportInputs,
                    MonthId: obj?.id
                })
                break;
            default:
                break;
        }
        validateAutocompleteComponents(event, obj?.name);

    };

    const validateAutocompleteComponents = (e, value) => {
        let elem = e.target.id;
        let elemId = !elem
            ? ""
            : elem.includes("-")
                ? elem.substr(0, elem.indexOf("-"))
                : elem;
        switch (elemId) {
            case "year":
                setErrorTexts({
                    ...errorTexts,
                    [elemId]: !value ? "Mandatory" : "",
                });
                break;
            case "month":
                setErrorTexts({
                    ...errorTexts,
                    [elemId]: !value ? "Mandatory" : "",
                });
                break;
            default:
                break;
        }
    };
    function getSalesBranchDetails() {
        getSalesBranchDDL(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {
                console.log(response);
                setSalesBranchData(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    function getSalesTeritoryDetails() {
        getSalesTeritoryDDL(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {
                setSalesTeriotoryData(response);
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    function getEngineerDetails() {
        getEngineerDDL(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {
                setEngineerData(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    function getBusinessUnit() {
        getBusinessUnitData(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {
                setBusinessUnit(response);
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    function getBusinessSegment() {
        getBusinessSegmentData(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {
                setBusinessSegment(response);
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    function getBusinessSubSegment() {
        getBusinessSubSegmentDDL(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {
                setBusinessSubSegment(response);
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    function GetSalesOrderPotentialsReportFn() {
        setIsLoader(true);




        console.log(reportInputs);
        GetSalesOrderPotentialsReport(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, reportInputs)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.byteLength <= 200) {
                        setIsLoader(false);
                        handleSnackOpen();
                        setSnackMessage("No Records are Found ");
                        setAlertType("success");
                    } else {
                        var blob = new Blob([response.data], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        });
                        FileSaver.saveAs(blob, "PotentialDataReport.xlsx");
                        setIsLoader(false);
                        handleSnackOpen();
                        setSnackMessage("Potential Data Report Downloaded");
                        setAlertType("success");
                    }
                } else {
                    setIsLoader(false);
                    handleSnackOpen();
                    setSnackMessage("Report download failed");
                    setAlertType("error");
                }
            })
            .catch((error) => {
                setIsLoader(false);
                handleSnackOpen();
                setSnackMessage("Error: ", error);
                setAlertType("error");
            });
    }

    function GetSalesOrderActualsReportFn() {
        setIsLoader(true);
        console.log(reportInputs);
        GetSalesOrderActualsReport(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, reportInputs, input.monthId)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.byteLength <= 200) {
                        setIsLoader(false);
                        handleSnackOpen();
                        setSnackMessage("No Records are Found ");
                        setAlertType("success");
                    } else {
                        var blob = new Blob([response.data], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        });
                        FileSaver.saveAs(blob, "MonthlyActualsDataReport.xlsx");
                        setIsLoader(false);
                        handleSnackOpen();
                        setSnackMessage("Monthly Actuals Data Report Downloaded");
                        setAlertType("success");
                    }
                } else {
                    setIsLoader(false);
                    handleSnackOpen();
                    setSnackMessage("Report download failed");
                    setAlertType("error");
                }
            })
            .catch((error) => {
                setIsLoader(false);
                handleSnackOpen();
                setSnackMessage("Error: ", error);
                setAlertType("error");
            });
    }

    const filterResult = () => {
        if (reportInputs.budgetDDL === 1) {


            GetSalesOrderPotentialsReport();

        }
        else

            GetSalesOrderActualsReport();
    };
    useEffect(() => {
        getYearDetails();
        getMonthDetails();
        getEngineerDetails();
        getSalesBranchDetails();
        getSalesTeritoryDetails();
        getBusinessUnit();
        getBusinessSegment();
        getBusinessSubSegment();
        console.log(filterInput)


    }, []);


    function getYearDetails() {
        let year = getCurrentFiscalYear();
        getYearDDL(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {
                let obj = response?.find((x) => x.name === year);
                setFilterData({
                    ...filterData,
                    yearId: obj?.id,
                });
                setFilterInput({
                    ...filterInput,
                    year: { id: obj?.id, name: obj?.name },
                    yearText: obj?.name,
                });
                setReportInputs({
                    ...reportInputs,
                    fromYear:obj?.id,
                });
                console.log("year",reportInputs.fromYear);
                setYearData(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <div className="App">
            <h3>Monthly Planning and Actuals Data Report</h3>
            <Grid container spacing={1}>

                <Grid item xs={12} sm={3} className="pd-1">
                    <Autocomplete
                        filterOptions={filterOptions}
                        disableClearable
                        fullWidth
                        name="YearId"
                        value={filterInput.year}

                        inputValue={filterInput.yearText}
                        select
                        disabled={!yearData?.length > 0}
                        loading
                        loadingText="No Options"
                        id="YearId"
                        freeSolo
                        margin="normal"
                        options={yearData}
                        onChange={handleYearChange}
                        //onChange={handleAutocompleteChange}

                        onInputChange={handleYearInputChange}
                        getOptionLabel={(option) => (option.name ? option.name : "")}
                        getOptionDisabled={(option) => option.id < 2}
                        renderInput={(params) => (
                            <TextField {...params} label="&nbsp;&nbsp;Fiscal Year" />
                        )}
                    />

                </Grid>
                <Grid item xs={12} sm={3} className="pd-1">
                    <TextField className="lightInputFields  "
                        select
                        id=""
                        //disabled={true}
                        label="Sales Planning"
                        variant="filled"
                        fullWidth
                        name=""
                        //value={placeValue}
                        onChange={handleBudgetDDLValue}
                    >
                        <MenuItem value="">
                            <em>Select</em>
                        </MenuItem>
                        <MenuItem value={1}>Potentials</MenuItem>
                        <MenuItem value={2}>Monthly Actuals</MenuItem>
                    </TextField>
                </Grid>

                <Grid item xs={12} sm={3} className="pd-1">
                    <Autocomplete
                        disableClearable
                        fullWidth
                        name="month"
                        value={defaultInput.month}
                        select="true"
                        loading
                        loadingText="No Options"
                        id="month"
                        freeSolo
                        margin="normal"
                        options={monthData}
                        onChange={handleAutocompleteChange}
                        disabled={!flag}
                        getOptionLabel={(option) => (option.name ? option.name : "")}
                        // getOptionDisabled={(option) =>
                        //   //option.name !== getCurrentFiscalYear()
                        //   DisableMonths(option)
                        // }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Month"
                                error={errorTexts.month}
                                helperText={errorTexts.month}
                                variant="filled"
                            />
                        )}
                    />
                </Grid>


                {userdetails.roleId == 1 || userdetails.roleId == 10 ? (
                    <Grid item xs={12} sm={3} className="pd-1">
                        <Autocomplete
                            filterOptions={filterOptions}
                            //multiple
                            loading
                            loadingText="No Options"
                            disableClearable
                            freeSolo
                            id="Engineer Data"
                            disabled={!engineerData?.length > 0}
                            options={engineerData}
                            fullWidth
                            value={filterInput.engineer}
                            onChange={(event, value) => {
                                setFilterInput((old) => {
                                    return {
                                        ...old,
                                        engineer: value,
                                    };
                                });
                                setReportInputs({
                                    ...reportInputs,
                                    Sdistrict: value.id,

                                });
                            }}
                            limitTags={2}
                            disableCloseOnSelect
                            getOptionLabel={(option) => (option.name ? option.name : "")}

                            renderInput={(params) => (
                                <TextField {...params} label="&nbsp;&nbsp;Sales District" />
                            )}
                        />
                    </Grid>
                ) : (
                    <></>
                )}
                {userdetails.roleId == 1 || userdetails.roleId == 10 ? (
                    <Grid item xs={12} sm={3} className="pd-1">
                        <Autocomplete
                            filterOptions={filterOptions}
                            disableClearable
                            //multiple
                            loading
                            loadingText="No Options"
                            freeSolo
                            id="Branch"
                            disabled={!salesBranchData?.length > 0}
                            options={salesBranchData}
                            fullWidth
                            value={filterInput.branch}
                            onChange={(event, value) => {
                                setFilterInput((old) => {
                                    return {
                                        ...old,
                                        branch: value,
                                    };
                                });
                                setReportInputs({
                                    ...reportInputs,
                                    Sbranch: value.id,

                                });

                            }}
                            limitTags={4}
                            disableCloseOnSelect
                            getOptionLabel={(option) =>
                                option.name ? option.name : ""
                            }

                            renderInput={(params) => (
                                <TextField {...params} label="&nbsp;&nbsp;Sales Branch" />
                            )}
                        />
                    </Grid>
                ) : (
                    <></>
                )}
                {userdetails.roleId == 1 || userdetails.roleId == 10 ? (
                    <Grid item xs={12} sm={3} className="pd-1">
                        <Autocomplete
                            filterOptions={filterOptions}
                            disableClearable
                            // multiple
                            loading
                            loadingText="No Options"
                            freeSolo
                            id="Teritory"
                            disabled={!salesTeritoryData?.length > 0}
                            options={salesTeritoryData}
                            fullWidth
                            value={filterInput.teritory}
                            onChange={(event, value) => {
                                setFilterInput((old) => {
                                    return {
                                        ...old,
                                        teritory: value,
                                    };
                                });
                                setReportInputs({
                                    ...reportInputs,
                                    Sterritory: value.id,

                                });
                            }}
                            limitTags={4}
                            disableCloseOnSelect
                            getOptionLabel={(option) => (option.name ? option.name : "")}

                            renderInput={(params) => (
                                <TextField {...params} label="&nbsp;&nbsp;Sales Teritory" />
                            )}
                        />
                    </Grid>
                ) : (
                    <></>
                )}
                {userdetails.roleId == 1 || userdetails.roleId == 10 ? (
                    <Grid item xs={12} sm={3} className="pd-1">
                        <Autocomplete
                            filterOptions={filterOptions}
                            disableClearable
                            // multiple
                            loading
                            loadingText="No Options"
                            freeSolo
                            id="Teritory"
                            //disabled={!businessUnit?.length > 0}
                            options={businessUnit}
                            fullWidth
                            value={filterInput.business}
                            onChange={(event, value) => {
                                setFilterInput((old) => {
                                    return {
                                        ...old,
                                        business: value,
                                    };
                                });
                                setReportInputs({
                                    ...reportInputs,
                                    bunit: value.businessUnitId,

                                });
                            }}
                            limitTags={4}
                            disableCloseOnSelect
                            getOptionLabel={(option) => (option.businessUnit ? option.businessUnit : "")}


                            renderInput={(params) => (
                                <TextField {...params} label="&nbsp;&nbsp;Business Unit" />
                            )}
                        />
                    </Grid>
                ) : (
                    <></>
                )}
                {userdetails.roleId == 1 || userdetails.roleId == 10 ? (
                    <Grid item xs={12} sm={3} className="pd-1">
                        <Autocomplete
                            filterOptions={filterOptions}
                            disableClearable
                            // multiple
                            loading
                            loadingText="No Options"
                            freeSolo
                            id="Segment"
                            //disabled={!businessSegment?.length > 0}
                            options={businessSegment}
                            fullWidth
                            value={filterInput.segment}
                            onChange={(event, value) => {
                                setFilterInput((old) => {
                                    return {
                                        ...old,
                                        segment: value,
                                    };
                                });
                                setReportInputs({
                                    ...reportInputs,
                                    bsegment: value.businessSegmentId,

                                });
                            }}
                            limitTags={4}
                            disableCloseOnSelect
                            getOptionLabel={(option) => (option.businessSegment ? option.businessSegment : "")}

                            renderInput={(params) => (
                                <TextField {...params} label="&nbsp;&nbsp;Business Segment" />
                            )}
                        />
                    </Grid>
                ) : (
                    <></>
                )}
                {userdetails.roleId == 1 || userdetails.roleId == 10 ? (
                    <Grid item xs={12} sm={3} className="pd-1">
                        <Autocomplete
                            filterOptions={filterOptions}
                            disableClearable
                            //multiple
                            loading
                            loadingText="No Options"
                            freeSolo
                            id="Segment"
                            //disabled={!businessSegment?.length > 0}
                            options={businessSubSegment}
                            fullWidth
                            value={filterInput.subsegment}
                            onChange={(event, value) => {
                                setFilterInput((old) => {
                                    return {
                                        ...old,
                                        subsegment: value,
                                    };
                                });
                                setReportInputs({
                                    ...reportInputs,
                                    bsubsegment: value.id,

                                });
                            }}
                            limitTags={4}
                            disableCloseOnSelect
                            getOptionLabel={(option) => (option.name ? option.name : "")}

                            renderInput={(params) => (
                                <TextField {...params} label="&nbsp;&nbsp;Business SubSegment" />
                            )}
                        />
                    </Grid>
                ) : (
                    <></>
                )}
               


                {/*<Checkbox*/}
                {/*    icon={icon}*/}
                {/*    label="without SI EP"*/}
                {/*    checkedIcon={checkedIcon}*/}
                {/*    style={{ marginRight: 8 }}*/}
                {/*    checked={withoutSIEP }*/}
                {/*    onChange={handleWthoutSIEP }*/}
                {/*    //name=""*/}
                {/*    //checked={selected}*/}
                {/*/>*/}

                <Grid item xs={12} sm={3} className="pd-1">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={withoutSIEP}
                                onChange={handleWthoutSIEP}
                                name="checkedB"
                            />
                        }
                        label="Without SIEP"
                    />
                    </Grid>

                <Grid item xs={12} sm={3} >

                </Grid>
                <Grid item xs={12} sm={3} >

                </Grid>
                <Grid item xs={12} sm={3} >

                </Grid>



                <Grid item xs={12}  style={{display:'flex', justifycontent:'center'} } >
                    <Button onClick={reportInputs.budgetDDL === 1 ? GetSalesOrderPotentialsReportFn : GetSalesOrderActualsReportFn} className="pt-button--secondary">
                        Filter Result
                    </Button>
                </Grid>
            </Grid>


            {isLoader ? < Loader /> : <></>}


        </div>

    );
}
