import React from 'react';
import MasterUploads from './MasterUploads';
import SalesOrderPlanningUpload from './SalesOrderPlanningUpload';
import AttributeDataUpload from './AttributeDataUpload';


import "./Uploads.scss";
export default function Uploads(props) {
    return (
      <div className="bulk-upload">
        <SalesOrderPlanningUpload />
            <MasterUploads />
            <AttributeDataUpload />
      </div>
    );
}