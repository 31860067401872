import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import { Grid, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import DialogActions from "@material-ui/core/DialogActions";
import {
  getBusinessUnitDDL,
  getBusinessSegmentDDL,
} from "../../../../../Redux/APIs/api_Common";

export default function BusinessSubSegmentForm(props) {
  const token = useSelector((state) => state.saveTokenReducer.token);
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
  const useStyles = makeStyles(() => ({
    inputForm: {
      padding: "85px 50px",
    },
  }));

  // API
  let [bunitData, setBunitData] = useState([]);
  function getBusinessUnitDetails() {
      getBusinessUnitDDL(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId)
      .then((response) => {
        if (response && response.length > 0) {
          setBunitData(
            response.map((item) => ({ value: item.id, label: item.name }))
          );
        } else {
          setBunitData([]);
        }
      })
      .catch((error) => console.log(error));
  }
  let [bsegmentData, setBsegmentData] = useState([]);

  function getBusinessSegmentDetails(buid) {
      getBusinessSegmentDDL(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId, buid)
      .then((response) => {
        if (response && response.length > 0) {
          setBsegmentData(
            response.map((item) => ({ value: item.id, label: item.name }))
          );
        } else {
          setBsegmentData([]);
        }
      })
      .catch((error) => console.log(error));
  }

  const [errorTexts, setErrorTexts] = useState({
    bunit: "",
    bsegment: "",
    businessSubSegment: "",
    common: "",
  });
  let [defaultInputs, setDefaultInputs] = useState({
    businessSubSegment: props?.rowData?.businessSubSegment,
    bunit: {
      value: props?.rowData?.bunitId,
      label: props?.rowData?.bunit,
    },
    bunitText: props?.rowData?.bunit,
    bsegment: {
      value: props?.rowData?.bsegmentId,
      label: props?.rowData?.bsegment,
    },
    bsegmentText: props?.rowData?.bsegment,
  });
  let [inputs, setInputs] = useState({
    businessSubSegmentId: props?.rowData?.businessSubSegmentId,
    businessSubSegment: props?.rowData?.businessSubSegment,
    bunitId: props?.rowData?.bunitId,
    bsegmentId: props?.rowData?.bsegmentId,
  });

  const handleBssChange = (e) => {
    const value = e.target.value;
    setDefaultInputs({
      ...defaultInputs,
      businessSubSegment: value,
    });
    setInputs({
      ...inputs,
      businessSubSegment: value,
    });
    validateAutocompleteComponents(e, value);
  };

  const handleAutocompleteChange = (event, obj) => {
    let elem = event.target.id;
    let elemId = !elem
      ? ""
      : elem.includes("-")
      ? elem.substr(0, elem.indexOf("-"))
      : elem;
    switch (elemId) {
      case "bunit":
        setDefaultInputs({
          ...defaultInputs,
          [elemId]: obj,
          [elemId + "Text"]: obj?.label,
        });

        setInputs({
          ...inputs,
          [elemId + "Id"]: obj?.value,
        });

        getBusinessSegmentDetails(obj.value);
        break;
      case "bsegment":
        setDefaultInputs({
          ...defaultInputs,
          [elemId]: obj,
          [elemId + "Text"]: obj?.label,
        });
        setInputs({
          ...inputs,
          [elemId + "Id"]: obj?.value,
        });
        break;
      default:
        break;
    }
    validateAutocompleteComponents(event, obj?.label);
  };
  const handleAutocompleteTextChange = (event, text) => {
    let elem = event.target.id;
    let elemId = !elem
      ? ""
      : elem.includes("-")
      ? elem.substr(0, elem.indexOf("-"))
      : elem;
    switch (elemId) {
      case "bunit":
        setDefaultInputs({
          ...defaultInputs,
          [elemId + "Text"]: text,
        });
        let bunitId = bunitData.find((x) => x.label === text)?.value;
        setInputs({
          ...inputs,
          [elemId + "Id"]: bunitId ? bunitId : 0,
        });
        break;
      case "bsegment":
        setDefaultInputs({
          ...defaultInputs,
          [elemId + "Text"]: text,
        });
        let bsegmentId = bsegmentData.find((x) => x.label === text)?.value;
        setInputs({
          ...inputs,
          [elemId + "Id"]: bsegmentId ? bsegmentId : 0,
        });
        break;
      default:
        break;
    }
    validateAutocompleteComponents(event, text);
  };
  const handleSubmit = (e) => {
    if (!validateFieldsOnSubmit(e)) {
      e.preventDefault();
      return;
    } else {
      if (props?.rowData) {
        props.handleUpdateData(inputs);
        props.handleCloseDialog(true);
      } else {
        props.handleAddData(inputs);
        props.handleCloseDialog(true);
      }
    }
    // if(props?.rowData){
    //     let EditInputs={
    //         businessSubSegmentId :props.rowData?.businessSubSegmentId ,
    //         businessSubSegment: inputs.BusinessSubSegment? inputs.BusinessSubSegment: props.rowData.businessSubSegment,
    //         bunitId: inputs.bunitId? inputs.bunitId: props.rowData.bunitId,
    //         bsegmentId: inputs.bsegmentId? inputs.bsegmentId: props.rowData.bsegmentId,
    //     };

    //     props.handleUpdateData(EditInputs)
    // }
    // else{

    //     props.handleAddData(inputs)
    // }
    // props.handleCloseDialog(true);
  };
  const handleClose = () => {
    props.handleCloseDialog(true);
  };
  const validateAutocompleteComponents = (e, value) => {
    let elem = e.target.id;
    let elemId = !elem
      ? ""
      : elem.includes("-")
      ? elem.substr(0, elem.indexOf("-"))
      : elem;
    switch (elemId) {
      case "businessSubSegment":
        setErrorTexts({
          ...errorTexts,
          [elemId]: !value ? "Mandatory" : "",
          common: "",
        });
        break;
      case "bunit":
        setErrorTexts({
          ...errorTexts,
          [elemId]: !value
            ? "Mandatory"
            : !bunitData.find((x) => x.label === value)
            ? "Invalid selection"
            : "",
          bsegment: !defaultInputs?.bsegmentText ? "Mandatory" : "",
          common: "",
        });
        break;
      case "bsegment":
        setErrorTexts({
          ...errorTexts,
          [elemId]: !value
            ? "Mandatory"
            : !bsegmentData.find((x) => x.label === value)
            ? "Invalid selection"
            : "",
          common: "",
        });
        break;
      default:
        break;
    }
  };
  const validateFieldsOnSubmit = (e) => {
    let ret = true;
    let errors = {};
    if (!defaultInputs?.businessSubSegment) {
      errors.businessSubSegment = "Mandatory";
      ret = false;
    }
    if (!defaultInputs?.bunitText) {
      errors.bunit = "Mandatory";
      ret = false;
    } else if (!bunitData.find((x) => x.label === defaultInputs?.bunitText)) {
      errors.bunit = "Invalid selection";
      ret = false;
    }
    if (!defaultInputs?.bsegmentText) {
      errors.bunit = "Mandatory";
      ret = false;
    } else if (
      !bsegmentData.find((x) => x.label === defaultInputs?.bsegmentText)
    ) {
      errors.bsegment = "Invalid selection";
      ret = false;
    }
    if (
      (!props?.rowData?.businessSubSegmentId &&
        props?.tableData.find(
          (x) =>
            x.businessSubSegment === defaultInputs?.businessSubSegment &&
            x.bunitId === defaultInputs?.bunit?.value &&
            x.bsegmentId === defaultInputs?.bsegment?.value
        )) ||
      (props?.rowData?.businessSubSegmentId &&
        props?.tableData.find(
          (x) =>
            x.businessSubSegmentId !== props?.rowData?.businessSubSegmentId &&
            x.businessSubSegment === defaultInputs?.businessSubSegment &&
            x.bunitId === defaultInputs?.bunit?.value &&
            x.bsegmentId === defaultInputs?.bsegment?.value
        ))
    ) {
      errors.common =
        "This Business Sub Segment combination already exists, please try different.";
      ret = false;
    }
    setErrorTexts({
      ...errorTexts,
      bunit: errors.bunit,
      bsegment: errors.bsegment,
      businessSubSegment: errors.businessSubSegment,
      common: errors.common,
    });
    return ret;
  };
  useEffect(() => {
    getBusinessUnitDetails();
    if (props?.rowData?.bunitId) {
      getBusinessSegmentDetails(props.rowData.bunitId);
    }
  }, [props]);

  // useEffect(() => {

  //     if (
  //         props?.rowData &&
  //         props?.bunitData &&
  //         props?.bsegmentData
  //     ) {

  //         const bunit = props?.bunitData;
  //         const bsegment = props?.bsegmentData;
  //         const bsubsegment=props?.rowData.businessSubSegment;
  //         setDefaultInputs({
  //             ...defaultInputs,
  //             businessSubSegment:bsubsegment,
  //             bunit: bunit,
  //             bunitText: bunit?.label,
  //             bsegment: bsegment,
  //             bsegmentText: bsegment?.label,

  //         });
  //         console.log(props.rowData,defaultInputs.businessSubSegment)
  //     }

  // }, [props])

  const classes = useStyles();
  return (
    <div className={classes.inputForm}>
      <Grid>
        <h3 className="text-center m-0  dialog-header">
          {props?.rowData ? "Edit Data" : "Add Data"}
        </h3>
      </Grid>
      <Grid>
        <h4 style={{ color: "red" }}>{errorTexts?.common}</h4>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            className="mt-1"
            fullWidth
            value={defaultInputs?.businessSubSegment}
            onChange={handleBssChange}
            bame="businessSubSegment"
            type="text"
            label="Business SubSegment"
            variant="filled"
            id="businessSubSegment"
            error={errorTexts.businessSubSegment}
            helperText={errorTexts.businessSubSegment}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Autocomplete
            className="mt-1"
            fullWidth
            name="bunit"
            disableClearable
            freeSolo
            value={defaultInputs?.bunit}
            inputValue={defaultInputs?.bunitText}
            select
            id="bunit"
            options={bunitData}
            disabled={!bunitData.length > 0}
            onChange={handleAutocompleteChange}
            onInputChange={handleAutocompleteTextChange}
            getOptionLabel={(option) => (option.label ? option.label : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Business Unit"
                variant="filled"
                error={errorTexts.bunit}
                helperText={errorTexts.bunit}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Autocomplete
            className="mt-1"
            disableClearable
            freeSolo
            fullWidth
            name="bsegment"
            value={defaultInputs?.bsegment}
            inputValue={defaultInputs?.bsegmentText}
            select
            id="bsegment"
            options={bsegmentData}
            disabled={!bsegmentData.length > 0}
            onChange={handleAutocompleteChange}
            onInputChange={handleAutocompleteTextChange}
            getOptionLabel={(option) => (option.label ? option.label : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Business Segment"
                variant="filled"
                error={errorTexts.bsegment}
                helperText={errorTexts.bsegment}
              />
            )}
          />
        </Grid>
      </Grid>
      <DialogActions>
        <Button
          onClick={handleClose}
          className="pr-button--secondary "
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          type="submit"
          form="myform"
          className="pt-button--primary button-submit"
          disabled={
            errorTexts?.bunit ||
            errorTexts?.bsegment ||
            errorTexts?.businessSubSegment ||
            errorTexts?.common
          }
        >
          Submit
        </Button>
      </DialogActions>
    </div>
  );
}
