import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./assets/breakpoint.scss";
import "./App.css";
import { LoginRoute } from "./Routes/LoginRoute";
import { useAuth } from "react-oidc-context";
import { useDispatch, useSelector } from "react-redux";
import { storeUserDetailsAction, saveAccessTokenAction, saveAzureTokenAction } from "../src/Redux/Actions/actions";
import { MasterRoute, GLRoute, BMRoute, SERoute, OtherRoute } from "./Routes/MasterRoute";
import Header from "./Components/Header/Header";
import Loader from "./Components/Loader/Loader";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import UnauthorizedAccess from "./Components/UnauthorizedAccess/UnauthorizedAccess";
import IdleTimer from "react-idle-timer";
import {
  authenticateUserWithMyID,
  generateJwtForAPI,
} from "./Redux/APIs/api_Login";
import { useMsal } from '@azure/msal-react';


export default function App() {
  const auth = useAuth();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.saveTokenReducer.token);
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  const [isLoader, setIsLoader] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
    const [unAuthText, setUnAuthText] = useState("");
    const { instance, accounts } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const [isAuthorized, setIsAuthorized] = useState(true);

  const handleClose = (e, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setDialogOpen(false);
  };
  function sessionExpire(auth, dispatch) {
    var newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname;
    window.history.pushState({ path: newurl }, "", `/`);
    localStorage.clear();
    sessionStorage.clear();
    if (auth !== undefined) {
      dispatch(storeUserDetailsAction(""));

      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
    window.location.href = "/";
    }
    function authenticateUserWithGID(gid) {
        authenticateUserWithMyID(dispatch, activeAccount.idToken, gid)
            .then((response) => {
                if (response) {
                    console.log("app", response)
                    // dispatch(saveAccessTokenAction(activeAccount.idToken));
                    setIsLoader(false);
                    setIsAuthorized(false);
                } else {
                    setIsLoader(false);
                    setIsAuthorized(false);
                    setDialogOpen(true);
                }
            })
            .catch((error) => {
                //setDialogOpen(true);
                setIsLoader(false);
            });
    }

  //function authenticateUserWithGID(gid) {
  //  let data = { gid: gid };
  //  authenticateUserWithMyID(dispatch, token, data)
  //    .then((response) => {
  //      if (response.response) {
  //        setUnAuthText("");
  //        setDialogOpen(false);
  //        setIsLoader(false);
  //      } else {
  //        setIsLoader(false);
  //        setUnAuthText(response.responseMsg);
  //        setDialogOpen(true);
  //      }
  //    })
  //    .catch((error) => {
  //      setIsLoader(false);
  //      setUnAuthText("Exception occured while autheniticating user.");
  //      setDialogOpen(true);
  //    });
  //}
  //useEffect(() => {
  //  if (auth.user) {
  //    setIsLoader(true);
  //    if (token) {
  //      const arr = auth.user.profile.sub.split("|");
  //      let gid = arr && arr.length ? arr[1] : "";
  //      authenticateUserWithGID(gid);
  //    } else {
  //      generateJwtForAPI(dispatch)
  //        .then((response) => setIsLoader(false))
  //        .catch((error) => setIsLoader(false));
  //    }
  //  }
  //  if (auth.error) {
  //    setDialogOpen(true);
  //  }
  //}, [auth, token, dispatch]);
    useEffect(() => {
        console.log(activeAccount);
        console.log(userDetails)
        if (activeAccount && isAuthorized && !userDetails.userId) {
            setIsLoader(true);
            dispatch(saveAccessTokenAction(activeAccount.idToken));
            dispatch(saveAzureTokenAction(activeAccount.idToken));
            authenticateUserWithGID();
        }
        else {

            setIsLoader(false);

        }


    }, [instance, activeAccount, !userDetails.userId]);
  return (
    // <div>Sie Sales Indirect Actual Tool</div>
    <>
    <BrowserRouter>
      <div className="ssiat-master-block">
        {auth.isLoading || isLoader ? (
          <Loader />
        ) : (userDetails && userDetails.roleId > 0) || auth.user ? (
          <>
            <Header>Header Component</Header>
                              <main className="main-component">{userDetails.roleId === 1 ? MasterRoute :
                                  userDetails.roleId === 5 ? GLRoute : userDetails.roleId === 6? SERoute : userDetails.roleId === 10? BMRoute : OtherRoute}</main>
          </>
        ) : (
          LoginRoute
        )}
      </div>
      <div>
        <IdleTimer
          timeout={1000 * 60 * 15}
          onIdle={sessionExpire}
          debounce={250}
        />
      </div>
      <>
        {/*<Dialog*/}
        {/*  disableEscapeKeyDown*/}
        {/*  open={dialogOpen}*/}
        {/*  onClose={handleClose}*/}
        {/*  aria-labelledby="alert-dialog-title"*/}
        {/*  aria-describedby="alert-dialog-description"*/}
        {/*>*/}
        {/*  <DialogTitle id="alert-dialog-title">*/}
        {/*    {auth.error ? "MyID Session Expired" : "Un-Authorized"}*/}
        {/*  </DialogTitle>*/}
        {/*  <DialogContent>*/}
        {/*    <UnauthorizedAccess*/}
        {/*      errorText={*/}
        {/*        auth.error ? "MyID Error :" + auth.error?.stack : unAuthText*/}
        {/*      }*/}
        {/*    />*/}
        {/*  </DialogContent>*/}
        {/*</Dialog>*/}
      </>
    </BrowserRouter>
    </>
  );
}
