import React, { useEffect, useState,useRef } from "react";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import { tableIcons } from "../../../../Utilities/Utility";
import { tableOptionsUser } from "../../../../Themes/LightTheme";
import { useSelector } from "react-redux";
import SnackbarCustom from "../../../../Components/Snackbar/Snackbar";
import * as XLSX from 'xlsx';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import { getRoleData, addRoleData, updateRoleData, deleteRoleData } from "../../../../Redux/APIs/api_MasterData";

export default function RoleMaster() {
    const tableRef = useRef();
    const icon=()=>{
        return(
        <GetAppRoundedIcon  style={{ fontSize: 53 }}/>
        );
    }
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const [tableData, setTableData] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpen(false);
    };
    function isRoleExists(value) {
        const obj = tableData.find((c) => c.roleName == value);
        return obj ? true : false;
      }
      function isRoleExists(value, id) {
        const obj = tableData.find((c) => c.roleName == value);
        return obj && obj.roleId !== id ? true : false;
      }
      function validateRole(value, id) {
        return !value
          ? { isValid: false, helperText: "*Required" }
          : (!id && isRoleExists(value)) || (id && isRoleExists(value, id))
          ? { isValid: false, helperText: "Already exists" }
          : { isValid: true, helperText: "" };
      }
    const columns = [
        { title: 'Role Id', field: 'roleId', hidden: true },
        { title: 'Role Name', field: 'roleName' ,
        validate: (rowData) =>
        validateRole(rowData.roleName, rowData.roleId),
    },

    ];
    function getTableData() {
        setIsLoader(true);
        getRoleData(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {
                setTableData(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    useEffect(() => {
        getTableData();
    }, [userDetails])


    const handleRowUpdate = (newTableData, oldTableData, resolve, reject) => {
        setIsLoader(true);
        newTableData.loggedinUserId = userDetails.userId;
        updateRoleData(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId,newTableData)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableData();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                reject();
                setIsLoader(false);
            });
    };
   
  
    function ExportToExcel() {
        const dataToDownload = tableRef?.current?.dataManager?.searchedData.map(
          (row) => {
            return {
              roleName: row.roleName,
              businessSegment: row.businessSegment,
              businessSubSegment: row.businesssubSegment,
              gck: row.gck,
            };
          }
        );
        let headers = [
          "Role Name",
         
        ];
        //const workSheet = XLSX.utils.json_to_sheet(newData);
        let ws = XLSX.utils.book_new();
        let wb = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(ws, [headers]);
        XLSX.utils.sheet_add_json(ws, dataToDownload, {
          origin: "A2",
          skipHeader: true,
          cellStyles: true,
        });
        ws["!cols"] = cellAdjustToContents(dataToDownload);
        XLSX.utils.book_append_sheet(wb, ws, "Role");
        XLSX.write(wb, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(wb, "RoleMaster.xlsx");
      }
      function cellAdjustToContents(data) {
        return [
          {
            wch: Math.max(
              ...data?.map((c) =>
                c.roleName? c.roleName?.toString()?.length : 0
              )
            ),
          },
       
        ];
      }
    return (
        <div>
            <div className="App">
                <Grid container spacing={1}>

                    <Grid item xs={12}>
                        {userDetails.roleId !== 10 ?
                            <MaterialTable
                                title=""
                                tableRef={tableRef}
                                columns={columns}
                                data={tableData}
                                isLoading={isLoader}
                                icons={tableIcons}
                                options={tableOptionsUser}
                                actions={
                                    [{
                                        icon: icon,
                                        tooltip: "Export to Excel",
                                        onClick: () => ExportToExcel(),
                                        isFreeAction: true
                                    },]
                                }
                                editable={{
                                    onRowUpdate: (newTableData, oldTableData) =>
                                        new Promise((resolve, reject) => {
                                            handleRowUpdate(
                                                newTableData,
                                                oldTableData,
                                                resolve,
                                                reject
                                            );
                                        }),

                                }}
                            /> : <MaterialTable
                                title=""
                                tableRef={tableRef}
                                columns={columns}
                                data={tableData}
                                isLoading={isLoader}
                                icons={tableIcons}
                                options={tableOptionsUser}
                                actions={
                                    [{
                                        icon: icon,
                                        tooltip: "Export to Excel",
                                        onClick: () => ExportToExcel(),
                                        isFreeAction: true
                                    },]
                                }
                                
                            />}
                        <>
                            <SnackbarCustom
                                open={open}
                                message={snackMessage}
                                alertType={alertType}
                                handleClose={handleClose}
                            />
                        </>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}