import React, { useState, useEffect } from "react"; 
import { TextField, Grid, Button, CircularProgress } from "@material-ui/core";
import {
    FormControl,
    
    FormHelperText,
    Dialog,
    DialogContent,
    Tooltip,
   
    DialogContentText,
    DialogActions,
    DialogTitle,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
} from "@material-ui/core";
import Icon from '@material-ui/core/Icon';
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import { makeStyles } from "@material-ui/core/styles";

import {
    customStylesDefault,
    tableIconsInputScreen,
    theme,
} from "../../../src/Utilities/Utility";
import CloseIcon from "@material-ui/icons/Close";
import Checkbox from "@material-ui/core/Checkbox";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useSelector } from "react-redux";
import { getCurrentFiscalYear } from "../../Utilities/Utility";
import {
  getCustomerDDL,
  getEngineerDDL,
  getGckDDL,
  getSalesBranchDDL,
  getSalesOfficeDDL,
    getSalesTeritoryDDL,
    getBusinessSegmentDDL,
    getBusinessUnitDDL,
    getYearDDL,
    getGck,
    getCustomer
} from "../../Redux/APIs/api_Common";
import { getBusinessSegmentData, getBusinessUnitData, addSales } from "../../Redux/APIs/api_MasterData";
const icon = <CheckBoxOutlineBlankIcon />;
const checkedIcon = <CheckBoxIcon />;
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));
export default function FilterList(props) {
    const classes = useStyles();
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 200,
  });
  function getProgressComponent() {
    return <CircularProgress style={{ height: `40px`, width: `40px` }} />;
    }
    const [formDialogTitle, setFormDialogTitle] = useState("Save");

    // const handleClickOpenForm = () => {
    //   setFormDialogTitle("Save");
    //   setOpenForm(true);
    // };
    const [openForm, setOpenForm] = useState(false);
    const handleCloseForm = () => {
        //clearFormValues();
        setOpenForm(false);
    };
  //const siteToken = useSelector((state) => state.saveTokenReducer.token);
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
  let [isLoader, setIsLoader] = useState(false);
  const [CustomerData, setCustomerData] = useState([]);
  const [yearData, setYearData] = useState([]);
  const [gckData, setGckData] = useState([]);
  const [engineerData, setEngineerData] = useState([]);
    const [salesOfficeData, setSalesOfficeData] = useState([]);
    const [businessUnit, setBusinessUnit] = useState([]);
    const [businessSegment, setBusinessSegment] = useState([]);
    const [salesTeritoryData, setSalesTeriotoryData] = useState([]);
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
  let [year, setYear] = useState({
    id: props.filterData?.yearid,
    name: props.filterData?.yearName,
  });
  let [filterInput, setFilterInput] = useState({
    year: { id: 0, name: "" },
    yearText: "",
    customer: [],
      gck: [],
      
      gckText:"",
    engineer: [],
    office: [],
      teritory: [],
      business: [],
      segment:[],
  });
  let [yearText, setYearText] = useState(props.filterData?.yearName);
  const handleYearChange = (e, newValue) => {
    setFilterInput({
      ...filterInput,
      year: { id: newValue?.id, name: newValue?.name },
      yearText: newValue?.name,
      customer:[],
        gck: [],
       
      engineer: [],
      office: [],
        teritory: [],
        business: [],
        segment:[],
    });
    
    // props.setFilterData({
    //   ...props.filterData,
    //   yearId: newValue?.id,
    //   yearName: newValue?.name,
    // });
    setYear({ id: newValue?.id, name: newValue?.name });
    setYearText(newValue?.name);
  };
  const handleYearInputChange = (e, text) => {
    setFilterInput({
      ...filterInput,
      yearText: text,
    });
    setYearText(text);
  };
  function getCustomerDetails(yearId) {
    setIsLoader(true);
      getCustomerDDL(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId, yearId)
      .then((response) => {
        setCustomerData(response);
        setIsLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoader(false);
      });
  }
  function getYearDetails() {
    let year = getCurrentFiscalYear();
      getYearDDL(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId)
        .then((response) => {
            console.log(response);
        let obj = response?.find((x) => x.name === year);
        setFilterInput({
          ...filterInput,
          year: { id: obj?.id, name: obj?.name },
          yearText: obj?.name,
        });
        setYearData(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getGckDetails(yearId) {
      getGckDDL(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId, yearId)
        .then((response) => {
            console.log(response);
            setGckData(response);
            
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getEngineerDetails() {
      getEngineerDDL(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId)
      .then((response) => {
        setEngineerData(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getSalesOfficeDetails() {
      getSalesBranchDDL(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId)
      .then((response) => {
        setSalesOfficeData(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getSalesTeritoryDetails() {
      getSalesTeritoryDDL(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId)
      .then((response) => {
          setSalesTeriotoryData(response);
          console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
    }
    function getBusinessUnit() {
        getBusinessUnitDDL(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {
                setBusinessUnit(response);
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    function getBusinessSegment() {
        getBusinessSegmentDDL(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {
                setBusinessSegment(response);
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    function getCust(yearId,name) {
        getCustomer(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId,yearId,name)
            .then((response) => {
                setBusinessSegment(response);
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    function hello(){
        console.log("hi gck");
    }
    function getGckmaster(yearId,gckId) {
        getGck(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId,yearId,gckId)
            .then((response) => {
                setGckData(response);
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const id = localStorage.getItem("ApplicationId");
  const FilterResult = () => {
    //props.handleClickFilterResult();
      localStorage.setItem("cus", 1);
      console.log(filterInput.segment)
      console.log(filterInput.business)
    props.setFilterData({
      ...props.filterData,
      yearId: filterInput?.year?.id,
      customer: filterInput?.customer,
        gck: filterInput?.gck,
       
      engineer: filterInput?.engineer,
      office: filterInput?.office,
        teritory: filterInput?.teritory,
        bsegment: filterInput?.segment,
        bunit: filterInput?.business,
    });

      
          setFormDialogTitle("Save");
          setOpenForm(true);
      
    };
    function clearFormValues() {
        props.setFilterData({
            ...props.filterData,
            yearId: "",
            customer: [],
            gck: [],
            
            
        });
        
    }
    const handleClose = (e) => {
        setOpen(false);
    };
    const logAddCustomer = () => {
        //props.handleClickFilterResult();
        let dist = filterInput?.engineer;
        if (userDetails.roleId == 1 || userDetails.roleId==5) {
            if (dist === "" || dist === null || dist === undefined || dist.length <= 0) {
                handleCloseForm();
                handleSnackOpen("please select  sales district", "error");
               
                return;
            }
        }
        let data =
           {
                yearid: filterInput?.year?.id,
                CustomerList: filterInput?.customer,
                GckList: filterInput?.gck,
                loggedinuserid: userDetails.userId,
                SdistrictList: filterInput?.engineer,
            };
       
        //props.setFilterData({
        //    ...props.filterData,
        //    yearId: filterInput?.year?.id,
        //    customer: filterInput?.customer,
        //    gck: filterInput?.gck,

        //    engineer: filterInput?.engineer,
        //    office: filterInput?.office,
        //    teritory: filterInput?.teritory,
        //    segment: filterInput?.segment,
        //});
        let customer = filterInput?.customer;
        let gck = filterInput?.gck;
        let yearId = filterInput?.year?.id;
        
        //let data = {
        //    yearid: filterInput?.year?.id,
        //    CustomerList: customer,
        //    GckList: gck,
        //    loggedinuserid: userDetails.userId,
        //    SdistrictList:dist,
        //}
       
        console.log(data);
        addSales(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, data)
       
            .then((response) => {
                console.log(response);
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    FilterResult();
                   
                    handleCloseForm();
                    setIsLoader(false);
                    getYearDetails();

                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                setIsLoader(false);
            });
    };

    function func() {
        const Filter = () => {
            //props.handleClickFilterResult();

            props.setFilterData({
                ...props.filterData,
                yearid: filterInput?.year?.id,
                customerId: filterInput?.customer[0].id,
                gckId: filterInput?.gck[0].id,
                loggedinuserid: userDetails.userId,


            });
        };
        addSales(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
                .then((response) => {
                    if (response.response) {
                        handleSnackOpen(response.responseMsg, "success");
                        setIsLoader(false);
                       
                    } else {
                        handleSnackOpen(response.responseMsg, "error");
                        setIsLoader(false);
                    }
                })
                .catch((error) => {
                    handleSnackOpen("Exception : " + error, "error");
                    setIsLoader(false);
                });
        

            

       
    }

  useEffect(() => {
    getYearDetails();
    getEngineerDetails();
    getSalesOfficeDetails();
      getSalesTeritoryDetails();
      getBusinessUnit();
      getBusinessSegment();
      localStorage.setItem("cus", 0); 
      
  }, []);

  useEffect(() => {
    if (filterInput?.year?.id > 0) {
      getCustomerDetails(filterInput?.year?.id);
      getGckDetails(filterInput?.year?.id);
      }
      if (filterInput?.year?.id > 0 && filterInput?.gck?.id > 0) {
          getGckmaster(filterInput?.year?.id, filterInput?.gck?.id);
      }
  }, [filterInput?.year]);
  
  useEffect(() => {
    if (filterInput?.year?.id > 0) {
      switch (userDetails.roleId) {
        case 6:
          let engineer = [
            { id: userDetails?.sdistrictId, name: userDetails?.sdistrict },
          ];
          setFilterInput({
            ...filterInput,
            engineer: engineer,
          });
          // props.setFilterData({
          //   ...props.filterData,
          //   engineer: engineer,
          //   yearId: filterInput?.year?.id,
          //   customer: [],
          //   gck: [],
          // });
          break;
        case 5:
          let office = [
            { id: userDetails?.sbranchId, name: userDetails?.sbranch },
          ];
          setFilterInput({
            ...filterInput,
            office: office,
          });
          // props.setFilterData({
          //   ...props.filterData,
          //   office: office,
          //   yearId: filterInput?.year?.id,
          //   customer: [],
          //   gck: [],
          //   engineer: [],
          //   teritory: [],
          // });
          break;
        default:
          // props.setFilterData({
          //   ...props.filterData,
          //   yearId: filterInput?.year?.id,
          //   customer: [],
          //   gck: [],
          //   engineer: [],
          //   office: [],
          //   teritory: [],
          // });
          break;
      }
    }
  }, [userDetails, filterInput?.year?.id]);
    
  return (
    <>
      {isLoader ? (
        <Grid item xs={12} sm={12} className="d-flex jc-center pd-2">
          {getProgressComponent()}
        </Grid>
      ) : (
        <>
          <Grid
            item
            xs={12}
            sm={userDetails.roleId !== 6 ? 3 : 2}
            className="pd-1"
          >
            <Autocomplete
              filterOptions={filterOptions}
              disableClearable
              fullWidth
              name="YearId"
              value={filterInput.year}
              inputValue={filterInput.yearText}
              select
              disabled={!yearData?.length > 0}
              loading
              loadingText="No Options"
              id="YearId"
              freeSolo
              margin="normal"
              options={yearData}
              onChange={handleYearChange}
              onInputChange={handleYearInputChange}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              getOptionDisabled={(option) => option.id < 2}
              renderInput={(params) => (
                <TextField {...params} label="&nbsp;&nbsp;Fiscal Year" />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={userDetails.roleId !== 6 ? 6 : 5}
            className="pd-1"
          >
            <Autocomplete
              filterOptions={filterOptions}
              multiple
              disableClearable
              loading
              loadingText="No Options(customer is not there in the database)"
              freeSolo
              id="Customer"
              name="Customer"
              disabled={!CustomerData?.length > 0}
              options={CustomerData}
              value={filterInput.customer}
              onChange={(event, value) => {
                setFilterInput((old) => {
                  return {
                    ...old,
                    customer: value,
                  };
                });
              }}
              fullWidth
              limitTags={1}
                              disableCloseOnSelect
               
              getOptionLabel={(option) => (option.name ? option.name : "")}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField {...params} label="&nbsp;&nbsp;Customer" />
                              )}
               
                          />
                          
          </Grid>

          <Grid item xs={12} sm={3} className="pd-1">
            <Autocomplete
              filterOptions={filterOptions}
              multiple
              disableClearable
              loading
              loadingText="No Options(gck is not there in the database)"
              freeSolo
              id="gck"
              disabled={!gckData?.length > 0}
              options={gckData}
              value={filterInput.gck}
              onChange={(event, value) => {
                setFilterInput((old) => {
                  return {
                    ...old,
                      gck: value,
                      
                  };
                });
              }}
              fullWidth
              limitTags={2}
              disableCloseOnSelect
              getOptionLabel={(option) => (option.name ? option.name : "")}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField {...params} label="&nbsp;&nbsp;GCK" />
              )}
            />
                      </Grid>
                      
          {userDetails.roleId !== 6 ? (
            <>
              <Grid item xs={12} sm={4} className="pd-1">
                <Autocomplete
                  filterOptions={filterOptions}
                  multiple
                  loading
                  loadingText="No Options"
                  disableClearable
                  freeSolo
                  id="Engineer Data"
                  disabled={!engineerData?.length > 0}
                  options={engineerData}
                  fullWidth
                  value={filterInput.engineer}
                  onChange={(event, value) => {
                    setFilterInput((old) => {
                      return {
                        ...old,
                        engineer: value,
                      };
                    });
                  }}
                  limitTags={2}
                  disableCloseOnSelect
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="&nbsp;&nbsp;Sales District" />
                  )}
                />
              </Grid>
              {userDetails.roleId !== 5 ? (
                <Grid item xs={12} sm={3} className="pd-1">
                  <Autocomplete
                    filterOptions={filterOptions}
                    disableClearable
                    multiple
                    loading
                    loadingText="No Options"
                    freeSolo
                    id="Office"
                    disabled={!salesOfficeData?.length > 0}
                    options={salesOfficeData}
                    fullWidth
                    value={filterInput.office}
                    onChange={(event, value) => {
                      setFilterInput((old) => {
                        return {
                          ...old,
                          office: value,
                        };
                      });
                    }}
                    limitTags={4}
                    disableCloseOnSelect
                    getOptionLabel={(option) =>
                      option.name ? option.name : ""
                    }
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="&nbsp;&nbsp;Sales Office" />
                    )}
                  />
                </Grid>
              ) : (
                <></>
              )}
              <Grid item xs={12} sm={3} className="pd-1">
                <Autocomplete
                  filterOptions={filterOptions}
                  disableClearable
                  multiple
                  loading
                  loadingText="No Options"
                  freeSolo
                  id="Teritory"
                  disabled={!salesTeritoryData?.length > 0}
                  options={salesTeritoryData}
                  fullWidth
                  value={filterInput.teritory}
                  onChange={(event, value) => {
                    setFilterInput((old) => {
                      return {
                        ...old,
                        teritory: value,
                      };
                    });
                  }}
                  limitTags={4}
                  disableCloseOnSelect
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8, alignItems: "center" }}
                        checked={selected}
                      />
                      {option.name}
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="&nbsp;&nbsp;Sales Territory" />
                  )}
                />
              </Grid>
            </>
          ) : (
            <></>
                      )}
                      {userDetails.roleId == 1 ? ( 
                      <Grid item xs={12} sm={3} className="pd-1">
                          <Autocomplete
                              filterOptions={filterOptions}
                              disableClearable
                              multiple
                              loading
                              loadingText="No Options"
                              freeSolo
                              id="Teritory"
                              disabled={!businessUnit?.length > 0}
                              options={businessUnit}
                              fullWidth
                              value={filterInput.business}
                              onChange={(event, value) => {
                                  setFilterInput((old) => {
                                      return {
                                          ...old,
                                          business: value,
                                      };
                                  });
                              }}
                              limitTags={4}
                              disableCloseOnSelect
                              getOptionLabel={(option) => (option.name? option.name : "")}
                              renderOption={(option, { selected }) => (
                                  <React.Fragment>
                                      <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          style={{ marginRight: 8, alignItems: "center" }}
                                          checked={selected}
                                      />
                                      {option.name}
                                  </React.Fragment>
                              )}
                              renderInput={(params) => (
                                  <TextField {...params} label="&nbsp;&nbsp;Business Unit" />
                              )}
                          />
                      </Grid>
                  
                      ) : (
                          <></>
                      )}
                      {userDetails.roleId == 1 ? ( 
                      <Grid item xs={12} sm={3} className="pd-1">
                          <Autocomplete
                              filterOptions={filterOptions}
                              disableClearable
                              multiple
                              loading
                              loadingText="No Options"
                              freeSolo
                              id="Segment"
                              disabled={!businessSegment?.length > 0}
                              options={businessSegment}
                              fullWidth
                              value={filterInput.segment}
                              onChange={(event, value) => {
                                  setFilterInput((old) => {
                                      return {
                                          ...old,
                                          segment: value,
                                      };
                                  });
                              }}
                              limitTags={4}
                              disableCloseOnSelect
                              getOptionLabel={(option) => (option.name? option.name : "")}
                              renderOption={(option, { selected }) => (
                                  <React.Fragment>
                                      <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          style={{ marginRight: 8, alignItems: "center" }}
                                          checked={selected}
                                      />
                                      {option.name}
                                  </React.Fragment>
                              )}
                              renderInput={(params) => (
                                  <TextField {...params} label="&nbsp;&nbsp;Business Segment" />
                              )}
                          />
                      </Grid>
                      ) : (
                          <></>
                      )}
                      
          <Grid item xs={12} sm={2} className=" d-flex jc-center pd-1">
            <Button onClick={FilterResult} className="pt-button--secondary">
              Filter Result
            </Button>
          </Grid>
        </>
          )}
          
          <Dialog fullWidth
              onClose={(event, reason) => {
                  if (reason !== "backdropClick") {
                      handleCloseForm();
                  }
              }}
              aria-labelledby="litigation-form--registration"
              open={openForm && id=="1"||openForm &&id=="2"}
              maxWidth={"lg"}
              disableEscapeKeyDown
          >
              <DialogTitle id="responsive-dialog-title">Add Row</DialogTitle>
              <div style={{ position: "absolute", right: "1%" }}>
                  <Tooltip title="Close">
                      <IconButton onClick={handleCloseForm}>
                          <CloseIcon />
                      </IconButton>
                  </Tooltip>
              </div>
              <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                      <Grid container spacing={2}>
                          <Grid
                              item
                              xs={12}
                               sm={4} md={3}
                              sm={userDetails.roleId !== 6 ? 3 : 2}
                              className="pd-1"
                          >
                              <Autocomplete
                                  filterOptions={filterOptions}
                                  disableClearable
                                  fullWidth
                                  name="YearId"
                                  value={filterInput.year}
                                  inputValue={filterInput.yearText}
                                  select
                                  disabled={!yearData?.length > 0}
                                  loading
                                  loadingText="No Options"
                                  id="YearId"
                                  freeSolo
                                  margin="normal"
                                  options={yearData}
                                  onChange={handleYearChange}
                                  onInputChange={handleYearInputChange}
                                  getOptionLabel={(option) => (option.name ? option.name : "")}
                                  getOptionDisabled={(option) => option.id < 2}
                                  renderInput={(params) => (
                                      <TextField {...params} label="&nbsp;&nbsp;Fiscal Year" />
                                  )}
                              />
                          </Grid>
                          <Grid
                              item
                              xs={12}
                              sm={4} md={3}
                              sm={userDetails.roleId !== 6 ? 6 : 5}
                              className="pd-1"
                          >
                              <Autocomplete
                                  filterOptions={filterOptions}
                                  multiple
                                  disableClearable
                                  loading
                                  loadingText="No Options"
                                  freeSolo
                                  id="Customer"
                                  name="Customer"
                                  disabled={!CustomerData?.length > 0}
                                  options={CustomerData}
                                  value={filterInput.customer}
                                  onChange={(event, value) => {
                                      setFilterInput((old) => {
                                          return {
                                              ...old,
                                              customer: value,
                                          };
                                      });
                                  }}
                                  fullWidth
                                  limitTags={1}
                                  disableCloseOnSelect
                                  getOptionLabel={(option) => (option.name ? option.name : "")}
                                  renderOption={(option, { selected }) => (
                                      <React.Fragment>
                                          <Checkbox
                                              icon={icon}
                                              checkedIcon={checkedIcon}
                                              style={{ marginRight: 8 }}
                                              checked={selected}
                                          />
                                          {option.name}
                                      </React.Fragment>
                                  )}
                                  renderInput={(params) => (
                                      <TextField {...params} label="&nbsp;&nbsp;Customer" />
                                  )}
                              />
                          </Grid>

                          <Grid item xs={12} sm={4} md={3} >
                              <Autocomplete
                                  filterOptions={filterOptions}
                                  multiple
                                  disableClearable
                                  loading
                                  loadingText="No Options"
                                  freeSolo
                                  id="gck"
                                  //onClick={func }
                                  //disabled={!gckData?.length > 0}
                                  options={gckData}
                                  value={filterInput.gck}
                                  onChange={(event, value) => {
                                      setFilterInput((old) => {
                                          return {
                                              ...old,
                                              gck: value,
                                          };
                                      });
                                  }}
                                  fullWidth
                                  limitTags={2}
                                  disableCloseOnSelect
                                  getOptionLabel={(option) => (option.name ? option.name : "")}
                                  renderOption={(option, { selected }) => (
                                      <React.Fragment>
                                          <Checkbox
                                              icon={icon}
                                              checkedIcon={checkedIcon}
                                              style={{ marginRight: 8 }}
                                              checked={selected}
                                          />
                                          {option.name}
                                      </React.Fragment>
                                  )}
                                  renderInput={(params) => (
                                      <TextField {...params} label="&nbsp;&nbsp;GCK" />
                                  )}
                              />
                          </Grid>
                          
                      </Grid>
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button
                      type="submit"
                      onClick={logAddCustomer }
                      className="pt-button--secondary"
                      id="addUserSave"
                     
                  >
                      {formDialogTitle}
                  </Button>
              </DialogActions>
          </Dialog>
          <SnackbarCustom
              open={open}
              message={snackMessage}
              alertType={alertType}
              handleClose={handleClose}
          />

    </>
  );
}
