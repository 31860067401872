import { baseApiURL } from "../../Utilities/Utility";
import axios from "axios";

//UserMaster

export function getUserDetails(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/Admin/GetUserDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function addUserDetails(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PostUserDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function updateUserDetails(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            //Authorization: "Bearer " + siteToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PutUserDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function updateUser(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            //Authorization: "Bearer " + siteToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/SalesOrder/UpdateSalesDistrictDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function deleteUserDetails(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            //Authorization: "Bearer " + siteToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/DeleteUserDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
/*   Fin Year Master  */

export function getFinYearData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/Common/GetFinYearDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function addFinYearData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Common/PostFinYearDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function updateFinYearData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            //Authorization: "Bearer " + siteToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Common/PutFinYearDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function deleteFinYearData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            //Authorization: "Bearer " + siteToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,

        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Common/DeleteFinYearDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
/*   Role Master  */

export function getRoleData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/Admin/GetRoleDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function addRoleData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "post",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PostRoleDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function updateRoleData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            //Authorization: "Bearer " + siteToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PutRoleDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function deleteRoleData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            //Authorization: "Bearer " + siteToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/DeleteRoleDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
/*   Customer Master  */

export function getAllCustomerDatayear(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, yearId) {
    const options = {
        method: "get",
        url: baseApiURL + "/Admin/GetCustomerDetailsPerYear?encUserId=" + encodeURIComponent(userId) + "&yearId=" + yearId,
        headers: {
            //"Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return (
        axios(options)
            //.then((response) => response.json())
            .then((response) => {
                return response.data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}
export function getCustomerData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/Admin/GetCustomerDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function addCustomerData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PostCustomerDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function updateCustomerData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            //Authorization: "Bearer " + siteToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PutCustomerDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function deleteCustomerData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            // Authorization: "Bearer " + siteToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/DeleteCustomerDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

/*   GCK Master  */
// export function getAllGckDatayear(siteToken, userId,yearId) {
//     const options = {
//         method: "get",
//         headers: { Authorization: "Bearer " + siteToken },
//     };
//     return fetch(baseApiURL + "/Admin/GetAllGcksperyear?userId=" + userId& "yearId="+yearId, options)
//         .then((response) => response.json())
//         .then((data) => {
//             return data.result;
//         })
//         .catch((error) => {
//             console.log("Error : ", error);
//         });
// }

export function getGckData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/Admin/GetGckDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getAllGckDatayear(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, yearId) {
    const options = {
        method: "get",
        url: baseApiURL + "/Admin/GetGckDetailsPerYear?encUserId=" + encodeURIComponent(userId) + "&yearId=" + yearId,
        headers: {
            // "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,

        },
        //params: { userId: userId, yearId: yearId },
    };
    return (
        axios(options)
            //.then((response) => response.json())
            .then((response) => {
                return response.data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}
export function addGckData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PostGckDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function updateGckData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            //Authorization: "Bearer " + siteToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,

        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PutGckDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function deleteGckData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            // Authorization: "Bearer " + siteToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/DeleteGckDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
/*   Sales Engineer Master  */

export function getSalesEngineerData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/Admin/GetSalesDistrictDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function addSalesEngineerData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PostSalesDistrictDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function updateSalesEngineerData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            //Authorization: "Bearer " + siteToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,

        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PutSalesDistrictDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function deleteSalesEngineerData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            //Authorization: "Bearer " + siteToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/DeleteSalesDistrictDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

/*   Sales Branch Master  */

export function getSalesBranchData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/Admin/GetSalesBranchDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function addSalesBranchData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PostSalesBranchDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function updateSalesBranchData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            //Authorization: "Bearer " + siteToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PutSalesBranchDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function deleteSalesBranchData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            //Authorization: "Bearer " + siteToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/DeleteSalesBranchDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

/*   Sales Office Master  */

export function getSalesOfficeData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/Admin/GetSalesOfficeDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function addSalesOfficeData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PostSalesOfficeDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function updateSalesOfficeData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            // Authorization: "Bearer " + siteToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,

        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PutSalesOfficeDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function deleteSalesOfficeData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            //Authorization: "Bearer " + siteToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/DeleteSalesOfficeDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

/*   Sales Department Master  */

export function getSalesDepartmentData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/Admin/GetSalesDepartmentDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function addSalesDepartmentData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PostSalesDepartmentDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function updateSalesDepartmentData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            // Authorization: "Bearer " + siteToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PutSalesDepartmentDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function deleteSalesDepartmentData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            //Authorization: "Bearer " + siteToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/DeleteSalesDepartmentDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

/*   Sales Teritory Master  */

export function getSalesTeritoryData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/Admin/GetSalesTeritoryDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function addSalesTeritoryData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PostSalesTeritoryDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function updateSalesTeritoryData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            //Authorization: "Bearer " + siteToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PutSalesTeritoryDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function deleteSalesTeritoryData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            //Authorization: "Bearer " + siteToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/DeleteSalesTeritoryDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
//Business Unit

export function getBusinessUnitData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/Admin/GetBusinessUnitDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function addBusinessUnitData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PostBusinessUnitDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function updateBusinessUnitData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            // Authorization: "Bearer " + siteToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PutBusinessUnitDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function deleteBusinessUnitData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            // Authorization: "Bearer " + siteToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/DeleteBusinessUnitDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
//Business segment

export function getBusinessSegmentData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/Admin/GetBusinessSegmentDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function addBusinessSegmentData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PostBusinessSegmentDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function updateBusinessSegmentData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            //Authorization: "Bearer " + siteToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PutBusinessSegmentDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function deleteBusinessSegmentData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            // Authorization: "Bearer " + siteToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/DeleteBusinessSegmentDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
//Business sub segment

export function getBusinessSubSegmentData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/Admin/GetBusinessSubSegmentDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function addBusinessSubSegmentData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PostBusinessSubSegmentDetails?encUserId=" + userId, options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function updateBusinessSubSegmentData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            //Authorization: "Bearer " + siteToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,

        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PutBusinessSubSegmentDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function deleteBusinessSubSegmentData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            //Authorization: "Bearer " + siteToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,

        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/DeleteBusinessSubSegmentDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function addSales(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/SalesOrder/PostSalesOrderData?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}