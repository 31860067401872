import React, { useState, useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useSelector } from "react-redux";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
//import { Buffer } from 'buffer';
import {
  getCustomerDDL,
  getYearDDL,
  getGckDDL,
  getEngineerDDL,
  getSalesBranchDDL,
  getSalesTeritoryDDL,
} from "../../Redux/APIs/api_Common";
import {
  IconButton,
  makeStyles,
  TableBody,
  ThemeProvider,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import MaterialTable, {
  MTableBody,
  MTableGroupRow,
  MTableHeader,
  MTablePagination,
} from "material-table";
import {
  getRoundUptoXDecimals,
  tableCellCurrentFY,
  tableCellPreviousFY,
  tableIcons,
  renderValueWithThousandSeparator,
  tdInputCurrentFY,
  tdTotalInPut,
} from "../../Utilities/Utility";
import { lightTheme } from "../../Themes/LightTheme";
import FilterList from "./FilterList";
import "./OrderList.scss";
import { getSalesOrdersList } from "../../Redux/APIs/api_OrderList";
import { getCurrentFiscalYear } from "../../Utilities/Utility";
import { sassNull } from "sass";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
const Buffer = require('buffer').Buffer;
export default function OrderList() {
  const tableRef = useRef();
  const icon = () => {
    return <GetAppRoundedIcon style={{ fontSize: 53 }} />;
  };
  const useStyles = makeStyles(() => ({
    paperSty: {
      padding: "40px",
      marginTop: "30px",
    },
    childGrid: {
      marginTop: "20px",
    },
    btnGrid: {
      justifyContent: "center",
      marginTop: "2rem",
    },
    tableCellPreviousFY: tableCellPreviousFY,
    tableCellCurrentFY: tableCellCurrentFY,
    tableCellTotal: tdTotalInPut,
  }));
  const classes = useStyles();
  const [CustomerData, setCustomerData] = useState([]);
  const [yearData, setYearData] = useState([]);
  const [gckData, setGckData] = useState([]);
  const [engineerData, setEngineerData] = useState([]);
  const [salesOfficeData, setSalesOfficeData] = useState([]);
  const [salesTeritoryData, setSalesTeriotoryData] = useState([]);
    const [yearid, setYearId] = useState(0);
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
  const [yearName, setYearName] = useState("");
  function getCustomerDetails(yearId) {
      getCustomerDDL(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId,yearId)
      .then((response) => {
        if (response) {
          setCustomerData(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  // function getYearDetails() {
  //   getYearDDL(token, userdetails.userId)
  //     .then((response) => {
  //       setYearData(response);
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //     });
  // }
  function getYearDetails() {
    let year = getCurrentFiscalYear();
      getYearDDL(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId).then((response) => {
      let obj = response?.find((x) => x.name === year);
      setFilterData({
        ...filterData,
        yearId: obj?.id,
      });
      setYearData(response);
    });
  }
  function getGckDetails(yearId) {
      getGckDDL(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId, yearId)
      .then((response) => {
        setGckData(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getEngineerDetails() {
      getEngineerDDL(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId)
      .then((response) => {
        setEngineerData(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getSalesOfficeDetails() {
      getSalesBranchDDL(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId)
      .then((response) => {
        setSalesOfficeData(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }
    const handleClickOpenDialog = () => {
       
        setOpenDialog(true);
    };
    const handleCloseDialogBox = () => {

        setOpenBox(false);
    };
    const handleCloseDialogBoxGck = () => {

        setOpenBoxGck(false);
    };
    const handleOpenDialogBox = () => {

        setOpenBox(true);
    };
    const handleOpenDialogBoxGck = () => {

        setOpenBoxGck(true);
    };
    const handleOpenDialogBoxCustomer = () => {

        setOpenBoxCustomer(true);
    };
    const handleCloseDialogBoxCustomer = () => {

        setOpenBoxCustomer(false);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleClickOpenDialogGck = () => {

        setOpenDialogGck(true);
    };
    const handleCloseDialogGck = () => {

        setOpenDialogGck(false);
    };
    const handleClickOpenDialogGckBox = () => {

        setOpenDialogGckBox(true);
    };

    const handleCloseDialogGckBox = () => {
        setOpenDialogGckBox(false);
    };
    const c = localStorage.getItem("cus"); 
    console.log(c);
  const [isLoader, setIsLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const [openBox, setOpenBox] = useState(false);
    const [openBoxGck, setOpenBoxGck] = useState(false);
    const [openBoxCustomer, setOpenBoxCustomer] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogGck, setOpenDialogGck] = useState(false);
    const [openDialogGckBox, setOpenDialogGckBox] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpen(true);
  };
  const handleClose = (e) => {
    setOpen(false);
  };
  let [totals, setTotals] = useState([
    {
      totalPotentialCurrent: 0,
      totalBudgetCurrent: 0,
      totalActualsCurrent: 0,
      sowPercent: 0,
      totalPotentialPrevious: 0,
      totalBudgetPrevious: 0,
      totalActualsPrevious: 0,
      achievementPercent: 0,
    },
  ]);
  const token = useSelector((state) => state.saveTokenReducer.token);
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );

  function goToInputScreen(data) {
    const params =
      "yearId=" +
      data?.yearId +
      "&customerId=" +
      data?.customerId +
      "&customer=" +
      data?.customer +
      "&gckId=" +
      data?.gckId +
      "&gck=" +
      data?.gck +
      "&sdistrictId=" +
      data?.sdistrictId +
      "&sdistrict=" +
      data?.sdistrict;
    const url = "/orderInput?" + Buffer.from(params)?.toString("base64");
    window.open(url, "_blank");
  }
  // function getViewOrderDetailsButton(data) {
  //   return (
  //     <Link
  //       to={{
  //         pathname: "/orderInput",
  //         state: {
  //           filters: filterData,
  //           rowData: data,
  //         },
  //       }}
  //     >
  //       <VisibilityIcon />
  //     </Link>
  //   );
  // }
  function getViewOrderDetailsButton(data) {
    return (
      <IconButton
        title="Go to input screen"
        onClick={() => goToInputScreen(data)}
      >
        <VisibilityIcon />
      </IconButton>
    );
  }
  let [filterData, setFilterData] = useState({
    yearId: yearid,
    customer: [],
    gck: [],
    engineer:
      userDetails?.roleId === 6
        ? [{ id: userDetails?.sdistrictId, name: userDetails?.sdistrict }]
        : [],
    office:
      userDetails?.roleId === 5
        ? [{ id: userDetails?.sbranchId, name: userDetails?.sbranch }]
        : [],
      teritory: [],
      bunit: [],
      bsegment:[],
    loggedInUserId: userDetails.userId,
  });
  let [finYear, setFinYear] = useState({
    previousyear: "Previous FY",
    currentyear: "Current FY",
  });
  const [tableData, setTableData] = useState([]);
    function getSalesOrderData(filterData) {
        setTotals([
            {
                totalPotentialCurrent: 0,
                totalBudgetCurrent: 0,
                totalActualsCurrent: 0,
                sowPercent: 0,
                totalPotentialPrevious: 0,
                totalBudgetPrevious: 0,
                totalActualsPrevious: 0,
                achievementPercent: 0,
            },
        ]);
    let yearname = yearData.find((x) => x.id == filterData.yearId)?.name;
    setIsLoader(true);
        getSalesOrdersList(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, filterData)
        .then((response) => {
            console.log(response);
            
            if (response.responseCustomer === 2 ) {
                handleClickOpenDialog();
            }
            
            // if(response.responseCustomer === 1) {
            //    handleOpenDialogBoxCustomer();
            //}

            if (response.responseGck === 2 ) {
                handleClickOpenDialogGck();
            }
            
            if (response.combinationcheck == 2) {
                handleClickOpenDialog();
            }
           

        if (response.response) {
          setTableData(response.objResult.listPlanningDetails);
          setTotals([{ ...response.objResult.totals }]);
          if (yearname === "2020-21") {
            setFinYear({
              previousyear: "2019-20",
              currentyear: response.objResult.listPlanningDetails[0].year,
            });
          } else {
            setFinYear({
              previousyear:
                response.objResult.listPlanningDetails[0].previousYear,
              currentyear: response.objResult.listPlanningDetails[0].year,
            });
          }
            setIsLoader(false);
            response.combinationcheck = 0;
            response.reponseGck = 0;
            response.responseCustomer = 0;
        } else {
          setTableData([]);
            handleSnackOpen(response.responseMsg, "info");
            response.combinationcheck = 0;
            response.reponseGck = 0;
            response.responseCustomer = 0;
          setIsLoader(false);
        }
      })
      .catch((error) => {
        console.log("Error : " + error, "error");
        handleSnackOpen("Exception occured while fetching order list", "error");
        setIsLoader(false);
      });
    }
    function add() {
        handleCloseDialog();
        localStorage.setItem("ApplicationId", 1);


    }
    function addgck() {
        handleCloseDialogGck();
        localStorage.setItem("ApplicationId", 2);

    }

  var columns = [
    { title: "SalesOrderId", field: "salesOrderDetailsId", hidden: true },
    { title: "YearId", field: "yearId", hidden: true },
    {
      title: "View",
      field: "",
      render: (rowData) => getViewOrderDetailsButton(rowData),
    },
    {
      title: "Customer Name",
      field: "customer",
    },
    {
      title: "IFA Number",
      field: "ifaNumber",
      },
      {
          title: "City",
          field: "city",
      },
      {
          title: "Role",
          field: "role",
      },
      {
          title: "Industry",
          field: "industry",
      },
      {
          title: "Industry Segment",
          field: "industrySegment",
      },
    {
      title: "Sales Office",
      field: "sbranch",
    },
    {
      title: "Sales Teritory",
      field: "steritory",
    },
    {
      title: "Sales Engineer",
      field: "sdistrict",
      },
      
    {
      title: "GCK",
      field: "gck",
    },
    {
      title: "Potential",
      field: "potentialPrevious",
      cellStyle: tableCellPreviousFY,
      headerStyle: tableCellPreviousFY,
    },
    {
      title: "Budget",
      field: "budgetPrevious",
      cellStyle: tableCellPreviousFY,
      headerStyle: tableCellPreviousFY,
    },
    {
      title: "New Orders",
      field: "actualsPrevious",
      cellStyle: tableCellPreviousFY,
      headerStyle: tableCellPreviousFY,
    },
    {
      title: "SOW (%)",
      field: "sow",
      cellStyle: tableCellPreviousFY,
      headerStyle: tableCellPreviousFY,
      render: (rowData) => <>{getRoundUptoXDecimals(rowData.sow, 0)}</>,
    },
    {
      title: "Potential",
      field: "potentialCurrent",
      cellStyle: tableCellCurrentFY,
      headerStyle: tableCellCurrentFY,
    },
    {
      title: "Budget",
      field: "budgetCurrent",
      cellStyle: tableCellCurrentFY,
      headerStyle: tableCellCurrentFY,
    },
    {
      title: "New Orders",
      field: "actualsCurrent",
      cellStyle: tableCellCurrentFY,
      headerStyle: tableCellCurrentFY,
    },
    {
      title: "Achivement (%)",
      field: "achievement",
      cellStyle: tableCellCurrentFY,
      headerStyle: tableCellCurrentFY,
      render: (rowData) => <>{getRoundUptoXDecimals(rowData.achievement, 0)}</>,
    },
  ];
    
  function getCustomTHead(params) {
    return (
      <TableHead>
        <TableRow>
          <TableCell colSpan={11} align="center"></TableCell>
          <TableCell
            className={classes.tableCellPreviousFY}
            colSpan={4}
            align="center"
            style={{ fontWeight: "bold" }}
          >
            {finYear.previousyear}
          </TableCell>
          <TableCell
            className={classes.tableCellCurrentFY}
            colSpan={4}
            align="center"
            style={{ fontWeight: "bold" }}
          >
            {finYear.currentyear}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }
  function getCustomTBodyForTotal(data) {
    return (
      <TableBody style={{ position: "sticky", top: 60 }}>
        {data.map((row) => (
          <TableRow className={classes.tableCellTotal}>
            <TableCell
              align="center"
              colSpan={11}
              style={{ fontWeight: "bold" }}
            >
              TOTAL
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }}>
              {renderValueWithThousandSeparator(
                getRoundUptoXDecimals(row.totalPotentialPrevious, 2)
              )}
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }}>
              {renderValueWithThousandSeparator(
                getRoundUptoXDecimals(row.totalBudgetPrevious, 2)
              )}
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }}>
              {renderValueWithThousandSeparator(
                getRoundUptoXDecimals(row.totalActualsPrevious, 2)
              )}
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }}>
              {renderValueWithThousandSeparator(
                getRoundUptoXDecimals(row.sowPercent, 0)
              )}
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }}>
              {renderValueWithThousandSeparator(
                getRoundUptoXDecimals(row.totalPotentialCurrent, 2)
              )}
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }}>
              {renderValueWithThousandSeparator(
                getRoundUptoXDecimals(row.totalBudgetCurrent, 2)
              )}
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }}>
              {renderValueWithThousandSeparator(
                getRoundUptoXDecimals(row.totalActualsCurrent, 2)
              )}
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }}>
              {renderValueWithThousandSeparator(
                getRoundUptoXDecimals(row.achievementPercent, 0)
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
  }
  function handleClickFilterResult() {
    getSalesOrderData(filterData);
  }
  function ExportToExcel() {
    const dataToDownload = tableRef?.current?.dataManager?.searchedData.map(
      (row) => {
        return {
          customerName: row.customer,
            ifaNumber: row.ifaNumber,
            city: row.city,
            role: row.role,
            industry: row.industry,
            industrySegment: row.industrySegment,
          salesOffice: row.sbranch,
          salesTeritory: row.steritory,
          salesEngineer: row.sdistrict,
          gck: row.gck,
          PreviousPotential: row.potentialPrevious,
          PreviousBudget: row.budgetPrevious,
          PreviousNewOrders: row.actualsPrevious,
          sow: row.sow,
          CurrentPotential: row.potentialCurrent,
          CurrentBudget: row.budgetCurrent,
          CurrentNewOrders: row.actualsCurrent,
          Acheivement: row.achievement,
        };
      }
    );
    let headers = [
      "Customer Name",
        "IFA Number",
        "City",
        "Role",
        "Industry",
        "Industry Segment",
      "Sales Office",
      "Sales Teritory",
      "Sales Engineer",
      "GCK",
      "Previous Potential",
      "Previous Budget",
      "Previous New Orders",
      "SOW",
      "Current Potential",
      "Current Budget",
      "Current New Orders",
      "Acheivement",
    ];
    //const workSheet = XLSX.utils.json_to_sheet(newData);
    let ws = XLSX.utils.book_new();
    let wb = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, [headers]);
    XLSX.utils.sheet_add_json(ws, dataToDownload, {
      origin: "A2",
      skipHeader: true,
      cellStyles: true,
    });
    const dataForCellAdjustments = dataToDownload;
    const headerObj = {
      customerName: headers[0],
      ifaNumber: headers[1],
      salesOffice: headers[2],
      salesTeritory: headers[3],
      salesEngineer: headers[4],
      gck: headers[5],
      PreviousPotential: headers[6],
      PreviousBudget: headers[7],
      PreviousNewOrders: headers[8],
      sow: headers[9],
      CurrentPotential: headers[10],
      CurrentBudget: headers[11],
      CurrentNewOrders: headers[12],
      Acheivement: headers[13],
    };
    dataForCellAdjustments.push(headerObj);
    ws["!cols"] = cellAdjustToContents(dataForCellAdjustments);
    XLSX.utils.book_append_sheet(wb, ws, "SalesOrder");
    XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(wb, "SalesOrders.xlsx");
  }
  function cellAdjustToContents(data) {
    return [
      {
        wch: Math.max(
          ...data?.map((c) =>
            c.customerName ? c.customerName?.toString()?.length : 0
          )
        ),
      },
      {
        wch: Math.max(
          ...data?.map((c) =>
            c.ifaNumber ? c.ifaNumber?.toString()?.length : 0
          )
        ),
      },
      {
        wch: Math.max(
          ...data?.map((c) =>
            c.salesOffice ? c.salesOffice?.toString()?.length : 0
          )
        ),
      },
      {
        wch: Math.max(
          ...data?.map((c) =>
            c.salesTeritory ? c.salesTeritory?.toString()?.length : 0
          )
        ),
      },
      {
        wch: Math.max(
          ...data?.map((c) =>
            c.salesEngineer ? c.salesEngineer?.toString()?.length : 0
          )
        ),
      },
      {
        wch: Math.max(
          ...data?.map((c) => (c.gck ? c.gck?.toString()?.length : 0))
        ),
      },
      {
        wch: Math.max(
          ...data?.map((c) =>
            c.PreviousPotential ? c.PreviousPotential?.toString()?.length : 0
          )
        ),
      },
      {
        wch: Math.max(
          ...data?.map((c) =>
            c.PreviousBudget ? c.PreviousBudget?.toString()?.length : 0
          )
        ),
      },
      {
        wch: Math.max(
          ...data?.map((c) =>
            c.PreviousNewOrders ? c.PreviousNewOrders?.toString()?.length : 0
          )
        ),
      },
      {
        wch: Math.max(
          ...data?.map((c) => (c.sow ? c.sow?.toString()?.length : 0))
        ),
      },
      {
        wch: Math.max(
          ...data?.map((c) =>
            c.CurrentPotential ? c.CurrentPotential?.toString()?.length : 0
          )
        ),
      },
      {
        wch: Math.max(
          ...data?.map((c) =>
            c.CurrentBudget ? c.CurrentBudget?.toString()?.length : 0
          )
        ),
      },
      {
        wch: Math.max(
          ...data?.map((c) =>
            c.CurrentNewOrders ? c.CurrentNewOrders?.toString()?.length : 0
          )
        ),
      },
      {
        wch: Math.max(
          ...data?.map((c) =>
            c.Acheivement ? c.Acheivement?.toString()?.length : 0
          )
        ),
      },
    ];
  }

  useEffect(() => {
    getYearDetails();
  }, []);

  // useEffect(() => {
  //   if (filterData?.yearId > 0) {
  //     switch (userdetails.roleId) {
  //       case 6:
  //         let engineer = [
  //           { id: userdetails?.sdistrictId, name: userdetails?.sdistrict },
  //         ];
  //         setFilterData({
  //           ...filterData,
  //           engineer: engineer,
  //           customer: [],
  //           gck: [],
  //         });
  //         break;
  //       case 5:
  //         let office = [
  //           { id: userdetails?.sbranchId, name: userdetails?.sbranch },
  //         ];
  //         setFilterData({
  //           ...filterData,
  //           office: office,
  //           customer: [],
  //           gck: [],
  //           engineer: [],
  //           teritory: [],
  //         });
  //         break;
  //       default:
  //         setFilterData({
  //           ...filterData,
  //           customer: [],
  //           gck: [],
  //           engineer: [],
  //           office: [],
  //           teritory: [],
  //         });
  //         break;
  //     }
  //   }
  // }, [userdetails, filterData?.yearId]);

  useEffect(() => {
      setIsLoader(true);
      localStorage.setItem("ApplicationId", 0);
      //localStorage.setItem("cus", 0);
    if (filterData.yearId > 0) {
      getSalesOrderData(filterData);
    }
  }, [filterData]);
    console.log(filterData);
    let cus = filterData.customer;
   
  return (
    <ThemeProvider theme={lightTheme}>
      <div className="order-list-paperSty">
        <h3 className="text-center m-0 page-header">Sales Planning List</h3>
        <div className="filter-grid">
          <Grid container item xs={12} className="d-flex jc-space-bt al-center">
            <FilterList
              filterData={filterData}
              setFilterData={setFilterData}
              handleClickFilterResult={handleClickFilterResult}
            />
          </Grid>
        </div>
        <div className="order-list-div">
          <Grid container spacing={2} className="">
            <Grid item xs={12} className="d-flex fd-col pd-1">
              {/*<h4 className="d-flex jc-center">List View</h4>*/}
              <MaterialTable
                title="List View"
                tableRef={tableRef}
                columns={columns}
                isLoading={isLoader}
                data={tableData}
                icons={tableIcons}
                actions={[
                  {
                    icon: icon,
                    tooltip: "Export to Excel",
                    onClick: () => ExportToExcel(),
                    isFreeAction: true,
                  },
                              ]}
                              options={{
                                  draggable: false,
                                  sorting: true,
                                  showTitle: false,
                                  searchFieldAlignment: "left",
                                  toolbarButtonAlignment: "left",
                                  //paginationPosition: "top",
                                  //pageSize: dataLength > 5 ? 5 : dataLength,
                                  //pageSizeOptions: [5, 10, 20, 30],
                                  maxBodyHeight: "400px",
                                  padding: "dense",
                              }}
                components={{
                  Header: (props) => (
                    <>
                      {getCustomTHead()}
                      <MTableHeader {...props} />
                      {getCustomTBodyForTotal(totals)}
                    </>
                  ),
                  Body: (props) => (
                    <>
                      <MTableBody {...props} />
                    </>
                  ),
                  // Pagination: (props) => (
                  //   <Grid className="d-flex jc-flex-end">
                  //     <Grid item xs={3}>
                  //       <MTablePagination {...props} />
                  //     </Grid>
                  //     <Grid item xs={1}>
                  //       <IconButton
                  //         title="Load More"
                  //         onClick={() => alert("Load More")}
                  //       >
                  //         <MoreHoriz />
                  //       </IconButton>
                  //     </Grid>
                  //   </Grid>
                  // ),
                }}
              />
            </Grid>
                  </Grid>
                 
                  <>
                      <Dialog
                          open={openDialog}
                          onClose={handleCloseDialog}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                      >
                          <DialogTitle id="alert-dialog-title">{"ERROR:"}</DialogTitle>
                          <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                  "SAP-Roll Customer" is not added yet.
                              </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                              <Button onClick={add} color="primary">
                                  ADD Customer
                              </Button>
                              <Button onClick={handleCloseDialog} color="primary" autoFocus>
                                  CLOSE
                              </Button>
                          </DialogActions>
                      </Dialog>
                      <Dialog
                          open={openDialogGck}
                          onClose={handleCloseDialogGck}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                      >
                          <DialogTitle id="alert-dialog-title">{"ERROR:"}</DialogTitle>
                          <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                  "SAP-Roll-Gck" is not added yet.
                              </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                              <Button onClick={addgck} color="primary">
                                  ADD GCK
                              </Button>
                              <Button onClick={handleCloseDialogGck} color="primary" autoFocus>
                                  CLOSE
                              </Button>
                          </DialogActions>
                      </Dialog>
                      <Dialog
                          open={openDialogGck}
                          onClose={handleCloseDialogGck}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                      >
                          <DialogTitle id="alert-dialog-title">{"ERROR:"}</DialogTitle>
                          <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                  "SAP-Roll-Gck" is not added yet.
                              </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                              <Button onClick={addgck} color="primary">
                                  ADD GCK
                              </Button>
                              <Button onClick={handleCloseDialogGck} color="primary" autoFocus>
                                  CLOSE
                              </Button>
                          </DialogActions>
                      </Dialog>
                      <Dialog
                          open={openDialogGckBox}
                          onClose={handleCloseDialogGckBox}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                      >
                          <DialogTitle id="alert-dialog-title">{"ERROR:"}</DialogTitle>
                          <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                  "SAP-Roll-Gck" is already added.
                              </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                              
                              <Button onClick={handleCloseDialogGckBox} color="primary" autoFocus>
                                  CLOSE
                              </Button>
                          </DialogActions>
                      </Dialog>
                      <Dialog
                          open={openBox && c=="1"}
                          onClose={handleCloseDialogBox}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                      >
                          <DialogTitle id="alert-dialog-title">{"ERROR:"}</DialogTitle>
                          <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                  "Customer Name is not there in the database"
                              </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                              
                              <Button onClick={handleCloseDialogBox} color="primary" autoFocus>
                                  CLOSE
                              </Button>
                          </DialogActions>
                      </Dialog>
                      <Dialog
                          open={openBoxGck && c == "1"}
                          onClose={handleCloseDialogBoxGck}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                      >
                          <DialogTitle id="alert-dialog-title">{"ERROR:"}</DialogTitle>
                          <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                  "Gck is not there in the database"
                              </DialogContentText>
                          </DialogContent>
                          <DialogActions>

                              <Button onClick={handleCloseDialogBoxGck} color="primary" autoFocus>
                                  CLOSE
                              </Button>
                          </DialogActions>
                      </Dialog>
                      <Dialog
                          open={openBoxCustomer}
                          onClose={handleCloseDialogBoxCustomer}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                      >
                          <DialogTitle id="alert-dialog-title">{"ERROR:"}</DialogTitle>
                          <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                  "Customer Name is already added"
                              </DialogContentText>
                          </DialogContent>
                          <DialogActions>

                              <Button onClick={handleCloseDialogBoxCustomer} color="primary" autoFocus>
                                  CLOSE
                              </Button>
                          </DialogActions>
                      </Dialog>
            <SnackbarCustom
              open={open}
              message={snackMessage}
              alertType={alertType}
              handleClose={handleClose}
            />
          </>
        </div>
          </div>
        
    </ThemeProvider>
  );
}
