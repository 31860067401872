import React, { useState, useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import * as XLSX from "xlsx";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import MaterialTable from "material-table";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {
  GetAddButton,
  GetEditButton,
  tableIcons,
} from "../../../../Utilities/Utility";
import { tableOptionsUser } from "../../../../Themes/LightTheme";
import { useSelector } from "react-redux";
import SnackbarCustom from "../../../../Components/Snackbar/Snackbar";
import {
  getSalesEngineerData,
  addSalesEngineerData,
  updateSalesEngineerData,
  deleteSalesEngineerData,
} from "../../../../Redux/APIs/api_MasterData";
import SalesDistrictForm from "../MasterDataForms/SalesTeritoryForms/SalesDistrictForm";
export default function SEngineerMaster(props) {
  const tableRef = useRef();
  const icon = () => {
    return (
      <GetAppRoundedIcon
        style={{ fontSize: 53, color: `var(--btn-primary-bg-color)` }}
      />
    );
  };
  const columns = [
    { title: "Sales DistrictId", field: "salesDistrictId", hidden: true },
    { title: "Sales Engineer", field: "salesDistrict" },
    {
      title: "Sales Department",
      field: "sdepartmentId",
      render: (rowData) => (
        <span>{props.renderSDepartmentValue(rowData.sdepartmentId)}</span>
      ),
      customFilterAndSearch: (term, rowData) =>
      props.renderSDepartmentValue(rowData.sdepartmentId)
        .toLowerCase()
        .includes(term.toLowerCase()),
      
    },
    {
      title: "Sales Office",
      field: "sofficeId",
      render: (rowData) => (
        <span>{props.renderSOfficeValue(rowData.sofficeId)}</span>
      ),
      customFilterAndSearch: (term, rowData) =>
      props.renderSOfficeValue(rowData.sofficeId)
        .toLowerCase()
        .includes(term.toLowerCase()),
    },
    {
      title: "Sales Branch",
      field: "sbranchId",
      render: (rowData) => (
        <span>{props.renderSBranchValue(rowData.sbranchId)}</span>
      ),
      customFilterAndSearch: (term, rowData) =>
      props.renderSBranchValue(rowData.sbranchId)
        .toLowerCase()
        .includes(term.toLowerCase()),
    },
    {
      title: "Sales Teritory",
      field: "steritoryId",
      render: (rowData) => (
        <span>{props.renderSTeritoryValue(rowData.steritoryId)}</span>
      ),
      customFilterAndSearch: (term, rowData) =>
      props.renderSTeritoryValue(rowData.steritoryId)
        .toLowerCase()
        .includes(term.toLowerCase()),
    },
  ];
  const actions = [
    {
      icon: GetAddButton,
      onClick: () => handleClickOpenDialog(),
      isFreeAction: true,
    },
    {
      icon: icon,
      tooltip: "Export to Excel",
      onClick: () => ExportToExcel(),
      isFreeAction: true,
    },
    (rowData) => ({
      icon: GetEditButton,
      onClick: (event, rowData) => handleClickOpenDialog(rowData),
      isFreeAction: true,
    }),
  ];
  const token = useSelector((state) => state.saveTokenReducer.token);
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
  const [tableData, setTableData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [rowData, setRowData] = useState("");
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpen(true);
  };
  const handleClose = (e) => {
    setOpen(false);
    setOpenDialog(false);
  };

  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpenDialog = (data) => {
    setRowData(data);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSubmit = () => {
    handleCloseDialog(true);
  };
  function getTableData() {
    setIsLoader(true);
      getSalesEngineerData(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId)
      .then((response) => {
        setTableData(response);
        setIsLoader(false);
      })
      .catch((error) => {
        handleSnackOpen("Error : " + error, "error");
        setIsLoader(false);
      });
  }

  useEffect(() => {
    getTableData();
  }, [userDetails]);

  const handleUpdateData = (data) => {
    setIsLoader(true);
      updateSalesEngineerData(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId, data)
      .then((response) => {
        if (response.response) {
          handleSnackOpen(response.responseMsg, "success");
          setIsLoader(false);
          getTableData();
        } else {
          handleSnackOpen(response.responseMsg, "error");
          setIsLoader(false);
        }
      })
      .catch((error) => {
        handleSnackOpen("Exception : " + error, "error");
        setIsLoader(false);
      });
  };

  function handleAddData(data) {
    setIsLoader(true);
      addSalesEngineerData(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId, data)
      .then((response) => {
        if (response.response) {
          handleSnackOpen(response.responseMsg, "success");
          setIsLoader(false);
          getTableData();
        } else {
          handleSnackOpen(response.responseMsg, "error");
          setIsLoader(false);
        }
      })
      .catch((error) => {
        handleSnackOpen("Exception : " + error, "error");
        setIsLoader(false);
      });
  }
  const handleRowDelete = (oldTableData, resolve, reject) => {
    setIsLoader(true);
    oldTableData.loggedinUserId = userDetails.userId;
      deleteSalesEngineerData(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId, oldTableData)
      .then((response) => {
        if (response.response) {
          handleSnackOpen(response.responseMsg, "success");
          resolve();
          setIsLoader(false);
          getTableData();
        } else {
          handleSnackOpen(response.responseMsg, "error");
          reject();
          setIsLoader(false);
        }
      })
      .catch((error) => {
        handleSnackOpen("Exception : " + error, "error");
        reject();
        setIsLoader(false);
      });
  };
  function ExportToExcel() {
    const dataToDownload = tableRef?.current?.dataManager?.searchedData.map(
      (row) => {
        return {
          salesDistrict: row.salesDistrict,
          salesDepartment: row.salesdepartment,
          salesOffice: row.salesoffice,
          salesBranch: row.salesbranch,
          salesTeritory: row.salesteritory,
        };
      }
    );
    let headers = [
      "Sales Engineer",
      "Sales Department",
      "Sales Office",
      "Sales Branch",
      "Sales Teritory",
    ];
    //const workSheet = XLSX.utils.json_to_sheet(newData);
    let ws = XLSX.utils.book_new();
    let wb = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, [headers]);
    XLSX.utils.sheet_add_json(ws, dataToDownload, {
      origin: "A2",
      skipHeader: true,
      cellStyles: true,
    });
    const dataForCellAdjustments=dataToDownload;
    const headerObj={
        salesDistrict: headers[0],
        salesDepartment: headers[1],
        salesOffice: headers[2],
        salesBranch: headers[3],
        salesTeritory: headers[4],
    }
    dataForCellAdjustments.push(headerObj);
    ws["!cols"] = cellAdjustToContents(dataToDownload);
    XLSX.utils.book_append_sheet(wb, ws, "SalesEngineer");
    XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(wb, "SalesEngineerMaster.xlsx");
  }
  function cellAdjustToContents(data) {
    return [
      {
        wch: Math.max(
          ...data?.map((c) =>
            c.salesDistrict ? c.salesDistrict?.toString()?.length : 0
          )
        ),
      },
      {
        wch: Math.max(
          ...data?.map((c) =>
            c.salesDepartment ? c.salesDepartment?.toString()?.length : 0
          )
        ),
      },
      {
        wch: Math.max(
          ...data?.map((c) =>
            c.salesOffice ? c.salesOffice?.toString()?.length : 0
          )
        ),
      },
      {
        wch: Math.max(
          ...data?.map((c) =>
            c.salesBranch ? c.salesBranch?.toString()?.length : 0
          )
        ),
      },
      {
        wch: Math.max(
          ...data?.map((c) =>
            c.salesTeritory ? c.salesTeritory?.toString()?.length : 0
          )
        ),
      },
    ];
  }

  return (
    <div>
      <div className="App">
        <Grid container spacing={1}>
                  <Grid item xs={12}>
                      {userDetails.roleId !== 10 ?
                          <MaterialTable
                              columns={columns}
                              tableRef={tableRef}
                              data={tableData}
                              isLoading={isLoader}
                              icons={tableIcons}
                              options={tableOptionsUser}
                              editable={{
                                  onRowDelete: (oldTableData) =>
                                      new Promise((resolve, reject) => {
                                          handleRowDelete(oldTableData, resolve, reject);
                                      }),
                              }}
                              actions={actions}
                          /> : <MaterialTable
                              columns={columns}
                              tableRef={tableRef}
                              data={tableData}
                              isLoading={isLoader}
                              icons={tableIcons}
                              options={tableOptionsUser}
                              
                              //actions={actions}
                          />}
            <Dialog
              open={openDialog}
              onClose={handleCloseDialog}
              fullWidth
              maxWidth="md"
              aria-labelledby="form-dialog-title"
            >
              <DialogContent>
                <SalesDistrictForm
                  openDialog={openDialog}
                  handleCloseDialog={handleCloseDialog}
                  rowData={rowData}
                  tableData={tableData}
                  handleSubmit={handleSubmit}
                  handleUpdateData={handleUpdateData}
                  handleAddData={handleAddData}
                  steritoryData={props.steritoryData.find(
                    (x) => x.value === rowData?.steritoryId
                  )}
                  sbranchData={props.sbranchData.find(
                    (x) => x.value === rowData?.sbranchId
                  )}
                  sofficeData={props.sofficeData.find(
                    (x) => x.value === rowData?.sofficeId
                  )}
                  sdepartmentData={props.sdepartmentData.find(
                    (x) => x.value === rowData?.sdepartmentId
                  )}
                />
              </DialogContent>
            </Dialog>
            <>
              <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleClose}
              />
            </>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
