import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DrawerMenuIcon from "../../assets/icons/Menu.svg";
import SiemensLogoWhite from "../../assets/images/siemens-logo-white.svg";
import HomeIcon from "@material-ui/icons/Home";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import FlightTakeoffOutlinedIcon from "@material-ui/icons/FlightTakeoffOutlined";
import DevicesOutlinedIcon from "@material-ui/icons/DevicesOutlined";
import BusinessCenterOutlinedIcon from "@material-ui/icons/BusinessCenterOutlined";
// import ServicesGroup from "../../../assets/icons/groups.svg";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import BorderColorOutlinedIcon from "@material-ui/icons/BorderColorOutlined";
import TableChartIcon from '@material-ui/icons/TableChart';
import PowerSettingsNewOutlinedIcon from "@material-ui/icons/PowerSettingsNewOutlined";
import UploadIcon from "@material-ui/icons/CloudUploadRounded";
import StorageIcon from "@material-ui/icons/Storage";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import "./SideDrawer.scss";
//import { useSelector } from "react-redux";
import { sideDrawerItemsGeneric } from "../../Utilities/Utility";
import {
    getAllRoles
} from "../../Redux/APIs/api_Login";
import { isEditableAction, storeUpdatePotentialResponse, isEditAction, storeLandingMenuItemsAction, storeMenuItemsAction } from "../../Redux/Actions/actions";
const useStyles = makeStyles({
  list: {
    width: 300,
  },
  fullList: {
    width: "auto",
  },
});

export default function SideDrawer() {
    const dispatch = useDispatch();
  let userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
    );
    const token = useSelector((state) => state.saveTokenReducer.token);
  const classes = useStyles();
  const [drawerState, setDrawerState] = React.useState({
    left: false,
  });
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const landingMenuItems = useSelector(state => state.storeLandingMenuItemsReducer.menuItems) || [];
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };

  function logoutUser() {
    var newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname;
    window.history.pushState({ path: newurl }, "", `/`);
    localStorage.clear();
    sessionStorage.clear();
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    // if (auth !== undefined) {
    //   //auth.removeUser();
    //   // // dispatch(saveAccessTokenAction(''));
    //   dispatch(storeUserDetailsAction(""));

    // }
    window.location.href = "/";
    }
    function homeClick() {
        window.location.href = "/";
    }

  // const sideDrawerItems = [
  //   { icon: <HomeIcon />, title: "Home", link: "/" },
  //   {
  //     icon: <ListAltOutlinedIcon />,
  //     title: "Sales Planning List",
  //     link: "orderList",
  //   },
  //   {
  //     icon: <UploadIcon />,
  //     title: "Uploads",
  //     link: "uploads",
  //   },
  //   {
  //     icon: <StorageIcon />,
  //     title: "Master Data",
  //     link: "masterData",
  //   },
  //   {
  //     icon: <GroupAddIcon />,
  //     title: "User Management",
  //     link: "userManagement",
  //   },
  //   {
  //     icon: <PowerSettingsNewOutlinedIcon />,
  //     title: "Logout",
  //     link: "logout",
  //   },
  //   // { icon: <TrendingUpIcon />, title: "InputList", link: "" },

  //   // {
  //   //     icon: <img src={ServicesGroup} alt="Production Order Workflow" className="sideDrawerServicesIcon" />, title: "Production Order Workflow", link: "poworkflow"
  //   // },
  //   // { icon: <FlightTakeoffOutlinedIcon />, title: "Acknowledge Data", link: "a" },
  //   // { icon: <DevicesOutlinedIcon />, title: "Reprint QR", link: "" },
  //   // { icon: <BusinessCenterOutlinedIcon />, title: "Admin", link: "" },
  // ];
  //let [sideDrawerItems, setSideDrawerItems] = useState([]);
  //useEffect(() => {
  //  sideDrawerItemsGeneric.forEach((c) => {
  //    if (c?.roleId?.includes(userDetails?.roleId)) {
  //      sideDrawerItems.push(c);
  //    }
  //    return c;
  //  });
  //  setSideDrawerItems(sideDrawerItems);
  //}, [userDetails]);
    const icons = {
        "<ListAltOutlinedIcon />": <ListAltOutlinedIcon />,

        "<HomeIcon />": <HomeIcon />,

        "<StorageIcon />": <StorageIcon />,
        "<TableChartIcon />": <TableChartIcon />,
        "<GroupAddIcon />": <GroupAddIcon />,
        "<UploadIcon />": <UploadIcon />,
        //"<ListAltOutlinedIcon />": <ListItem.ListAltOutlinedIcon />,
        //"<ListAltOutlinedIcon />": <ListItem.ListAltOutlinedIcon />
    };
    function getMenus() {
        //console.log("menu", MasterRouteLanding)
        //let filtered = MasterRouteLanding.filter((c) => c.roleId === 1);
        //setRoutes(filtered);
        getAllRoles(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId
        )
            //.then((response) => {
            //    if (response) {
            //        console.log('land', response)
            //        setRoutes(response);
            //    } else {
            //        setRoutes([]);
            //    }
            //})
            .then((response) => {
                if (response) {
                    console.log(response)
                    // var resp = response.slice(1, response.length - 1);
                    // setRoutes(resp);
                    dispatch(storeLandingMenuItemsAction(response));
                    dispatch(storeMenuItemsAction(response));
                } else {
                    // setRoutes([]);
                    dispatch(storeMenuItemsAction([]));
                    dispatch(storeLandingMenuItemsAction(response));
                }
            })
            .catch((error) => {
                console.log("Error: " + error, "error");
            });
    }


    useEffect(() => {
        //if (userDetails && userDetails.userId > 0 && siteToken.token !== "") {

        //    getMenus();
        //}
        // dispatch(isForgotPasswordAction(false));
        if (userDetails?.userId > 0 && token !== "" && landingMenuItems?.length <= 0) {
            getMenus();
        }
    }, [userDetails]);
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Link to="/" className="d-flex jc-center side-drawer-logo">
        <img src={SiemensLogoWhite} alt="Siemens" className="official-logo" />
          </Link>
          <Link style={{ marginTop: '-10px' }} >
              <ListItem button onClick={homeClick} >
                  <ListItemIcon className="side-drawer-icons">
                      < HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary="Home" />
              </ListItem>
          </Link>
          <List className="side-drawer-menu-list" style={{ marginTop: '-10px' }}>
              {landingMenuItems.map((item, index) =>
           (
            <Link to={item.menuLink} key={index}>
              <ListItem button>
                <ListItemIcon className="side-drawer-icons">
                    {icons[item.menuIcon]}
                </ListItemIcon>
                <ListItemText primary={item.menuName} />
              </ListItem>
            </Link>
          )
        )}
      </List>
      <div className="sideDrawer-footer-block">
        <p>© 2021 Siemens Intranet</p>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <img
            src={DrawerMenuIcon}
            alt="Besys Menu"
            className="besys-menu-icon"
            onClick={toggleDrawer(anchor, true)}
          />
          <SwipeableDrawer
            anchor={anchor}
            open={drawerState[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}
