
import React,{useState} from "react";
import {
    Grid,
    ThemeProvider,
} from "@material-ui/core";
import { lightTheme } from "../../../Themes/LightTheme";
import GckMaster from "./MasterStructure/GckMaster";
import CustomerMaster from "./MasterStructure/CustomerMaster";
import BusinessUnitMaster from "./MasterStructure/BusinessUnitMaster";
import BusinessSegmentMaster from "./MasterStructure/BusinessSegmentMaster";
import BusinessSubSegmentMaster from "./MasterStructure/BusinessSubSegmentMaster";

export default function CustomerGckSegments() {

    const [gckSegments,setGckSegments]=useState({
        gck:0,
       bunit:0,
       bsegment:0,
       bsubsegment:0
    })
   
    return (
        <div>
            <ThemeProvider theme={lightTheme}>

                <>
                    <div className="master-paperSty">
                        <div className="master-data">
                            <Grid container spacing={2} className="master-div">
                                <Grid item xs={12} md={12} className="d-flex fd-col pd-1">
                                    <h4 className="d-flex jc-center">Gck </h4>
                                    <GckMaster 
                                    gckSegments={gckSegments}
                                    setGckSegments={setGckSegments}
                                  
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} className="d-flex fd-col pd-1">
                                    <h4 className="d-flex jc-center">Customer</h4>
                                    <CustomerMaster />
                                </Grid>
                                <Grid item xs={12} md={6} className="d-flex fd-col pd-1">
                                    <h4 className="d-flex jc-center">Business Unit</h4>
                                    <BusinessUnitMaster
                                    gckSegments={gckSegments}
                                    setGckSegments={setGckSegments}
                                  
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} className="d-flex fd-col pd-1">
                                    <h4 className="d-flex jc-center">Business Segment </h4>
                                    <BusinessSegmentMaster 
                                    gckSegments={gckSegments}
                                    setGckSegments={setGckSegments}
                        
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} className="d-flex fd-col pd-1">
                                    <h4 className="d-flex jc-center">Business SubSegment</h4>
                                    <BusinessSubSegmentMaster 
                                    gckSegments={gckSegments}
                                    setGckSegments={setGckSegments}
                                
                                    />
                                </Grid>

                            </Grid>
                        </div>
                    </div>
                </>

            </ThemeProvider>
        </div>
    );
}