import React, { useState, useEffect,useRef } from "react";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import { tableIcons } from "../../../../Utilities/Utility";
import { tableOptionsUser } from "../../../../Themes/LightTheme";
import { useSelector } from "react-redux";
import SnackbarCustom from "../../../../Components/Snackbar/Snackbar";
import * as XLSX from 'xlsx';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import { getSalesBranchData, addSalesBranchData, updateSalesBranchData, deleteSalesBranchData } from "../../../../Redux/APIs/api_MasterData";

export default function SBranchMaster() {
    const tableRef = useRef();
    const icon=()=>{
        return(
        <GetAppRoundedIcon  style={{ fontSize: 53,color:`var(--btn-primary-bg-color)` }}/>
        );
    }
    const columns = [
        { title: 'Sales BranchId', field: 'salesBranchId', hidden: true },
        { title: 'Sales Branch', 
        field: 'salesBranch' ,
        validate: (rowData) =>
        validateSbranch(rowData.salesBranch, rowData.salesBranchId),
    },
    ]
  const actions=[
    {
        icon: icon,
        tooltip:"Export to Excel",
        onClick:()=>ExportToExcel(),
        isFreeAction:true
     },
    ];
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const [tableData, setTableData] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpen(false);
    };
    function isSbranchExists(value) {
        const obj = tableData.find((c) => c.salesBranch == value);
        return obj ? true : false;
      }
      function isSbranchExists(value, id) {
        const obj = tableData.find((c) => c.salesBranch == value);
        return obj && obj.salesBranchId !== id ? true : false;
      }
      function validateSbranch(value, id) {
        return !value
          ? { isValid: false, helperText: "*Required" }
          : (!id && isSbranchExists(value)) || (id && isSbranchExists(value, id))
          ? { isValid: false, helperText: "Already exists" }
          : { isValid: true, helperText: "" };
      }
    function getTableData() {
        setIsLoader(true);
        getSalesBranchData(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {
                setTableData(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    useEffect(() => {
        getTableData();
    }, [userDetails])


    const handleRowUpdate = (newTableData, oldTableData, resolve, reject) => {
        setIsLoader(true);
        newTableData.loggedinUserId = userDetails.userId;
        updateSalesBranchData(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, newTableData)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableData();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                reject();
                setIsLoader(false);
            });
    };
    const handleRowAdd = (newTableData, resolve, reject) => {
        setIsLoader(true);
        newTableData.loggedinUserId = userDetails.userId;
        addSalesBranchData(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, newTableData)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableData();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                reject();
                setIsLoader(false);
            });
    };
    const handleRowDelete = (oldTableData, resolve, reject) => {
        setIsLoader(true);
        oldTableData.loggedinUserId = userDetails.userId;
        deleteSalesBranchData(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, oldTableData)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableData();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                reject();
                setIsLoader(false);
            });
    };
    function ExportToExcel() {
        const dataToDownload = tableRef?.current?.dataManager?.searchedData.map(
          (row) => {
            return {
              salesBranch:row.salesBranch
            };
          }
        );
        let headers = [
          "Sales Branch",    
        ];
        //const workSheet = XLSX.utils.json_to_sheet(newData);
        let ws = XLSX.utils.book_new();
        let wb = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(ws, [headers]);
        XLSX.utils.sheet_add_json(ws, dataToDownload, {
          origin: "A2",
          skipHeader: true,
          cellStyles: true,
        });
        const dataForCellAdjustments = dataToDownload;
        const headerObj={
            salesBranch:headers[0]
        }
        dataForCellAdjustments.push(headerObj);
        ws["!cols"] = cellAdjustToContents(dataToDownload);
        XLSX.utils.book_append_sheet(wb, ws, "Sales Branch");
        XLSX.write(wb, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(wb, "SalesBranchMaster.xlsx");
      }
      function cellAdjustToContents(data) {
        return [
          {
            wch: Math.max(
              ...data?.map((c) =>
                c.salesBranch ? c.salesBranch?.toString()?.length : 0
              )
            ),
          },
         
        ];
      }
      function getDisableTooltip(value,type) {
        return value === true
          ? "Sales Branch is already Associated with salesOrders"
          : type === "Edit"
          ? "Edit"
          : "Delete";
      }
    return (
        <div>
            <div className="App">
                <Grid container spacing={1}>

                    <Grid item xs={12}>
                        {userDetails.roleId !== 10 ?
                            <MaterialTable
                                title=""
                                tableRef={tableRef}
                                columns={columns}
                                data={tableData}
                                isLoading={isLoader}
                                icons={tableIcons}
                                options={tableOptionsUser}
                                actions={actions}
                                editable={{
                                    isEditable: (rowdata) => !rowdata.isreadonlySbranch,
                                    editTooltip: (rowdata) => getDisableTooltip(rowdata.isreadonlySbranch, "Edit"),
                                    isDeletable: (rowdata) => !rowdata.isreadonlySbranch,
                                    deleteTooltip: (rowdata) => getDisableTooltip(rowdata.isreadonlySbranch, "Delete"),
                                    onRowUpdate: (newTableData, oldTableData) =>
                                        new Promise((resolve, reject) => {
                                            handleRowUpdate(
                                                newTableData,
                                                oldTableData,
                                                resolve,
                                                reject
                                            );
                                        }),
                                    onRowAdd: (newTableData) =>
                                        new Promise((resolve, reject) => {
                                            handleRowAdd(newTableData, resolve, reject);
                                        }),
                                    onRowDelete: (oldTableData) =>
                                        new Promise((resolve, reject) => {
                                            handleRowDelete(oldTableData, resolve, reject);
                                        }),
                                }}
                            /> : <MaterialTable
                                title=""
                                tableRef={tableRef}
                                columns={columns}
                                data={tableData}
                                isLoading={isLoader}
                                icons={tableIcons}
                                options={tableOptionsUser}
                                //actions={actions}
                                
                            />}
                        <>
                            <SnackbarCustom
                                open={open}
                                message={snackMessage}
                                alertType={alertType}
                                handleClose={handleClose}
                            />
                        </>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}