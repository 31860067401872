import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
//import { Link,useNavigate } from 'react-router-dom';
import OrderInput from "../InputRoot";

import {
  getRoundUptoXDecimals,
  tableIconsInputScreen,
} from "../../../Utilities/Utility";
import {
  getSalesOrderInputData,
  updatePotential,
} from "../../../Redux/APIs/api_OrderInputDetails";
import { isEditableAction, storeUpdatePotentialResponse,  } from "../../../Redux/Actions/actions";
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";
//import BudgetGrid from "./BudgetGrid";
import {
    saveLock, getLock
} from "../../../Redux/APIs/api_Common";


export default function PotentialGrid(props) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.saveTokenReducer.token);
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const accessable = useSelector((state) => state.isEditReducer.editables);
  let [data, setData] = useState([]);
  let [FYs, setFYs] = useState([]);
  let [isLoader, setIsLoader] = useState(true);
    const id = JSON.parse(localStorage.getItem("toggle"));
  //for Snack Alert
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpen(true);
    };
    //const navigate = useNavigate();
  const handleClose = (e) => {
    setOpen(false);
  };
  function renderYearValue(value) {
    let obj = {};
    if (FYs && FYs.length > 0) {
      obj = FYs.find((c) => c.id === value);
    }
    if (obj) {
      return obj.name;
    } else {
      return "";
    }
  }
  var columns = [
    {
      title: "SalesOrderId",
      field: "salesOrderDetailsId",
      hidden: true,
    },
    {
      title: "Year",
      field: "yearId",
      render: (rowData) => <>{renderYearValue(rowData.yearId)}</>,
      editable: "never",
    },
    {
      title: "Direct",
      field: "potentialDirect",
      type: "numeric",
    },
    {
      title: "Indirect",
      field: "potentialIndirect",
      type: "numeric",
    },
    {
      title: "Total",
      field: "total",
      render: (rowData) => (
        <>
          {getRoundUptoXDecimals(
            rowData?.potentialDirect + rowData?.potentialIndirect,
            3
          )}
        </>
      ),
      editable: "never",
    },
  ];
  const handleRowUpdate = (newData, oldData, resolve, reject) => {
      setIsLoader(true);
      console.log(oldData);
      if (oldData.potentialDirect > 0) {
          dispatch(isEditableAction("true"));
      }
      else {
          dispatch(isEditableAction("false"));
      }
      newData.loggedInUserId = userDetails.userId;
      
      if (newData.potentialDirect >0) {
          dispatch(isEditableAction("true"));
      }
      else {
          dispatch(isEditableAction("false"));
      }
      updatePotential(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId, newData)
        .then((response) => {
          console.log("po",response)
          if (response.potentialcheck == true) {
            handleSnackOpen(response.responseMsg, "success");
           
          resolve();
              setIsLoader(false);
              dispatch(storeUpdatePotentialResponse(response.response));

              //dispatch(storePotential(response.objResult));
             // localStorage.setItem("potential", 1);
              getDataOnLoad(props.filters);
             // window.location.reload()
              //window.location.replace("/orderInput")
        } else {
            handleSnackOpen(response.responseMsg, "error");
            dispatch(isEditableAction("false"));
          reject();
          setIsLoader(false);
        }
      })
      .catch((error) => {
          handleSnackOpen("Exception : " + error, "error");
          dispatch(isEditableAction("false"));
          reject();

        setIsLoader(false);
      });
  };
  function getFYs(data) {
    setFYs(data);
  }
  function getDataOnLoad(filters) {
    setIsLoader(true);
      getSalesOrderInputData(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId, filters)
      .then((response) => {
        if (response.response) {
          const obj = response.objResult;
          setData(obj.potentialDataList);
          const callBackPotential = obj?.potentialDataList?.map((c) => {
            return {
              yearId: c.yearId,
              total: getRoundUptoXDecimals(
                c?.potentialDirect + c?.potentialIndirect,
                3
              ),
            };
          });
            
            props.setPotentialData([...callBackPotential]);
           // props.setBudgetData([...callBackBudget]);
            //props.setData([obj.budgetDataList])
            
          setIsLoader(false);
        } else {
          setData([]);
          setIsLoader(false);
          handleSnackOpen(response.responseMsg, "info");
        }
      })
      .catch((error) => {
        console.log("Error : ", error);
        handleSnackOpen(
          "Exception occured while fetching potential data",
          "error"
        );
        setIsLoader(false);
      });
  }
  function getDisableTooltip(value, type) {
    return value === true
      ? "This action is restricted"
      : type === "Edit"
      ? "Edit"
      : "Delete";
    }
    function getlocking() {
        setIsLoader(true);
       

        getLock(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId
            )
            .then((response) => {
                if (response.response) {

                    console.log("res", response)
                    handleSnackOpen(response.responseMsg, "success");
                    setIsLoader(false);


                } else {


                    handleSnackOpen(response.responseMsg, "error");
                    setIsLoader(false);
                }
            })
            .catch((error) => {


                handleSnackOpen("Exception : " + error, "error");
                setIsLoader(false);
            });
    }

  useEffect(() => {
    if (props) {
        getFYs(props.fys);
      //getlocking()
    }
  }, [props]);
  useEffect(() => {
    if (props.filters) {
      // console.log("Filters : ", props.filters);
      getDataOnLoad(props.filters);
      }
      //getlocking()
      
      
  }, [props.filters]);

  return (
    <>
          <div className="order-input-table">
              {userDetails.roleId !== 10 && accessable != false|| (userDetails.roleId != 1 && accessable != false) || userDetails.roleId == 1 ?
                  <MaterialTable
                      title="Potential"
                      columns={columns}
                      data={data}
                      icons={tableIconsInputScreen}
                      isLoading={isLoader}
                      options={{
                          pageSize: 2,
                          toolbar: false,
                          paging: false,
                      }}
                      localization={{
                          header: {
                              actions: "Edit",
                          },
                      }}
                      editable={{
                          isEditable: (rowData) => userDetails.roleId !== 10 ?rowData.isPotBudLockedForCurrentFy === false || rowData.isPotBudLockedForPreviousFy === false ? true : false : false,
                          editTooltip: (rowData) =>
                              getDisableTooltip(rowData.isReadOnly, "Edit"),
                          onRowUpdate: (newData, oldData) =>
                              new Promise((resolve, reject) => {
                                  handleRowUpdate(newData, oldData, resolve, reject);
                              }),
                      }}
                  /> : <MaterialTable
                      title="Potential"
                      columns={columns}
                      data={data}
                      icons={tableIconsInputScreen}
                      isLoading={isLoader}
                      options={{
                          pageSize: 2,
                          toolbar: false,
                          paging: false,
                      }}
                      localization={{
                          header: {
                              actions: "Edit",
                          },
                      }}
                     
                  />}
      </div>
      <>
        <SnackbarCustom
          open={open}
          message={snackMessage}
          alertType={alertType}
          handleClose={handleClose}
        />
      </>
    </>
  );
}
