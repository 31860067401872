import { baseApiURL } from "../../Utilities/Utility";
import { saveAccessTokenAction, storeUserDetailsAction ,saveUserIdAction} from "../Actions/actions";
let generatedToken="";

export function authenticateUserWithMyID(dispatch, newToken) {
    var formdataPart1 = new FormData();
    formdataPart1.append("Token", newToken);


    const options = {
        method: "post",
        processData: false,
        mimeType: "multipart/form-data",
        contentType: false,
        body: formdataPart1,
    };
  
    return fetch(baseApiURL + "/Login/AuthenticateUser", options)
      .then((response) => response.json())
      .then((data) => {
          if (typeof data.result.response == "string") {
              var userDataNull = {
                  userData: {
                      gid: "",
                      roleId: 0,
                      roleName: "",
                      userEmail: "",
                      userFirstName: "",
                      userLastName: "",
                      userId: 0,
                  },
              };
              dispatch(storeUserDetailsAction(userDataNull));
              return true;
          } else if (data.result.response.roleId != null) {
              dispatch(saveUserIdAction(data.result.uid));
              dispatch(storeUserDetailsAction(data.result.response));
              dispatch(saveAccessTokenAction(data.result.tokenString));
              window.localStorage.setItem(
                  "response",
                  JSON.stringify(data.result.response)
              );
              return true;
          } else if (data?.result?.response?.roleId == null) {
              var userDataNull = {
                  gid: null,
                  roleId: null,
                  roleName: null,
                  userEmail: null,
                  userFirstName: null,
                  userLastName: null,
                  userId: null,
              };
              dispatch(storeUserDetailsAction(userDataNull));
              return false;
          } else {
              return false;
          }
      })
        .catch((error) => {
            return false;
        });
    // const responseJson = await response.json();
    // dispatch(storeUserDetailsAction(responseJson.result));
  }

  const userName = "sales.planning_tool@siemens";
  const passKey = "Sales_Planning_Tool@SIEMENS";
  export function generateJwtForAPI(dispatch) {
    const systemUser = {
      userName: userName,
      password: passKey,
    };
    const options = {
      method: "post",
      mode: "cors",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
       // "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(systemUser),
    };
  
    return fetch(baseApiURL + "/Login/ValidateRequest", options)
      .then((response) => response.json())
      .then((data) => {
        generatedToken = data.result.objResult;
        dispatch(saveAccessTokenAction(generatedToken));
        return data.result;
      })
      .catch((error) => {
        console.log("Error : ", error);
        return {
          response: false,
          responseMsg: "Exception occured while generating access token to database.",
        };
      }
      );
}

export function getAllRoles(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetLandingMenuData?userId=" + userId,
        options
    )
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}