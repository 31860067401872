import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Container, Grid } from "@material-ui/core";
import Cognisphere from "../../assets/images/Cognisphere.png";
// import HomeIcon from "../../assets/images/HomeImg.png";
import Loader from "../../Components/Loader/Loader";
import "./Home.scss";
import { MasterRouteLanding } from "../../Utilities/Utility";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import { isEditableAction, storeUpdatePotentialResponse, isEditAction, storeLandingMenuItemsAction, storeMenuItemsAction } from "../../Redux/Actions/actions";
import {
    saveLock, getLock
} from "../../Redux/APIs/api_Common";
import {
    getAllRoles
} from "../../Redux/APIs/api_Login";

export default function Home() {
    const dispatch = useDispatch();
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
    const token = useSelector((state) => state.saveTokenReducer.token);
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const landingMenuItems = useSelector(state => state.storeLandingMenuItemsReducer.menuItems) || [];
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
  const [isLoader, setIsLoader] = useState(false);
  const [routes, setRoutes] = useState([]);
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpen(false);
        //setOpenDialog(false);
    };
    
  function getMenus() {
    //let filtered = MasterRouteLanding.filter(
    //  (c) => c.roleId === userDetails.roleId
    //);
    //setRoutes(filtered);
    //setIsLoader(false);
      getAllRoles(
          siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId
      )
          //.then((response) => {
          //    if (response) {
          //        console.log('land', response)
          //        setRoutes(response);
          //    } else {
          //        setRoutes([]);
          //    }
          //})
          .then((response) => {
              if (response) {
                  console.log(response)
                  // var resp = response.slice(1, response.length - 1);
                  // setRoutes(resp);
                  dispatch(storeLandingMenuItemsAction(response));
                  dispatch(storeMenuItemsAction(response));
              } else {
                  // setRoutes([]);
                  dispatch(storeMenuItemsAction([]));
                  dispatch(storeLandingMenuItemsAction(response));
              }
          })
          .catch((error) => {
              console.log("Error: " + error, "error");
          });
    }
    function getlocking() {
        setIsLoader(true);
        getLock(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId
)
            .then((response) => {
                if (response) {
                    // setLock(response.isLocked)
                    if (userDetails.roleId != 10) {
                        dispatch(isEditAction(response[0].isLocked));
                    }
                    else {
                        dispatch(isEditAction(false))
                    }
                    console.log("res", response[0].isLocked)
                    handleSnackOpen(response.responseMsg, "success");
                    setIsLoader(false);


                } else {


                    handleSnackOpen(response.responseMsg, "error");
                    setIsLoader(false);
                }
            })
            .catch((error) => {


                handleSnackOpen("Exception : " + error, "error");
                setIsLoader(false);
            });
    }

  var newurl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname;
  window.history.pushState({ path: newurl }, "", newurl);

  //useEffect(() => {
  //  if (userDetails && userDetails.userId > 0) {
  //    setIsLoader(true);
  //      getMenus();
  //      getlocking()
  //  }
  //}, [userDetails]);
    useEffect(() => {
        if (userDetails?.userId > 0 && token !== "" && landingMenuItems?.length <= 0) {
            getMenus();
        }
    }, [userDetails]);

  return (
    <>
      <img
        src={Cognisphere}
        alt="Cognisphere"
        className="homepage-cognisphere"
          />
          {landingMenuItems.length ? (
        <div className="bg-landing-page">
          <Container maxWidth="lg">
            <Grid container className="homepage-container">
              <Grid item xs={12} sm={12} md={12} lg={5} className="z-9">
                <div className="logged-in-user">
                  <h1 className="logged-in-user-heading">
                    <span className="logged-in-greetings">Hello,</span>
                    {/* <span className="logged-in-user-name">{auth.user?.profile.sub}{" "}</span> */}
                    <span className="logged-in-user-name">
                      {userDetails.userFirstName}
                      {/* {" "}{userDetails.userLastName} */}
                    </span>
                  </h1>
                  <p className="logged-in-project-desc">
                    Welcome to Sales Planning Tool (SPL2.0)
                  </p>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={7}
                className="landing-page-menu"
              >
                              <Grid container spacing={2}>
                                  {landingMenuItems.map((item, index) => (
                    <Grid item xs={12} sm={6} md={6} key={index}>
                      <Link to={item.menuLink} key={index}>
                        <div className="menu-item-container">
                          {/* <div className="menu-item-image-block">
                            <img
                              src={HomeIcon}
                              alt={item.menuName}
                              className="menu-item-image"
                            />
                          </div> */}
                          <div className="menu-item-image">{item.icon}</div>
                          <div className="menu-item-text-block">
                            <span>{item.menuName}</span>
                          </div>
                        </div>
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
      ) : (
        <Loader />
      )}
      {isLoader ? <Loader /> : <></>}
    </>
    );
    <SnackbarCustom
        open={open}
        message={snackMessage}
        alertType={alertType}
        handleClose={handleClose}
    />
}
