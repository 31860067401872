import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    Button,
    makeStyles,
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Switch,
    Typography,
    TextField,
    Input,
    IconButton,
    CircularProgress,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Loading from "../../Components/Loader/Loader";
import InputAdornment from "@material-ui/core/InputAdornment";
import ActualsTemplate from "../../assets/template/Actuals_Upload_Monthly_template_v1.3.xlsx";
import PotBudTemplate from "../../assets/template/Potential&Budget_Upload_template_v1.0.xlsx";
import { getMonthDDL, getYearDDL, getError, getMonthError } from "../../Redux/APIs/api_Common";
import { getCurrentFiscalYear, getCurrentMonth } from "../../Utilities/Utility";

import "./Uploads.scss";
import UploadIcon from "@material-ui/icons/CloudUploadRounded";
import { MonthlyUpload, PotentialBudget } from "../../Redux/APIs/api_Uploads";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import * as XLSX from "xlsx";

export default function SalesOrderPlanningUpload() {
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    function getProgressComponent() {
        return (
            <h3
                style={{
                    fontStyle: "italic",
                    color: "var(--circular-progress-color)",
                    textAlign: "center",
                }}
            >
                <CircularProgress style={{ height: `25px`, width: `25px` }} />
                {" " + switchValue + " data upload in progress...."}
            </h3>
        );
    }
    function getUploadAddornment() {
        return (
            <IconButton
                title={"Upload " + switchValue}
                onClick={handleUploadFile}
                disabled={errorTexts?.month || !selectedFile || errorTexts?.year}
            >
                <UploadIcon fontSize="large" />
            </IconButton>
        );
    }
    function getDownloadAddornment() {
        return (
            <IconButton
                title={"Sample " + switchValue + " Template"}
                onClick={downloadTemplate}
            >
                <CloudDownloadIcon fontSize="large" />
            </IconButton>
        );
    }
    const [errorTexts, setErrorTexts] = useState({
        year: "",
        month: "",
    });
    let [defaultInput, setDefaultInput] = useState({
        year: {},
        yearText: "",
        month: {},
        monthText: "",
    });
    let [input, setInput] = useState({
        yearId: 0,
        monthId: 0,
    });
    const handleAutocompleteChange = (event, obj) => {
        let elem = event.target.id;
        let elemId = !elem
            ? ""
            : elem.includes("-")
                ? elem.substr(0, elem.indexOf("-"))
                : elem;
        switch (elemId) {
            case "year":
                setDefaultInput({
                    ...defaultInput,
                    [elemId]: obj,
                    [elemId + "Text"]: obj?.name,
                });
                setInput({
                    [elemId + "Id"]: obj?.id,
                });
                break;
            case "month":
                setDefaultInput({
                    ...defaultInput,
                    [elemId]: obj,
                    [elemId + "Text"]: obj?.name,
                });
                setInput({
                    ...input,
                    [elemId + "Id"]: obj?.id,
                });
                break;
            default:
                break;
        }
        validateAutocompleteComponents(event, obj?.name);
    };
    const handleAutocompleteTextChange = (event, text) => {
        let elem = event.target.id;
        let elemId = !elem
            ? ""
            : elem.includes("-")
                ? elem.substr(0, elem.indexOf("-"))
                : elem;
        switch (elemId) {
            case "year":
                setDefaultInput({
                    ...defaultInput,
                    [elemId + "Text"]: text,
                });
                let yearId = yearData.find((x) => x.name === text)?.id;
                setInput({
                    ...input,
                    [elemId + "Id"]: yearId ? yearId : 0,
                });
                break;
            default:
                break;
        }
        validateAutocompleteComponents(event, text);
    };

    const validateAutocompleteComponents = (e, value) => {
        let elem = e.target.id;
        let elemId = !elem
            ? ""
            : elem.includes("-")
                ? elem.substr(0, elem.indexOf("-"))
                : elem;
        switch (elemId) {
            case "year":
                setErrorTexts({
                    ...errorTexts,
                    [elemId]: !value ? "Mandatory" : "",
                });
                break;
            case "month":
                setErrorTexts({
                    ...errorTexts,
                    [elemId]: !value ? "Mandatory" : "",
                });
                break;
            default:
                break;
        }
    };
    //Year Api

    let [yearData, setYearData] = useState([]);
    let [yearId, setYearId] = useState(0);

    async function getYearDetails() {
        let year = getCurrentFiscalYear(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId);
        const response = await getYearDDL(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId);
        if (response && response.length > 0) {
            setYearData(response.map((item) => ({ id: item.id, name: item.name })));
            let obj = response?.find((x) => x.name == year);
            setYearId(obj.id);
            setDefaultInput({
                year: obj,
                yearText: obj?.name,
            });
        } else {
            setYearData([]);
        }
    }
    let [monthData, setMonthData] = useState([]);

    async function getMonthDetails() {
        const response = await getMonthDDL(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId);
        if (response && response.length > 0) {
            setMonthData(response.map((item) => ({ id: item.id, name: item.name })));
        } else {
            setMonthData([]);
        }
    }
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogContent, setDialogContent] = React.useState("");
    const [isLoader, setIsLoader] = useState(false);
    const handleDialogMessage = (text) => {
        setDialogContent(text);
        setDialogOpen(true);
    };
    const handleClose = (e, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        if (
            typeof dialogContent === "string" &&
            dialogContent.toLowerCase().includes("success")
        ) {
            setInputFileValue(Date.now);
            setSelectedFile(null);
        }
        setDialogOpen(false);
        setInputFileValue(Date.now);
        setErrorData([]);
        setErrorList([])
        setMonthError([]);
        setMsgMonth("")
        setMsgPotential("")
    };
    let [errorData, setErrorData] = useState([]);
    let [errorList, setErrorList] = useState([]);
    let [monthError, setMonthError] = useState([]);
    let [msgPotential, setMsgPotential] = useState("")
    let [msgMonth, setMsgMonth] = useState("")
    function error() {
        setIsLoader(true);
        getError(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {
                console.log(response);

                //setErrorData(response.objResult);
                setErrorList(response.objResult);
                //setMsgPotential(response.responseMsg)
                // handleDialogMessage(response.responseMsg);
                setIsLoader(false);
            })
            .catch((error) => {
                //handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    function errorMonth() {
        setIsLoader(true);
        getMonthError(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {
                console.log(response);

                //setErrorData(response.objResult);
                setMonthError(response.objResult);
                //setMsgMonth(response.responseMsg)
                // handleDialogMessage(response.responseMsg);
                setIsLoader(false);
            })
            .catch((error) => {
                //handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    const exportErrorData = () => {





        switch (switchValue) {
            case "Potential & Budget":

                ExportPotBudErrorDataToExcel();
                break;
            case "Monthly Actuals":
                ExportMonthlyActualsErrorDataToExcel();
                break;
            default:
                return;
        }
    };
    function ExportPotBudErrorDataToExcel() {

        const dataToDownload = errorList.map((row) => {
            return {
                missingDetails: row.missingDetails,

                remarks: row.remarks?.replace(".", ".\n "),
            };
        });
        let headers = [
            "Missing Details",
            "Remarks",
        ];
        //const workSheet = XLSX.utils.json_to_sheet(newData);
        let ws = XLSX.utils.book_new();
        let wb = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(ws, [headers]);
        XLSX.utils.sheet_add_json(ws, dataToDownload, {
            origin: "A2",
            skipHeader: true,
            cellStyles: true,
        });
        ws["!cols"] = cellAdjustToContentsPotBud(dataToDownload);
        ws['!cols'] = [{ width: 20 }, { width: 20 }];
        XLSX.utils.book_append_sheet(wb, ws, "ErrorData");
        XLSX.write(wb, { bookType: "xlsx", type: "binary" });
        //Download
        XLSX.writeFile(wb, "PotentialBudgetUploadErrors.xlsx");
    }
    function cellAdjustToContentsPotBud(data) {
        return [

            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.missingDetails ? c.missingDetails?.toString()?.length : 0
                    )
                ),
            },

            {
                wch: Math.max(
                    ...data?.map((c) => (c.remarks ? c.remarks?.toString()?.length : 0))
                ),
            },
        ];
    }
    function ExportMonthlyActualsErrorDataToExcel() {
        const dataToDownload = monthError.map((row) => {

            return {
                missingDetails: row.missingDetails,

                remarks: row.remarks?.replace(".", ".\n "),
            };
        });
        let headers = [
            "Missing Details",
            "Remarks",
        ];
        //const workSheet = XLSX.utils.json_to_sheet(newData);
        let ws = XLSX.utils.book_new();
        let wb = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(ws, [headers]);
        XLSX.utils.sheet_add_json(ws, dataToDownload, {
            origin: "A2",
            skipHeader: true,
            cellStyles: true,
        });
        ws["!cols"] = cellAdjustToContentsPotBud(dataToDownload);
        ws['!cols'] = [{ width: 40 }, { width: 20 }];
        XLSX.utils.book_append_sheet(wb, ws, "ErrorData");
        XLSX.write(wb, { bookType: "xlsx", type: "binary" });
        //Download

        XLSX.writeFile(wb, "MonthlyActualsUploadErrors.xlsx");
    }
    function cellAdjustToContentsMonthlyActuals(data) {
        return [
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.missingDetails ? c.missingDetails?.toString()?.length : 0
                    )
                ),
            },

            {
                wch: Math.max(
                    ...data?.map((c) => (c.remarks ? c.remarks?.toString()?.length : 0))
                ),
            },
        ];
    }
    const [selectedFile, setSelectedFile] = useState(null);
    let [inputFileValue, setInputFileValue] = useState(Date.now);
    let [switchValue, setSwitchValue] = useState("Potential & Budget");
    const toggleSwitch = (e) => {
        setSwitchValue(
            switchValue === "Potential & Budget"
                ? "Monthly Actuals"
                : "Potential & Budget"
        );
        if (switchValue === "Potential & Budget") {
            setErrorTexts({
                ...errorTexts,
                month: "Mandatory*",
            });
        } else {
            setErrorTexts({
                ...errorTexts,
                month: "",
            });
            setDefaultInput({
                ...defaultInput,
                month: {},
                monthText: "",
            });
        }
    };
    const updateExcelHandler = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    const handleUploadFile = (e) => {
        setIsLoader(true);
        if (!validateFieldsOnSubmit(e)) {
            e.preventDefault();
            setIsLoader(false);
        } else if (
            selectedFile &&
            !selectedFile?.type?.includes(
                "vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ) &&
            !selectedFile?.type?.includes("vnd.ms-excel")
        ) {
            setIsLoader(false);
            setDialogContent("Only .xlsx or .xls file is allowed");
            setDialogOpen(true);
        } else {
            uploadFile();
        }
    };
    function uploadFile() {
        if (switchValue === "Potential & Budget") {
            const PotentialBudgetformData = new FormData();
            PotentialBudgetformData.append("file", selectedFile);
            PotentialBudgetformData.append("UserId", userDetails.userId);
            let yearValue = input.yearId ? input.yearId : yearId;
            PotentialBudgetformData.append("yearId", yearValue);
            PotentialBudget(siteToken,
                azureToken.token,
                UID.id,
                userDetails.userEmail,
                userDetails.gid,
                userDetails.role,
                userDetails.roleId,PotentialBudgetformData)
                .then((response) => {
                    console.log(response)
                    if (response.response) {
                        setIsLoader(false);
                        handleDialogMessage(response.responseMsg);
                        error()
                        // handleDialogMessage(msgPotential);
                    } else {
                        setIsLoader(false);
                        setSelectedFile(null);
                        setInputFileValue(Date.now);
                        setErrorData(response.objResult);
                        error()
                        handleDialogMessage(
                          response.responseMsgs && response.responseMsgs.length > 0
                            ? response.responseMsgs
                            : response.responseMsg
                        );
                    }
                })
                .catch((error) => {
                    setIsLoader(false);
                    setSelectedFile(null);
                    setInputFileValue(Date.now);
                    handleDialogMessage("Exception in upload");
                });
        } else {
            const MonthformData = new FormData();
            MonthformData.append("file", selectedFile);
            MonthformData.append("UserId", userDetails.userId);
            let yearValue = input.yearId ? input.yearId : yearId;
            MonthformData.append("yearId", yearValue);
            let monthValue = input.monthId;
            MonthformData.append("monthId", monthValue);
            MonthlyUpload(siteToken,
                azureToken.token,
                UID.id,
                userDetails.userEmail,
                userDetails.gid,
                userDetails.role,
                userDetails.roleId,
MonthformData,)
                .then((response) => {
                    console.log(response)
                    if (response.response) {
                        setIsLoader(false);
                        handleDialogMessage(response.responseMsg);
                        errorMonth()

                    } else {
                        setIsLoader(false);
                        setSelectedFile(null);
                        setInputFileValue(Date.now);
                        setErrorData(response.objResult);
                        errorMonth()
                        handleDialogMessage(
                          response.responseMsgs && response.responseMsgs.length > 0
                            ? response.responseMsgs
                            : response.responseMsg
                        );
                    }
                })
                .catch((error) => {
                    setIsLoader(false);
                    setSelectedFile(null);
                    setInputFileValue(Date.now);
                    handleDialogMessage("Exception in upload");
                });
        }
    }
    const validateFieldsOnSubmit = (e) => {
        let ret = true;
        let errors = {};
        if (!defaultInput?.month && switchValue === "Monthly Actuals") {
            errors.month = "Mandatory*";
            ret = false;
        }
        if (!defaultInput?.year) {
            errors.year = "Mandatory*";
            ret = false;
        }
        setErrorTexts({
            ...errorTexts,
            month: errors.month,
            year: errors.year,
        });
        return ret;
    };
    const downloadTemplate = (e) => {
        window.open(
            switchValue === "Potential & Budget" ? PotBudTemplate : ActualsTemplate
        );
    };
    function DisableYears(value) {
        let yearId = yearData.find((x) => x.name == value)?.id;
        let fiscalYearId = yearData.find(
            (x) => x.name === getCurrentFiscalYear()
        )?.id;
        if (switchValue === "Potential & Budget") {
            // return (yearId>fiscalYearId+1)||(yearId<fiscalYearId-1);
            return yearId > fiscalYearId + 1;
        } else {
            // return yearId>fiscalYearId || yearId<fiscalYearId-1;
            return yearId > fiscalYearId;
        }
    }
    function DisableMonths(value) {
        let fiscalmonth = monthData.find((x) => x.name == value.name)?.id;

        let fiscalYearId = yearData.find(
            (x) => x.name === getCurrentFiscalYear()
        )?.id;
        if (defaultInput.year.id == fiscalYearId) {
            let monthId = monthData.find((x) => x.name === getCurrentMonth())?.id;
            return monthId < fiscalmonth;
        }
    }

    useEffect(() => {
        getYearDetails();
        getMonthDetails();
    }, []);
    return (
        <>
            <Grid className="bulk-upload-paperSty">
                <Grid item xs={12} className="d-flex jc-center">
                    <div className="bulk-upload-block">
                        <h3 className="bulk-upload-title"> Sales Planning Upload</h3>
                    </div>
                </Grid>
                {isLoader ? (
                    getProgressComponent()
                ) : (
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={4}>
                            <Grid component="label" container alignItems="center" spacing={1}>
                                <Grid item xs={4} md={3}>
                                    <h5>Potential & Budget</h5>
                                </Grid>
                                <Grid item xs={2} md={2}>
                                    <Switch
                                        checked={switchValue !== "Potential & Budget"}
                                        onChange={toggleSwitch}
                                    />
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <h5>Monthly Actuals</h5>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <Autocomplete
                                disableClearable
                                fullWidth
                                name="year"
                                value={defaultInput.year}
                                inputValue={defaultInput?.yearText}
                                select="true"
                                loading
                                loadingText="No Options"
                                id="year"
                                freeSolo
                                margin="normal"
                                options={yearData}
                                onChange={handleAutocompleteChange}
                                onInputChange={handleAutocompleteTextChange}
                                getOptionLabel={(option) => (option.name ? option.name : "")}
                                // getOptionDisabled={(option) =>
                                //   //option.name !== getCurrentFiscalYear()
                                //   DisableYears(option.name)
                                // }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Fiscal Year"
                                        variant="filled"
                                        error={errorTexts.year}
                                        helperText={errorTexts.year}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <Autocomplete
                                disableClearable
                                fullWidth
                                name="month"
                                value={defaultInput.month}
                                select="true"
                                loading
                                loadingText="No Options"
                                id="month"
                                freeSolo
                                margin="normal"
                                options={monthData}
                                onChange={handleAutocompleteChange}
                                disabled={switchValue === "Potential & Budget"}
                                getOptionLabel={(option) => (option.name ? option.name : "")}
                                // getOptionDisabled={(option) =>
                                //   //option.name !== getCurrentFiscalYear()
                                //   DisableMonths(option)
                                // }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Month"
                                        error={errorTexts.month}
                                        helperText={errorTexts.month}
                                        variant="filled"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <form className="bulk-upload-form">
                                <TextField
                                    id="fileUploadSalesPlanning"
                                    variant="filled"
                                    label={switchValue + " Upload File"}
                                        fullWidth
                                        disabled={userDetails.roleId==10}
                                    onChange={updateExcelHandler}
                                    type="file"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment
                                                position="end"
                                                className="btn-download-template"
                                            >
                                                {getUploadAddornment()}
                                                {getDownloadAddornment()}
                                            </InputAdornment>
                                        ),
                                    }}
                                    key={inputFileValue}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </form>
                            <span>Upload file format in excel i.e .xlsx, .xls format</span>
                        </Grid>
                        {/* <Grid
            item
            xs={12}
            sm={6}
            md={2}
            className="text-center btn-download-template"
          >
            <span>{"Sample " + switchValue + " Template"}</span>
            <Button
              className="pt-button--secondary bulk-upload-submit-btn"
              onClick={downloadTemplate}
              style={{ marginLeft: "1em" }}
            >
              Download
            </Button>
          </Grid> */}
                    </Grid>
                )}
            </Grid>
            <div>
                <Dialog
                    open={dialogOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {switchValue + " Data Upload Alert"}
                    </DialogTitle>
                    <DialogContent>
                        {dialogContent && typeof dialogContent === "object" ? (
                            <DialogContentText id="alert-dialog-description">
                                <h5>
                                    {dialogContent
                                        ?.filter((x, index) => index === 0)
                                        ?.map((c) => (
                                            <>&emsp;&emsp;{c.join(" ")}</>
                                        ))}
                                </h5>
                                <ol className="ol-bulkUpload-Error">
                                    {dialogContent
                                        ?.filter((x, index) => index !== 0)
                                        ?.map((c) => (
                                            <li>
                                                {c.map((x) =>
                                                    x.includes("--") && x.includes(":") ? (
                                                        x
                                                    ) : (
                                                        <ul className="ul-bulkUpload-Error">
                                                            <li>{x}</li>
                                                        </ul>
                                                    )
                                                )}
                                            </li>
                                        ))}
                                </ol>
                            </DialogContentText>
                        ) : (
                            <DialogContentText
                                id="alert-dialog-description "
                                className="dialog-center"
                            >
                                {dialogContent?.includes("successfully.") ? (
                                    <>
                                        {dialogContent?.split("successfully.")[0] +
                                            " successfully."}
                                        {dialogContent?.split("successfully.")?.length > 0 ? (
                                            <>
                                                <br />
                                                {dialogContent?.split("successfully.")[1]}
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </>
                                ) : (
                                    dialogContent
                                )}
                            </DialogContentText>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleClose}
                            className="pt-button--secondary"
                            title="Close"
                        >
                            Close
                        </Button>
                        {errorList && errorList.length > 0 || monthError && monthError.length > 0 ? (
                            <Button
                                onClick={exportErrorData}
                                className="pt-button--secondary"
                                title={"Export " + switchValue + " upload error data to excel"}
                            >
                                Export Error Data
                            </Button>
                        ) : (
                            <></>
                        )}
                    </DialogActions>
                </Dialog>
            </div>
            {/* {isLoader ? <Loading loaderText="Upload in progress...." /> : <></>} */}
        </>
    );
}
