export const authUserAction = (gid) => {
  return {
    type: "myIDLogin",
    payLoad: gid,
  };
};
export const saveAccessTokenAction = (token) => {
  return {
    type: "saveAccessToken",
    payLoad: token,
  };
};
export const storeUserDetailsAction = (userData) => {
  return {
    type: "storeUserData",
    payLoad: userData,
  };
};
export const storeUpdatePotentialResponse = (response) => {
  return {
    type: "updatePotential",
    payLoad: 1,
  };
};
export const storeUpdateBudgetResponse = (response) => {
  return {
    type: "updateBudget",
    payLoad: 2,
  };
};
export const storeUpdateManualIndirectResponse = (response) => {
  return {
    type: "updateManualIndirect",
    payLoad: 3,
  };
};
export const isEditableAction = (editable) => {
  return {
    type: "edit",
    payLoad: editable,
  };
};
export const isEditAction = (editables) => {
    return {
        type: "edits",
        payLoad: editables,
    };
};


export const saveUserIdAction = (id) => {
    return {
        type: "encryptedId",
        payLoad: id,
    };
};
export const saveAzureTokenAction = (token) => {
    return {
        type: "saveAzureToken",
        payLoad: token,
    };
};
export const storeMenuItemsAction = (menuItems) => {
    return {
        type: "saveMenuItems",
        payLoad: menuItems,
    };
};
export const storeLandingMenuItemsAction = (menuItems) => {
    return {
        type: "saveLandingMenuItems",
        payLoad: menuItems,
    };
};
