import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MenuItem, TextField, Grid, CircularProgress } from "@material-ui/core";
import {
  getCustomerDDL,
  getEngineerDDL,
  getGckDDL,
  getSalesOfficeDDL,
  getYearDDL,
} from "../../../Redux/APIs/api_Common";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";

export default function FilterInputs(props) {
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 200,
  });
  function getProgressComponent() {
    return <CircularProgress style={{ height: `30px`, width: `30px` }} />;
  }
  const token = useSelector((state) => state.saveTokenReducer.token);
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
  let [isLoader, setIsLoader] = useState(false);
  let [filtersInput, setFiltersInput] = useState({
    year: { id: 0, name: "" },
    yearText: "",
    customer: { id: 0, name: "" },
    customerText: "",
    gck: { id: 0, name: "" },
    gckText: "",
    engineer: { id: 0, name: "" },
    engineerText: "",
  });
  let [filters, setFilters] = useState({
    yearId: 0,
    customer: [],
    gck: [],
    engineer: [],
  });
  let [customers, setCustomers] = useState([]);
  function getCustomers(yearId) {
    setIsLoader(true);
      getCustomerDDL(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId, yearId)
      .then((response) => {
        setCustomers(response);
        setIsLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoader(false);
      });
  }
  let [years, setYears] = useState([]);
  function getYears() {
      getYearDDL(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId)
      .then((response) => {
        setYears(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  let [gcks, setGCKs] = useState([]);
  function getGCKs(yearId) {
      getGckDDL(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId, yearId)
      .then((response) => {
        setGCKs(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  let [engineers, setEngineers] = useState([]);
  function getEngineers() {
      getEngineerDDL(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId)
      .then((response) => {
        setEngineers(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const handleAutocompleteChange = (event, obj) => {
    let elem = event.target.id;
    let elemId = !elem
      ? ""
      : elem.includes("-")
        ? elem.substr(0, elem.indexOf("-"))
        : elem;
    switch (elemId) {
      case "year":
        setFiltersInput({
          ...filtersInput,
          [elemId]: obj,
          [elemId + "Text"]: obj?.name,
        });
        setFilters({
          ...filters,
          [elemId + "Id"]: obj?.id,
        });
        props.changeFilters({
          ...props.filters,
          [elemId + "Id"]: obj?.id,
        });
        break;
      case "customer":
        setFiltersInput({
          ...filtersInput,
          [elemId]: obj,
          [elemId + "Text"]: obj?.name,
        });
        setFilters({
          ...filters,
          [elemId]: [obj],
        });
        props.changeFilters({
          ...props.filters,
          [elemId]: [obj],
        });
        break;
      case "gck":
        setFiltersInput({
          ...filtersInput,
          [elemId]: obj,
          [elemId + "Text"]: obj?.name,
        });
        setFilters({
          ...filters,
          [elemId]: [obj],
        });
        props.changeFilters({
          ...props.filters,
          [elemId]: [obj],
        });
        break;
      case "engineer":
        setFiltersInput({
          ...filtersInput,
          [elemId]: obj,
          [elemId + "Text"]: obj?.name,
        });
        setFilters({
          ...filters,
          [elemId]: [obj],
        });
        props.changeFilters({
          ...props.filters,
          [elemId]: [obj],
        });
        break;
      default:
        break;
    }
  };
  const handleAutocompleteTextChange = (event, text) => {
    let elem = event.target.id;
    let elemId = !elem
      ? ""
      : elem.includes("-")
        ? elem.substr(0, elem.indexOf("-"))
        : elem;
    let obj = {};
    switch (elemId) {
      case "year":
        setFiltersInput({
          ...filtersInput,
          [elemId + "Text"]: text,
        });
        let yearId = years.find((x) => x.name === text)?.id;
        setFilters({
          ...filters,
          [elemId + "Id"]: yearId ? yearId : 0,
        });
        break;
      case "customer":
        setFiltersInput({
          ...filtersInput,
          [elemId + "Text"]: text,
        });
        obj = customers.find((x) => x.name === text);
        setFilters({
          ...filters,
          [elemId]: obj ? [obj] : [],
        });
        break;
      case "gck":
        setFiltersInput({
          ...filtersInput,
          [elemId + "Text"]: text,
        });
        obj = gcks.find((x) => x.name === text);
        setFilters({
          ...filters,
          [elemId]: obj ? [obj] : [],
        });
        break;
      case "engineer":
        setFiltersInput({
          ...filtersInput,
          [elemId + "Text"]: text,
        });
        obj = engineers.find((x) => x.name === text);
        setFilters({
          ...filters,
          [elemId]: obj ? [obj] : [],
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getYears();
    getEngineers();
  }, []);

  useEffect(() => {
    if (props.filters.yearId > 0) {
      getCustomers(props.filters.yearId);
      getGCKs(props.filters.yearId);
    }
  }, [props]);

  useEffect(() => {
    if (
      props.filters &&
      years?.length > 0 &&
      engineers?.length > 0 &&
      customers?.length > 0 &&
      gcks?.length > 0
    ) {
      setIsLoader(true);
      const year = years.find((x) => x.id === props?.filters?.yearId);
      const engineer = engineers.find(
        (x) => x.id === props?.filters?.engineer[0]?.id
      );
      const customer = customers.find(
        (x) => x.id === props?.filters?.customer[0]?.id
      );
      const gck = gcks.find((x) => x.id === props?.filters?.gck[0]?.id);
      setFiltersInput({
        ...filtersInput,
        year: year,
        yearText: year?.name,
        engineer: engineer,
        engineerText: engineer?.name,
        gck: gck,
        gckText: gck?.name,
        customer: customer,
        customerText: customer?.name,
      });
      setIsLoader(false);
    }
  }, [props, years, customers, engineers, gcks]);

  // useEffect(() => {
  //   console.log(filtersInput);
  // }, [filtersInput]);

  return (
    <>
      {isLoader ? (
        <Grid item xs={12} sm={12} className="d-flex jc-center pd-1">
          {getProgressComponent()}
        </Grid>
      ) : (
        <>
          <Grid item xs={12} sm={3} className="pd-1">
            <Autocomplete
              filterOptions={filterOptions}
              disableClearable
              fullWidth
              name="year"
              value={filtersInput?.year}
              inputValue={filtersInput?.yearText}
              select
              id="year"
              disabled
              freeSolo
              margin="normal"
              options={years}
              onChange={handleAutocompleteChange}
              onInputChange={handleAutocompleteTextChange}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              renderInput={(params) => <TextField {...params} label="Year" />}
            />
          </Grid>
          <Grid item xs={12} sm={3} className="pd-1">
            <Autocomplete
              filterOptions={filterOptions}
              disableClearable
              fullWidth
              name="customer"
              value={filtersInput?.customer}
              inputValue={filtersInput?.customerText}
              select
              id="customer"
              freeSolo
              margin="normal"
              disabled={!customers.length > 0}
              options={customers}
              onChange={handleAutocompleteChange}
              onInputChange={handleAutocompleteTextChange}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              renderInput={(params) => (
                <TextField {...params} label="Customer" />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={3} className="pd-1">
            <Autocomplete
              filterOptions={filterOptions}
              disableClearable
              fullWidth
              name="gck"
              value={filtersInput?.gck}
              inputValue={filtersInput?.gckText}
              select
              id="gck"
              freeSolo
              margin="normal"
              disabled={!gcks.length > 0}
              options={gcks}
              onChange={handleAutocompleteChange}
              onInputChange={handleAutocompleteTextChange}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              renderInput={(params) => <TextField {...params} label="GCK" />}
            />
          </Grid>
          <Grid item xs={12} sm={3} className="pd-1">
            <Autocomplete
              filterOptions={filterOptions}
              disableClearable
              fullWidth
              name="engineer"
              value={filtersInput?.engineer}
              inputValue={filtersInput?.engineerText}
              select
              id="engineer"
              freeSolo
              disabled={userDetails?.roleId === 6 || !engineers.length > 0}
              margin="normal"
              options={engineers}
              onChange={handleAutocompleteChange}
              onInputChange={handleAutocompleteTextChange}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              renderInput={(params) => (
                <TextField {...params} label="Engineer" />
              )}
            />
          </Grid>
        </>
      )}
    </>
  );
}
