import { baseApiURL } from "../../Utilities/Utility";
import axios from "axios";

export function getSalesOrdersList(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            //"Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(data),
    };

    return fetch(baseApiURL + "/SalesOrder/GetAllSalesOrderDetails?encUserId=" + userId, options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
            return {
                response: false,
                responseMsg: "Exception occured while fetching sales orders list",
            };
        });
    // const responseJson = await response.json();
    // dispatch(storeUserDetailsAction(responseJson.result));
}
//export function getSalesDist(siteToken, enggId) {
//    const options = {
//        method: "get",
//        url: baseApiURL + "/SalesOrder/GetSalesOrdersForDistrict",
//        headers: {
//            // "Access-Control-Allow-Origin": "*",
//            Authorization: "Bearer " + siteToken,
//        },
//        params: {enggId: enggId },
//    };
//    return (
//        axios(options)
//            //.then((response) => response.json())
//            .then((response) => {
//                return response.data.result;
//            })
//            .catch((error) => {
//                console.log("Error : ", error);
//            })
//    );
//}
export function getSalesDist(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "post",
        
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            //"Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(data),
    };

    return fetch(baseApiURL + "/SalesOrder/GetSalesOrdersForDistrict?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
            return {
                response: false,
                responseMsg: "Exception occured while fetching sales orders list",
            };
        });
}
//export function getSalesDist(siteToken, data) {
//    const options = {
//        method: "get",
//        mode: "cors",
//        headers: {
//            Accept: "application/json, text/plain, */*",
//            "Content-Type": "application/json",
//            "Access-Control-Allow-Credentials": true,
//            Authorization: "Bearer " + siteToken,
//            //"Access-Control-Allow-Origin": "*",
//        },
//        params: {data:data},

//    };
//    return fetch(baseApiURL + "/SalesOrder/GetSalesOrdersForDistrict",  options)
//        .then((response) => response.json())
//        .then((data) => {
//            return data.result;
//        })
//        .catch((error) => {
//            //return { response: false, responseMsg: error };
//            console.log("Error : ", error);
//        });
//}

