import { Switch, Route } from "react-router-dom";
import LoginRoot from "../Screens/LoginRegister/LoginRoot";
// import ChangePassword from '../Pages/ResetPassword/ChangePassword';
import Error from "../Components/Error/Error404";


export const LoginRoute = (
  <Switch>
        <Route exact path="/" render={() => <LoginRoot />} />
        <Route render={() => <Error />} />
  </Switch>
);

// export const ResetPasswordRoute = (
//     <Switch>
//         {/* <Redirect from="/" to="/changePassword" /> */}
//         <Route exact path="/" render={() => <ChangePassword />} />
//     </Switch>
// );
