import { baseApiURL } from "../../Utilities/Utility";
import axios from "axios";

export function getRoleDDL(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,) {
    const options = {
        method: "get",
        url: baseApiURL + "/Common/GetRolesDDL?encUserId=" + encodeURIComponent(userId),
        headers: {
            // "Access-Control-Allow-Origin": "*",
            // Authorization: "Bearer " + siteToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        //params: { userId: userId },
    };
    return (
        axios(options)
            //.then((response) => response.json())
            .then((response) => {
                return response.data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}

export function getMonthDDL(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        url: baseApiURL + "/Common/GetMonthDDL?encUserId=" + encodeURIComponent(userId),
        headers: {
            //"Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        //params: { userId: userId },
    };
    return (
        axios(options)
            //.then((response) => response.json())
            .then((response) => {
                return response.data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}

export function getYearDDL(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        url: baseApiURL + "/Common/GetYearDDL?encUserId=" + encodeURIComponent(userId),
        headers: {
            //"Access-Control-Allow-Origin": "*",
            //Authorization: "Bearer " + siteToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        // params: { userId: userId },
    };
    return (
        axios(options)
            //.then((response) => response.json())
            .then((response) => {
                return response.data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}
export function getCustomerDDL(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, yearId) {
    const options = {
        method: "get",
        url: baseApiURL + "/Common/GetCustomerDDL?encUserId=" + encodeURIComponent(userId) + "&id=" + yearId,
        headers: {
            //"Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        // params: { userId: userId, id: yearId },
    };
    return (
        axios(options)
            //.then((response) => response.json())
            .then((response) => {
                return response.data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}
export function getGckDDL(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, yearId) {
    const options = {
        method: "get",
        url: baseApiURL + "/Common/GetGckDDL?encUserId=" + encodeURIComponent(userId) + "&id=" + yearId,
        headers: {
            // "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,

        },
        //params: { userId: userId, id: yearId },
    };
    return (
        axios(options)
            //.then((response) => response.json())
            .then((response) => {
                return response.data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}

export function getEngineerDDL(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,) {
    const options = {
        method: "get",
        url: baseApiURL + "/Common/GetEngineerDDL?encUserId=" + encodeURIComponent(userId),
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        //params: { userId: userId },
    };
    return (
        axios(options)
            //.then((response) => response.json())
            .then((response) => {
                return response.data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}
export function getSalesBranchDDL(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
) {
    const options = {
        method: "get",
        url: baseApiURL + "/Common/GetSalesBranchDDL?encUserId=" + encodeURIComponent(userId),
        headers: {
            //"Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,

        },
        //params: { userId: userId },
    };
    return (
        axios(options)
            //.then((response) => response.json())
            .then((response) => {
                return response.data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}
export function getSalesTeritoryDDL(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,) {
    const options = {
        method: "get",
        url: baseApiURL + "/Common/GetSalesTeritoryDDL?encUserId=" + encodeURIComponent(userId),
        headers: {
            //"Access-Control-Allow-Origin": "*",
            //Authorization: "Bearer " + siteToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        // params: { userId: userId },
    };
    return (
        axios(options)
            //.then((response) => response.json())
            .then((response) => {
                return response.data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}
export function getSalesOfficeDDL(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, st, sb) {
    const options = {
        method: "get",
        url: baseApiURL + "/Common/GetSalesOfficeDDL?encUserId=" + encodeURIComponent(userId) + "&st=" + st + "&sb=" + sb,
        headers: {
            //"Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        // params: { userId: userId, st: st, sb: sb },
    };
    return (
        axios(options)
            //.then((response) => response.json())
            .then((response) => {
                return response.data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}
export function getSalesDepartmentDDL(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, st, sb, so) {
    const options = {
        method: "get",
        url: baseApiURL + "/Common/GetSalesDepartmentDDL?encUserId=" + encodeURIComponent(userId) + "&st=" + st + "&sb=" + sb + "so=" + so,
        headers: {
            //"Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        //params: { userId: userId, st: st, sb: sb, so: so },
    };
    return (
        axios(options)
            //.then((response) => response.json())
            .then((response) => {
                return response.data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}
export function getSalesDistrictDDL(siteToken, userId) {
    const options = {
        method: "get",
        url: baseApiURL + "/Common/GetSalesDistrictDDL",
        headers: {
            //"Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + siteToken,
        },
        params: { userId: userId },
    };
    return (
        axios(options)
            //.then((response) => response.json())
            .then((response) => {
                return response.data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}
export function getBusinessUnitDDL(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,) {
    const options = {
        method: "get",
        url: baseApiURL + "/Common/GetBusinessUnitDDL?encUserId=" + encodeURIComponent(userId),
        headers: {
            // "Access-Control-Allow-Origin": "*",
            //Authorization: "Bearer " + siteToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        //params: { userId: userId },
    };
    return (
        axios(options)
            //.then((response) => response.json())
            .then((response) => {
                return response.data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}

//export function getBusinessSegmentDDL(siteToken,
//    azureToken,
//    userId,
//    userEmail,
//    gid,
//    roleName,
//    roleId,
// bu) {
//    const options = {
//        method: "get",
//        url: baseApiURL + "/Common/GetBusinessSegmentDDL?encUserId=" + encodeURIComponent(userId) + "&buid=" + bu,
//        headers: {
//            //"Access-Control-Allow-Origin": "*",
//            //Authorization: "Bearer " + siteToken,
//            Authorization: "Bearer " + siteToken.token,
//            AzureToken: azureToken,
//            UserEmail: userEmail,
//            RoleName: roleName,
//            Gid: gid,
//            Roleid: roleId,
//        },
//        //params: { userId: userId, buid: bu },
//    };
//    return (
//        axios(options)
//            //.then((response) => response.json())
//            .then((response) => {
//                return response.data.result;
//            })
//            .catch((error) => {
//                console.log("Error : ", error);
//            })
//    );
//}
export async function getBusinessSegmentDDL(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    bu) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        //params: { encUserId: userId, buid: bu },
    };
    try {
        console.log("userdata", userId);
        const response = await fetch(
            baseApiURL + "/Common/GetBusinessSegmentDDL?encUserId=" + userId + "&buid=" + bu,
            options
        );
        const data = await response.json();
        return data.result;
    } catch (error) {
        console.log("Error : ", error);
    }
}

export function getBusinessSubSegmentDDL(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, bu, bs) {
    const options = {
        method: "get",
        url: baseApiURL + "/Common/GetBusinessSubSegmentDDL?encUserId=" + encodeURIComponent(userId) + "&buid=" + bu + "&bsid=" + bs,
        headers: {
            //"Access-Control-Allow-Origin": "*",
            //Authorization: "Bearer " + siteToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,

        },
        //params: { userId: userId, buid: bu, bsid: bs },
    };
    return (
        axios(options)
            //.then((response) => response.json())
            .then((response) => {
                return response.data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}
export function getGck(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, yearId, gckId) {
    const options = {
        method: "get",
        url: baseApiURL + "/Admin/GetGCKDetailsBasedOnSE?encUserId=" + encodeURIComponent(userId) + "&yearId=" + yearId + "&gckName=" + gckId,
        headers: {
            // "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        //params: { userId: userId, yearId: yearId, gckId: gckId },
    };
    return (
        axios(options)
            //.then((response) => response.json())
            .then((response) => {
                return response.data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}
export function getCustomer(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, yearId, name) {
    const options = {
        method: "get",
        url: baseApiURL + "/Common/GetCustomerDetailsBasedOnSE?encUserId=" + encodeURIComponent(userId) + "&yearId=" + yearId + "&customerName=" + name,
        headers: {
            // "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + siteToken,
        },
        //params: { userId: userId, id: yearId, name: name },
    };
    return (
        axios(options)
            //.then((response) => response.json())
            .then((response) => {
                return response.data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}
export function getError(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
) {
    const options = {
        method: "get",
        url: baseApiURL + "/Upload/GetErrorLogs?encUserId=" + encodeURIComponent(userId),
        headers: {
            // "Access-Control-Allow-Origin": "*",
            // Authorization: "Bearer " + siteToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        //params: { userId: userId},
    };
    return (
        axios(options)
            //.then((response) => response.json())
            .then((response) => {
                return response.data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}
export function getMonthError(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,) {
    const options = {
        method: "get",
        url: baseApiURL + "/Upload/GetMonthlyErrorLogs?encUserId=" + encodeURIComponent(userId),
        headers: {
            // "Access-Control-Allow-Origin": "*",
            // Authorization: "Bearer " + siteToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        // params: { userId: userId },
    };
    return (
        axios(options)
            //.then((response) => response.json())
            .then((response) => {
                return response.data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}
//export function getError(siteToken, userId) {
//    const options = {
//        method: "get",
//        mode: "cors",
//        headers: {
//            Authorization: "Bearer " + siteToken,
//        }
//    };
//    return fetch(baseApiURL + "/Upload/GetErrorLogs?userId=" + userId, options)
//        .then((response) => response.json())
//        .then((data) => {
//            return data.result;
//        })
//        .catch((error) => {
//            console.log("Error : ", error);
//        });
//}
export function saveLock(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Common/PostLockedDetails?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
//export function getLock(siteToken,
//    azureToken,
//    userId,
//    userEmail,
//    gid,
//    roleName,
//    roleId,) {
//    const options = {
//        method: "get",
//        //headers: { Authorization: "Bearer " + siteToken },
//        Authorization: "Bearer " + siteToken.token,
//        AzureToken: azureToken,
//        UserEmail: userEmail,
//        RoleName: roleName,
//        Gid: gid,
//        Roleid: roleId,
//    };
//    return fetch(baseApiURL + "/Common/GetLockingDetails?encUserId=" + encodeURIComponent(userId), options)
//        .then((response) => response.json())
//        .then((data) => {
//            return data.result;
//        })
//        .catch((error) => {
//            console.log("Error : ", error);
//        });
//}


export function getLock(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,) {
    const options = {
        method: "get",
        url: baseApiURL + "/Common/GetLockingDetails?encUserId=" + encodeURIComponent(userId),
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        //params: { userId: userId },
    };
    return (
        axios(options)
            //.then((response) => response.json())
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}