import { baseApiURL } from "../../Utilities/Utility";

export function getSalesOrderInputData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            //"Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(data),
    };

    return fetch(baseApiURL + "/SalesOrder/GetSalesOrderDetails?encUserId=" + userId, options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
            return {
                response: false,
                responseMsg: "Exception occured while fetching sales order details",
            };
        });
}

export function updatePotential(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": true,
            //Authorization: "Bearer " + newToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            //"Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/SalesOrder/UpdatePotential?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
            return {
                response: false,
                responseMsg: "Exception occured while updating potential data",
            };
        });
}

export function updateBudget(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": true,
            //Authorization: "Bearer " + newToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,

            //"Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(data),
    };

    return fetch(baseApiURL + "/SalesOrder/UpdateBudget?encUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
            return {
                response: false,
                responseMsg: "Exception occured while updating budget data",
            };
        });
    // const responseJson = await response.json();
    // dispatch(storeUserDetailsAction(responseJson.result));
}

export function updateManualIndirect(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": true,
            //Authorization: "Bearer " + newToken,
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            //"Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(data),
    };

    return fetch(baseApiURL + "/SalesOrder/UpdateManualIndirect?encUserId=" + userId, options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
            return {
                response: false,
                responseMsg: "Exception occured while updating manual indirect actuals",
            };
        });
}
