import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import MaterialTable, { MTableToolbar } from "material-table";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {
  GetAddButton,
  GetEditButton,
  tableIcons,
  getCurrentFiscalYear,
} from "../../../../Utilities/Utility";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { tableOptionsUser } from "../../../../Themes/LightTheme";
import { useSelector } from "react-redux";
import SnackbarCustom from "../../../../Components/Snackbar/Snackbar";
import {
  getGckData,
  addGckData,
  updateGckData,
  deleteGckData,
  getAllGckDatayear,
} from "../../../../Redux/APIs/api_MasterData";
import {
  getBusinessUnitDDL,
  getBusinessSegmentDDL,
  getBusinessSubSegmentDDL,
  getYearDDL,
} from "../../../../Redux/APIs/api_Common";
import GckInputForm from "../MasterDataForms/CustomerGckSegmentsForms/GckInputForm";
import * as XLSX from "xlsx";

export default function GckMaster(props) {
  const tableRef = useRef();
  const token = useSelector((state) => state.saveTokenReducer.token);
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const [userRole, setUserRole] = useState(userDetails.roleId);
  const [tableData, setTableData] = useState([]);
  let [dataFiltered, setDataFiltered] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [rowData, setRowData] = useState("");
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpen(true);
  };
  const handleClose = (e) => {
    setOpen(false);
    setOpenDialog(false);
  };

  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpenDialog = (data) => {
    setRowData(data);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const icon = () => {
    return (
      <GetAppRoundedIcon
        style={{ fontSize: 53, color: `var(--btn-primary-bg-color)` }}
      />
    );
  };

  //Api
  let [bunitData, setBunitData] = useState([]);
  async function getBusinessUnitDetails() {
      const response = await getBusinessUnitDDL(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId);
    if (response && response.length > 0) {
      setBunitData(
        response.map((item) => ({ value: item.id, label: item.name }))
      );
    } else {
      setBunitData([]);
    }
  }

  let [bsegmentData, setBsegmentData] = useState([]);

  async function getBusinessSegmentDetails(buid) {
    const response = await getBusinessSegmentDDL(
        siteToken,
        azureToken.token,
        UID.id,
        userDetails.userEmail,
        userDetails.gid,
        userDetails.role,
        userDetails.roleId,
      buid
    );

    if (response && response.length > 0) {
      setBsegmentData(
        response.map((item) => ({ value: item.id, label: item.name }))
      );
    } else {
      setBsegmentData([]);
    }
  }

  let [bsubsegmentData, setBsubsegmentData] = useState([]);
  async function getBusinessSubSegmentDetails(buid, bsid) {
    const response = await getBusinessSubSegmentDDL(
        siteToken,
        azureToken.token,
        UID.id,
        userDetails.userEmail,
        userDetails.gid,
        userDetails.role,
        userDetails.roleId,
      buid,
      bsid
    );
    if (response && response.length > 0) {
      setBsubsegmentData(
        response.map((item) => ({ value: item.id, label: item.name }))
      );
    } else {
      setBsegmentData([]);
    }
  }

  let [yearData, setYearData] = useState([]);
  let [yearId, setYearId] = useState(0);
  async function getYearDetails() {
    let year = getCurrentFiscalYear();
      const response = await getYearDDL(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId);
    if (response && response.length > 0) {
      setYearData(response.map((item) => ({ id: item.id, name: item.name })));
      let obj = response?.find((x) => x.name == year);

      //getTableData(obj.id);
      setYearId(obj.id);
      setDefaultYearInput({
        year: obj,
        yearText: obj?.name,
      });
    } else {
      setYearData([]);
    }
  }
  //render value
  function renderBunitValue(bunit) {
    let obj = {};
    if (bunitData && bunitData.length > 0) {
      obj = bunitData.find((c) => c.value == bunit);
    }
    if (obj) {
      return obj.label;
    } else {
      return "";
    }
  }

  function renderBSegmentValue(bsegment) {
    let obj = {};
    if (bsegmentData && bsegmentData.length > 0) {
      obj = bsegmentData.find((c) => c.value == bsegment);
    }
    if (obj) {
      return obj.label;
    } else {
      return "";
    }
  }

  function renderBSubSegmentValue(bsubsegment) {
    let obj = {};
    if (bsubsegmentData && bsubsegmentData.length > 0) {
      obj = bsubsegmentData.find((c) => c.value == bsubsegment);
    }
    if (obj) {
      return obj.label;
    } else {
      return "";
    }
  }
  let [defaultyearInput, setDefaultYearInput] = useState({
    year: {},
    yearText: "",
  });
  let [yearInput, setYearInput] = useState({
    yearId: 0,
  });

  const handleYearChange = (e, obj) => {
    setDefaultYearInput({
      year: obj,
      yearText: obj?.name,
    });
    setYearInput({
      yearId: obj?.id,
    });
    getTableData(obj.id);
  };
  const handleYearInputChange = (e, text) => {
    setDefaultYearInput({
      yearText: text,
    });
    let yearId = yearData.find((x) => x.name === text)?.id;
    setYearInput({
      yearId: yearId ? yearId : 0,
    });
  };

  const columns = [
    { title: "GCK Id", field: "gckId", hidden: true },
    { title: "GCK", field: "gck" },
    {
      title: "Business Unit",
      field: "bunitId",
      render: (rowData) => <span>{renderBunitValue(rowData.bunitId)}</span>,
      customFilterAndSearch: (term, rowData) =>
        renderBunitValue(rowData.bunitId)
          .toLowerCase()
          .includes(term.toLowerCase()),
    },
    {
      title: "Business Segment",
      field: "bsegmentId",
      render: (rowData) => (
        <span>{renderBSegmentValue(rowData.bsegmentId)}</span>
      ),
      customFilterAndSearch: (term, rowData) =>
        renderBSegmentValue(rowData.bsegmentId)
          .toLowerCase()
          .includes(term.toLowerCase()),
    },
    {
      title: "Business SubSegment",
      field: "bsubSegmentId",
      render: (rowData) => (
        <span>{renderBSubSegmentValue(rowData.bsubSegmentId)}</span>
      ),
      customFilterAndSearch: (term, rowData) =>
        renderBSubSegmentValue(rowData.bsubSegmentId)
          .toLowerCase()
          .includes(term.toLowerCase()),
    },
  ];
  const actions = [
    {
      icon: GetAddButton,
      tooltip: "Add GCK",
      onClick: () => handleClickOpenDialog(),
      isFreeAction: true,
    },
    {
      icon: icon,
      tooltip: "Export to Excel",
      onClick: () => ExportToExcel(),
      isFreeAction: true,
    },
    (rowData) => ({
      icon: GetEditButton,
      disabled:rowData.isreadonlyGCK,
      tooltip:getDisableTooltip(rowData.isreadonlyGCK,"Edit"),
      onClick: (event, rowData) => handleClickOpenDialog(rowData),
      isFreeAction: true,
    }),
  ];

  function getTableData(value) {
    setIsLoader(true);
      getAllGckDatayear(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId, value)
      .then((response) => {
        setTableData(response);
        setDataFiltered(response);
        setIsLoader(false);
      })
      .catch((error) => {
        handleSnackOpen("Error : " + error, "error");
        setIsLoader(false);
      });
  }
  useEffect(() => {
    setIsLoader(true);
    getYearDetails();
    getBusinessUnitDetails();
    getBusinessSegmentDetails();
    getBusinessSubSegmentDetails();
  }, []);
  
  const handleSubmit = () => {
    handleCloseDialog(true);
  };
  const handleUpdateData = (data) => {
    let yearIdValue = yearInput.yearId ? yearInput.yearId : yearId;
    setIsLoader(true);
    data.loggedinUserId = userDetails.userId;
      updateGckData(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId, data)
      .then((response) => {
        if (response.response) {
          handleSnackOpen(response.responseMsg, "success");
          setIsLoader(false);
          getTableData(yearIdValue);
          props.setGckSegments({
            gck:(props.gckSegments.gck)+1
          });
        } else {
          handleSnackOpen(response.responseMsg, "error");
          setIsLoader(false);
        }
      })
      .catch((error) => {
        handleSnackOpen("Exception : " + error, "error");
        setIsLoader(false);
      });
  };

  function handleAddData(data) {
    let yearIdValue = yearInput.yearId ? yearInput.yearId : yearId;
    setIsLoader(true);
    data.loggedinUserId = userDetails.userId;
      addGckData(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId, data)
      .then((response) => {
        if (response.response) {
          handleSnackOpen(response.responseMsg, "success");
          setIsLoader(false);
          getTableData(yearIdValue);
          props.setGckSegments({
            gck:(props.gckSegments.gck)+1
          });
        } else {
          handleSnackOpen(response.responseMsg, "error");
          setIsLoader(false);
        }
      })
      .catch((error) => {
        handleSnackOpen("Exception : " + error, "error");
        setIsLoader(false);
      });
  }
  const handleRowDelete = (oldTableData, resolve, reject) => {
    let yearIdValue = yearInput.yearId ? yearInput.yearId : yearId;
    setIsLoader(true);
    oldTableData.loggedinUserId = userDetails.userId;
      deleteGckData(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId, oldTableData)
      .then((response) => {
        if (response.response) {
          handleSnackOpen(response.responseMsg, "success");
          resolve();
          setIsLoader(false);
          getTableData(yearIdValue);
          props.setGckSegments({
            gck:(props.gckSegments.gck)+1
          });
        } else {
          handleSnackOpen(response.responseMsg, "error");
          reject();
          setIsLoader(false);
        }
      })
      .catch((error) => {
        handleSnackOpen("Exception : " + error, "error");
        reject();
        setIsLoader(false);
      });
  };
  function getDisableTooltip(value,type) {
    return value === true
      ? "GCK is already Associated with salesOrders"
      : type === "Edit"
      ? ""
      : "Delete";
  }
  function ExportToExcel() {
    const dataToDownload = tableRef?.current?.dataManager?.searchedData.map(
      (row) => {
        return {
          businessUnit: row.businessUnit,
          businessSegment: row.businessSegment,
          businessSubSegment: row.businesssubSegment,
          gck: row.gck,
        };
      }
    );
    let headers = [
      "Business Unit",
      "Business Segment",
      "Business Sub-Segment",
      "GCK",
    ];
    //const workSheet = XLSX.utils.json_to_sheet(newData);
    let ws = XLSX.utils.book_new();
    let wb = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, [headers]);
    XLSX.utils.sheet_add_json(ws, dataToDownload, {
      origin: "A2",
      skipHeader: true,
      cellStyles: true,
    });
    ws["!cols"] = cellAdjustToContents(dataToDownload);
    XLSX.utils.book_append_sheet(wb, ws, "Gck");
    XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(wb, "GckMaster.xlsx");
  }
  function cellAdjustToContents(data) {
    return [
      {
        wch: Math.max(
          ...data?.map((c) =>
            c.businessUnit ? c.businessUnit?.toString()?.length : 0
          )
        ),
      },
      {
        wch: Math.max(
          ...data?.map((c) =>
            c.businessSegment ? c.businessSegment?.toString()?.length : 0
          )
        ),
      },
      {
        wch: Math.max(
          ...data?.map((c) =>
            c.businessSubSegment ? c.businessSubSegment?.toString()?.length : 0
          )
        ),
      },
      {
        wch: Math.max(
          ...data?.map((c) => (c.gck ? c.gck?.toString()?.length : 0))
        ),
      },
    ];
  }
  useEffect(() => {
    if (yearId) {
      getTableData(yearId);
    }
  }, [yearId]);
  return (
    <div>
      <div className="App">
        <Grid container spacing={1}>
                  <Grid item xs={12}>
                      {userDetails.roleId !== 10 ?
                          <MaterialTable
                              tableRef={tableRef}
                              title=""
                              columns={columns}
                              data={tableData}
                              isLoading={isLoader}
                              icons={tableIcons}
                              options={tableOptionsUser}
                              actions={actions}
                              components={{
                                  Toolbar: (props) => {
                                      return (
                                          <div className="Table-margin">
                                              <Grid container spacing={1}>
                                                  <Grid item xs={2}>
                                                      <Autocomplete
                                                          disableClearable
                                                          fullWidth
                                                          name="year"
                                                          value={defaultyearInput.year}
                                                          inputValue={defaultyearInput?.yearText}
                                                          select="true"
                                                          loading
                                                          loadingText="No Options"
                                                          id="year"
                                                          freeSolo
                                                          margin="normal"
                                                          options={yearData}
                                                          onChange={handleYearChange}
                                                          onInputChange={handleYearInputChange}
                                                          getOptionLabel={(option) =>
                                                              option.name ? option.name : ""
                                                          }
                                                          renderInput={(params) => (
                                                              <TextField
                                                                  {...params}
                                                                  label="Fiscal Year"
                                                                  variant="filled"
                                                              />
                                                          )}
                                                      />
                                                  </Grid>

                                                  <Grid item xs={10}>
                                                      <MTableToolbar {...props} />
                                                  </Grid>
                                              </Grid>
                                          </div>
                                      );
                                  },
                              }}
                              editable={{
                                  isDeletable: (rowData) => userRole !== 10 && !rowData.isreadonlyGCK,
                                  deleteTooltip: (rowdata) => getDisableTooltip(rowdata.isreadonlyGCK, "Delete"),
                                  onRowDelete: (oldTableData) =>
                                      new Promise((resolve, reject) => {
                                          handleRowDelete(oldTableData, resolve, reject);
                                      }),
                              }}
                          /> : <MaterialTable
                              tableRef={tableRef}
                              title=""
                              columns={columns}
                              data={tableData}
                              isLoading={isLoader}
                              icons={tableIcons}
                              options={tableOptionsUser}
                              //actions={actions}
                              components={{
                                  Toolbar: (props) => {
                                      return (
                                          <div className="Table-margin">
                                              <Grid container spacing={1}>
                                                  <Grid item xs={2}>
                                                      <Autocomplete
                                                          disableClearable
                                                          fullWidth
                                                          name="year"
                                                          value={defaultyearInput.year}
                                                          inputValue={defaultyearInput?.yearText}
                                                          select="true"
                                                          loading
                                                          loadingText="No Options"
                                                          id="year"
                                                          freeSolo
                                                          margin="normal"
                                                          options={yearData}
                                                          onChange={handleYearChange}
                                                          onInputChange={handleYearInputChange}
                                                          getOptionLabel={(option) =>
                                                              option.name ? option.name : ""
                                                          }
                                                          renderInput={(params) => (
                                                              <TextField
                                                                  {...params}
                                                                  label="Fiscal Year"
                                                                  variant="filled"
                                                              />
                                                          )}
                                                      />
                                                  </Grid>

                                                  <Grid item xs={10}>
                                                      <MTableToolbar {...props} />
                                                  </Grid>
                                              </Grid>
                                          </div>
                                      );
                                  },
                              }}
                             
                          />}
            <Dialog
              open={openDialog}
              onClose={handleCloseDialog}
              fullWidth
              maxWidth="md"
              aria-labelledby="form-dialog-title"
            >
              <DialogContent>
                <GckInputForm
                  openDialog={openDialog}
                  handleCloseDialog={handleCloseDialog}
                  rowData={rowData}
                  tableData={tableData}
                  handleSubmit={handleSubmit}
                  handleUpdateData={handleUpdateData}
                  handleAddData={handleAddData}
                  yearInput={yearInput}
                  yearId={yearId}
                  bunitData={bunitData.find(
                    (x) => x.value === rowData?.bunitId
                  )}
                  bsegmentData={bsegmentData.find(
                    (x) => x.value === rowData?.bsegmentId
                  )}
                  bsubsegmentData={bsubsegmentData.find(
                    (x) => x.value === rowData?.bsubSegmentId
                  )}
                />
              </DialogContent>
            </Dialog>
            <>
              <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleClose}
              />
            </>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
