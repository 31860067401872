import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import MaterialTable from "material-table";
import { tableIcons } from "../../../../Utilities/Utility";
import { tableOptionsUser } from "../../../../Themes/LightTheme";
import { useSelector } from "react-redux";
import SnackbarCustom from "../../../../Components/Snackbar/Snackbar";
import * as XLSX from "xlsx";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import {
  getBusinessUnitData,
  addBusinessUnitData,
  updateBusinessUnitData,
  deleteBusinessUnitData,
} from "../../../../Redux/APIs/api_MasterData";

export default function BusinessUnitMaster(props) {
  const tableRef = useRef();
  const icon = () => {
    return (
      <GetAppRoundedIcon
        style={{ fontSize: 53, color: `var(--btn-primary-bg-color)` }}
      />
    );
  };
  const token = useSelector((state) => state.saveTokenReducer.token);
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
  const [tableData, setTableData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpen(true);
  };
  const handleClose = (e) => {
    setOpen(false);
  };
  function isBunitExists(value) {
    const obj = tableData.find((c) => c.businessUnit == value);
    return obj ? true : false;
  }
  function isBunitExists(value, id) {
    const obj = tableData.find((c) => c.businessUnit == value);
    return obj && obj.businessUnitId !== id ? true : false;
  }
  function validateBunit(value, id) {
    return !value
      ? { isValid: false, helperText: "*Required" }
      : (!id && isBunitExists(value)) || (id && isBunitExists(value, id))
      ? { isValid: false, helperText: "Already exists" }
      : { isValid: true, helperText: "" };
  }
  const columns = [
    { title: "BusinessUnit Id", field: "businessUnitId", hidden: true },
    {
      title: "Business Unit",
      field: "businessUnit",
      validate: (rowData) =>
        validateBunit(rowData.businessUnit, rowData.businessUnitId),
    },
  ];
  const actions = [
    {
      icon: icon,
      tooltip: "Export to Excel",
      onClick: () => ExportToExcel(),
      isFreeAction: true,
    },
  ];
  function getTableData() {
    setIsLoader(true);
      getBusinessUnitData(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId)
      .then((response) => {
        setTableData(response);
        setIsLoader(false);
      })
      .catch((error) => {
        handleSnackOpen("Error : " + error, "error");
        setIsLoader(false);
      });
  }
  useEffect(() => {
    getTableData();
  }, [userDetails]);
  
  useEffect(()=>{
       getTableData();
  },[props.gckSegments])

  const handleRowUpdate = (newTableData, oldTableData, resolve, reject) => {
    setIsLoader(true);
    newTableData.loggedinUsesrId = userDetails.userId;
      updateBusinessUnitData(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId, newTableData)
      .then((response) => {
        if (response.response) {
          handleSnackOpen(response.responseMsg, "success");
          resolve();
          setIsLoader(false);
          getTableData();
        } else {
          handleSnackOpen(response.responseMsg, "error");
          reject();
          setIsLoader(false);
        }
      })
      .catch((error) => {
        handleSnackOpen("Exception : " + error, "error");
        reject();
        setIsLoader(false);
      });
      
  };
  const handleRowAdd = (newTableData, resolve, reject) => {
    setIsLoader(true);
    newTableData.loggedinUserId = userDetails.userId;
      addBusinessUnitData(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId, newTableData)
      .then((response) => {
        if (response.response) {
          handleSnackOpen(response.responseMsg, "success");
          resolve();
          setIsLoader(false);
          getTableData();
        } else {
          handleSnackOpen(response.responseMsg, "error");
          reject();
          setIsLoader(false);
        }
      })
      .catch((error) => {
        handleSnackOpen("Exception : " + error, "error");
        reject();
        setIsLoader(false);
      });
     
  };
  const handleRowDelete = (oldTableData, resolve, reject) => {
    setIsLoader(true);
    oldTableData.loggedinUserId = userDetails.userId;
      deleteBusinessUnitData(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId, oldTableData)
      .then((response) => {
        if (response.response) {
          handleSnackOpen(response.responseMsg, "success");
          resolve();
          setIsLoader(false);
          getTableData();
        } else {
          handleSnackOpen(response.responseMsg, "error");
          reject();
          setIsLoader(false);
        }
      })
      .catch((error) => {
        handleSnackOpen("Exception : " + error, "error");
        reject();
        setIsLoader(false);
      });
     
  };
  function ExportToExcel() {
    const dataToDownload = tableRef?.current?.dataManager?.searchedData.map(
      (row) => {
        return {
          businessUnit: row.businessUnit,
        };
      }
    );
    let headers = ["Business Unit"];
    //const workSheet = XLSX.utils.json_to_sheet(newData);
    let ws = XLSX.utils.book_new();
    let wb = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, [headers]);
    XLSX.utils.sheet_add_json(ws, dataToDownload, {
      origin: "A2",
      skipHeader: true,
      cellStyles: true,
    });
    ws["!cols"] = cellAdjustToContents(dataToDownload);
    XLSX.utils.book_append_sheet(wb, ws, "Business Unit");
    XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(wb, "BusinessUnitMaster.xlsx");
  }
  function cellAdjustToContents(data) {
    return [
      {
        wch: Math.max(
          ...data?.map((c) =>
            c.businessUnit ? c.businessUnit?.toString()?.length : 0
          )
        ),
      },
    ];
  }
  function getDisableTooltip(gck,bs,bss,type) {
    return (gck===true && bs===true && bss===true) 
      ? "BusinessUnit is already Associated with Gck & Business Segment & Business SubSegment"
      :  (gck===true && bs===false && bss===true)
      ? "BusinessUnit is already Associated with Gck & Business SubSegment"
      :  (gck===true  && bs===true && bss===false)
      ? "BusinessUnit is already Associated with Gck & Business Segment"
      :  (gck===true  && bs===false&& bss===false )
      ? "BusinessUnit is already Associated with Gck"
      :  (gck===false && bs===true &&bss===true)
      ? "Business unit is already Associated with Business Segment & Business SubSegemnt"
      :  (gck===false && bs===true &&bss===false)
      ? "Business unit is already Associated with Business Segment"
      :  (gck===false && bs===false &&bss===true)
      ? "Business unit is already Associated with Business SubSegment"
      :  type === "Edit"
      ? "Edit"
      : "Delete";
  }
  return (
    <div>
      <div className="App">
        <Grid container spacing={1}>
                  <Grid item xs={12}>
                      {userDetails.roleId !== 10 ? <MaterialTable
                          tableRef={tableRef}
                          title="Customer Master"
                          columns={columns}
                          data={tableData}
                          isLoading={isLoader}
                          icons={tableIcons}
                          options={tableOptionsUser}
                          actions={actions}
                          editable={{
                              isEditable: (rowdata) => !(rowdata.isreadonlybunitgck || rowdata.isreadonlybunitsegment || rowdata.isreadonlybunitsubsegmnet),
                              editTooltip: (rowdata) =>
                                  getDisableTooltip(rowdata.isreadonlybunitgck, rowdata.isreadonlybunitsegment, rowdata.isreadonlybunitsubsegmnet, "Edit"),
                              isDeletable: (rowdata) => !(rowdata.isreadonlybunitgck || rowdata.isreadonlybunitsegment || rowdata.isreadonlybunitsubsegmnet),
                              deleteTooltip: (rowdata) =>
                                  getDisableTooltip(rowdata.isreadonlybunitgck, rowdata.isreadonlybunitsegment, rowdata.isreadonlybunitsubsegmnet, "Delete"),
                              onRowUpdate: (newTableData, oldTableData) =>
                                  new Promise((resolve, reject) => {
                                      handleRowUpdate(
                                          newTableData,
                                          oldTableData,
                                          resolve,
                                          reject
                                      );
                                  }),
                              onRowAdd: (newTableData) =>
                                  new Promise((resolve, reject) => {
                                      handleRowAdd(newTableData, resolve, reject);
                                  }),
                              onRowDelete: (oldTableData) =>
                                  new Promise((resolve, reject) => {
                                      handleRowDelete(oldTableData, resolve, reject);
                                  }),
                          }}
                      /> : <MaterialTable
                          tableRef={tableRef}
                          title="Customer Master"
                          columns={columns}
                          data={tableData}
                          isLoading={isLoader}
                          icons={tableIcons}
                          options={tableOptionsUser}
                          actions={actions}/>}
            
            <>
              <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleClose}
              />
            </>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
