import React, { useState, useEffect,useRef } from "react";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import { tableIcons } from "../../../../Utilities/Utility";
import { tableOptionsUser } from "../../../../Themes/LightTheme";
import { useSelector } from "react-redux";
import SnackbarCustom from "../../../../Components/Snackbar/Snackbar";
import * as XLSX from 'xlsx';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import { getSalesTeritoryData, addSalesTeritoryData, updateSalesTeritoryData, deleteSalesTeritoryData } from "../../../../Redux/APIs/api_MasterData";
export default function STeritoryMaster() {
    const tableRef = useRef();
    const icon=()=>{
        return(
        <GetAppRoundedIcon  style={{ fontSize: 53,color:`var(--btn-primary-bg-color)` }}/>
        );
    }
    const columns = [
        { title: 'Sales TeritoryId', field: 'salesTeritoryId', hidden: true },
        { title: 'Sales Teritory',
         field: 'salesTeritory',
         validate: (rowData) =>
         validateSTeritory(rowData.salesTeritory, rowData.salesTeritoryId),
        },
    ];
    const actions=  [
        {
        icon: icon,
        tooltip:"Export to Excel",
        onClick:()=>ExportToExcel(),
        isFreeAction:true
     },
    ]
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const [tableData, setTableData] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpen(false);
    };
    function isSTeritoryExists(value) {
        const obj = tableData.find((c) => c.salesTeritory== value);
        return obj ? true : false;
      }
      function isSTeritoryExists(value, id) {
        const obj = tableData.find((c) => c.salesTeritory == value);
        return obj && obj.salesTeritoryId !== id ? true : false;
      }
      function validateSTeritory(value, id) {
        return !value
          ? { isValid: false, helperText: "*Required" }
          : (!id && isSTeritoryExists(value)) || (id && isSTeritoryExists(value, id))
          ? { isValid: false, helperText: "Already exists" }
          : { isValid: true, helperText: "" };
      }
    function getTableData() {
        setIsLoader(true);
        getSalesTeritoryData(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {
                setTableData(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    useEffect(() => {

        getTableData();
    }, [userDetails])


    const handleRowUpdate = (newTableData, oldTableData, resolve, reject) => {
        setIsLoader(true);
        newTableData.loggedinUserId = userDetails.userId;
        updateSalesTeritoryData(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, newTableData)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableData();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                reject();
                setIsLoader(false);
            });
    };
    const handleRowAdd = (newTableData, resolve, reject) => {
        setIsLoader(true);
        newTableData.loggedinUserId = userDetails.userId;
        addSalesTeritoryData(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, newTableData)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableData();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                reject();
                setIsLoader(false);
            });
    };
    const handleRowDelete = (oldTableData, resolve, reject) => {
        setIsLoader(true);
        oldTableData.loggedinUserId = userDetails.userId;
        deleteSalesTeritoryData(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, oldTableData)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableData();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                reject();
                setIsLoader(false);
            });
    };
    function ExportToExcel() {
        const dataToDownload = tableRef?.current?.dataManager?.searchedData.map(
          (row) => {
            return {
              salesTeritory:row.salesTeritory
            };
          }
        );
        let headers = [
          "Sales Teritory",
          
        ];
        //const workSheet = XLSX.utils.json_to_sheet(newData);
        let ws = XLSX.utils.book_new();
        let wb = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(ws, [headers]);
        XLSX.utils.sheet_add_json(ws, dataToDownload, {
          origin: "A2",
          skipHeader: true,
          cellStyles: true,
        });
        ws["!cols"] = cellAdjustToContents(dataToDownload);
        XLSX.utils.book_append_sheet(wb, ws, "Sales Teritory");
        XLSX.write(wb, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(wb, "SalesTeritoryMaster.xlsx");
      }
      function cellAdjustToContents(data) {
        return [
          {
            wch: Math.max(
              ...data?.map((c) =>
                c.salesTeritory ? c.salesTeritory?.toString()?.length : 0
              )
            ),
          },
         
        ];
      }
      function getDisableTooltip(value,type) {
        return value === true
          ? "Sales Teritory is already Associated with salesOrders"
          : type === "Edit"
          ? "Edit"
          : "Delete";
      }
    return (
        <div>
            <div className="App">
                <Grid container spacing={1}>
                    
                    <Grid item xs={12}>
                        {userDetails.roleId !== 10 ?
                        <MaterialTable
                        tableRef={tableRef}
                            columns={columns}
                            data={tableData}
                            isLoading={isLoader}
                            icons={tableIcons}
                            options={tableOptionsUser}
                            actions={actions}
                            editable={{
                                isEditable: (rowdata) => !rowdata.isreadonlySteritory,
                                editTooltip: (rowdata) => getDisableTooltip(rowdata.isreadonlySteritory, "Edit"),
                                isDeletable: (rowdata) => !rowdata.isreadonlySteritory, 
                                 deleteTooltip: (rowdata) =>  getDisableTooltip(rowdata.isreadonlySteritory ,"Delete"),
                                onRowUpdate: (newTableData, oldTableData) =>
                                    new Promise((resolve, reject) => {
                                        handleRowUpdate(
                                            newTableData,
                                            oldTableData,
                                            resolve,
                                            reject
                                        );
                                    }),
                                onRowAdd: (newTableData) =>
                                    new Promise((resolve, reject) => {
                                        handleRowAdd(newTableData, resolve, reject);
                                    }),
                                onRowDelete: (oldTableData) =>
                                    new Promise((resolve, reject) => {
                                        handleRowDelete(oldTableData, resolve, reject);
                                    }),
                            }}
                            />:<MaterialTable
                                tableRef={tableRef}
                                columns={columns}
                                data={tableData}
                                isLoading={isLoader}
                                icons={tableIcons}
                                options={tableOptionsUser}
                                //actions={actions}
                               
                            />}
                        <>
                            <SnackbarCustom
                                open={open}
                                message={snackMessage}
                                alertType={alertType}
                                handleClose={handleClose}
                            />
                        </>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}