import { baseApiURL } from "../../Utilities/Utility";


export function CustomerUpload(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData) {
    const options = {
      method: "post",
      mode: "cors",
      headers: {
          Authorization: "Bearer " + siteToken.token,
          AzureToken: azureToken,
          UserEmail: userEmail,
          RoleName: roleName,
          Gid: gid,
          Roleid: roleId
      },
      body: formData,
    };
    return fetch(baseApiURL + "/Upload/UploadCustomerMasterfile?encUserId=" + encodeURIComponent(userId), options)
      .then((response) => response.json())
      .then((data) => {
        return data.result;
      })
      .catch((error) => {
        //return { response: false, responseMsg: error };
        console.log("Error : ", error);
      });
  }

export function GCKUpload(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData) {
    const options = {
      method: "post",
      mode: "cors",
      headers: {
          Authorization: "Bearer " + siteToken.token,
          AzureToken: azureToken,
          UserEmail: userEmail,
          RoleName: roleName,
          Gid: gid,
          Roleid: roleId,
      },
      body: formData,
    };
    return fetch(baseApiURL + "/Upload/UploadGCKMasterfile?encUserId=" + encodeURIComponent(userId), options)
      .then((response) => response.json())
      .then((data) => {
        return data.result;
      })
      .catch((error) => {
        //return { response: false, responseMsg: error };
        console.log("Error : ", error);
      });
  }

export function MonthlyUpload(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData) {
    const options = {
      method: "post",
      mode: "cors",
      headers: {
          Authorization: "Bearer " + siteToken.token,
          AzureToken: azureToken,
          UserEmail: userEmail,
          RoleName: roleName,
          Gid: gid,
          Roleid: roleId,
      },
      body: formData,
    };
    return fetch(baseApiURL + "/Upload/UploadMonthlyActualsData?encUserId=" + encodeURIComponent(userId), options)
      .then((response) => response.json())
      .then((data) => {
        return data.result;
      })
      .catch((error) => {
        //return { response: false, responseMsg: error };
        console.log("Error : ", error);
      });
  }
export function PotentialBudget(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData
) {
    const options = {
      method: "post",
      mode: "cors",
      headers: {
          Authorization: "Bearer " + siteToken.token,
          AzureToken: azureToken,
          UserEmail: userEmail,
          RoleName: roleName,
          Gid: gid,
          Roleid: roleId,
      },
      body: formData,
    };
    return fetch(baseApiURL + "/Upload/UploadedPotentialsData?encUserId=" + encodeURIComponent(userId), options)
      .then((response) => response.json())
      .then((data) => {
        return data.result;
      })
      .catch((error) => {
        //return { response: false, responseMsg: error };
        console.log("Error : ", error);
      });
  }
