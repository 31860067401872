
import React, { useEffect, useState } from "react";
import {
    Grid,
    ThemeProvider,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { lightTheme } from "../../../Themes/LightTheme";
import SBranchMaster from "./MasterStructure/SBranchMaster";
import STeritoryMaster from "./MasterStructure/STeritoryMaster";
import SOfficeMaster from "./MasterStructure/SOfficeMaster";
import SEngineerMaster from "./MasterStructure/SEngineerMaster";
import SDepartmentMaster from "./MasterStructure/SDepartmentMaster";
import { getSalesBranchDDL, getSalesDepartmentDDL, getSalesOfficeDDL, getSalesTeritoryDDL } from "../../../Redux/APIs/api_Common";


export default function TeritoryStructure() {
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    //Api
    let [steritoryData, setSTeritoryData] = useState([]);
    async function getSalesTeritoryDetails() {
        const response = await getSalesTeritoryDDL(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId);
        if (response && response.length > 0) {
            setSTeritoryData(
                response.map((item) => ({ value: item.id, label: item.name }))

            );
        } else {
            setSTeritoryData([]);
        }
    }
    let [sbranchData, setSBranchData] = useState([]);
    async function getSalesBranchDetails() {
        const response = await getSalesBranchDDL(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId);
        if (response && response.length > 0) {
            setSBranchData(
                response.map((item) => ({ value: item.id, label: item.name }))

            );
        } else {
            setSBranchData([]);
        }
    }
    let [sofficeData, setSOfficeData] = useState([]);
    async function getSalesOfficeDetails() {
        const response = await getSalesOfficeDDL(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId);
        if (response && response.length > 0) {
            setSOfficeData(
                response.map((item) => ({ value: item.id, label: item.name }))

            );
        } else {
            setSOfficeData([]);
        }
    }

    let [sdepartmentData, setSdepartmentData] = useState([]);
    async function getSalesdepartmentDetails() {
        const response = await getSalesDepartmentDDL(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId);
        if (response && response.length > 0) {
            setSdepartmentData(
                response.map((item) => ({ value: item.id, label: item.name }))

            );
        } else {
            setSdepartmentData([]);
        }
    }

    //render value
    function renderSTeritoryValue(st) {
        let obj = {};
        if (steritoryData && steritoryData.length > 0) {
            obj = steritoryData.find((c) => c.value == st);
        }
        if (obj) {
            return obj.label;
        } else {
            return "";
        }
    }

    function renderSBranchValue(sb) {
        let obj = {};
        if (sbranchData && sbranchData.length > 0) {
            obj = sbranchData.find((c) => c.value == sb);

        }
        if (obj) {
            return obj.label;
        } else {
            return "";
        }
    }

    function renderSOfficeValue(so) {
        let obj = {};
        if (sofficeData && sofficeData.length > 0) {
            obj = sofficeData.find((c) => c.value == so);

        }
        if (obj) {
            return obj.label;
        } else {
            return "";
        }
    }
    function renderSDepartmentValue(so) {
        let obj = {};
        if (sdepartmentData && sdepartmentData.length > 0) {
            obj = sdepartmentData.find((c) => c.value == so);

        }
        if (obj) {
            return obj.label;
        } else {
            return "";
        }
    }
    useEffect(() => {
        getSalesOfficeDetails();
        getSalesBranchDetails();
        getSalesTeritoryDetails();
        getSalesdepartmentDetails()
    }, [])
    return (
        <div>
            <ThemeProvider theme={lightTheme}>

                <>
                    <div className="master-paperSty">
                        <div className="master-data">
                            <Grid container spacing={2} className="master-div">
                                <Grid item xs={12} md={5} className="d-flex fd-col pd-1">
                                    <h4 className="d-flex jc-center">Sales Teritory</h4>
                                    <STeritoryMaster />
                                </Grid>
                                <Grid item xs={12} md={7} className="d-flex fd-col pd-1">
                                    <h4 className="d-flex jc-center">Sales Department</h4>
                                    <SDepartmentMaster
                                        steritoryData={steritoryData}
                                        sbranchData={sbranchData}
                                        sofficeData={sofficeData}
                                        renderSTeritoryValue={renderSTeritoryValue}
                                        renderSBranchValue={renderSBranchValue}
                                        renderSOfficeValue={renderSOfficeValue}
                                        getSalesOfficeDetails={getSalesOfficeDetails}
                                        getSalesBranchDetails={getSalesBranchDetails}
                                        getSalesTeritoryDetails={getSalesTeritoryDetails}
                                    />
                                </Grid>
                                <Grid item xs={12} md={5} className="d-flex fd-col pd-1">
                                    <h4 className="d-flex jc-center">Sales Branch</h4>
                                    <SBranchMaster />
                                </Grid>

                                <Grid item xs={12} md={7} className="d-flex fd-col pd-1">
                                    <h4 className="d-flex jc-center">Sales Office</h4>
                                    <SOfficeMaster
                                        steritoryData={steritoryData}
                                        sbranchData={sbranchData}
                                        renderSTeritoryValue={renderSTeritoryValue}
                                        renderSBranchValue={renderSBranchValue}
                                        getSalesBranchDetails={getSalesBranchDetails}
                                        getSalesTeritoryDetails={getSalesTeritoryDetails}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12} className="d-flex center fd-col pd-1">
                                    <h4 className="d-flex jc-center">Sales District</h4>
                                    <SEngineerMaster
                                        steritoryData={steritoryData}
                                        sbranchData={sbranchData}
                                        sofficeData={sofficeData}
                                        sdepartmentData={sdepartmentData}
                                        renderSTeritoryValue={renderSTeritoryValue}
                                        renderSBranchValue={renderSBranchValue}
                                        renderSOfficeValue={renderSOfficeValue}
                                        renderSDepartmentValue={renderSDepartmentValue}
                                        getSalesOfficeDetails={getSalesOfficeDetails}
                                        getSalesBranchDetails={getSalesBranchDetails}
                                        getSalesTeritoryDetails={getSalesTeritoryDetails}
                                        getSalesdepartmentDetails={getSalesdepartmentDetails}
                                    />
                                </Grid>


                            </Grid>
                        </div>
                    </div>
                </>

            </ThemeProvider>
        </div>
    );
}