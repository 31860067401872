import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import {
  getCurrentFiscalYear,
  getRoundUptoXDecimals,
} from "../../../Utilities/Utility";
import { getSalesOrderInputData } from "../../../Redux/APIs/api_OrderInputDetails";

export default function Dashboard(props) {
  const token = useSelector((state) => state.saveTokenReducer.token);
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  const updateResponse = useSelector(
    (state) => state.updateResponseReducer.response
    );
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
  let [isLoader, setIsLoader] = useState(true);
  let [currentFY, setCurrentFY] = useState("");
  let [sowBudget, setSowBudget] = useState(0.0);
  let [sowActuals, setSowActuals] = useState(0.0);
  let [achBudget, setAchBudget] = useState(0.0);
  let [potentialGrowth, setPotentialGrowth] = useState(0.0);
  let [budgetGrowth, setBudgetGrowth] = useState(0.0);

  function getProgressComponent() {
    return <CircularProgress style={{ height: `15px`, width: `15px` }} />;
  }

  function getSOWBudget(data) {
    const currBudObj = data.budgetDataList.find(
      (x) => x.yearId === data.yearId
    );
    let totalBud = currBudObj.budgetDirect + currBudObj.budgetIndirect;
    const currPotObj = data.potentialDataList.find(
      (x) => x.yearId === data.yearId
    );
    let totalPot = currPotObj.potentialDirect + currPotObj.potentialIndirect;
    setSowBudget(
      totalPot > 0 ? getRoundUptoXDecimals((totalBud / totalPot) * 100, 0) : 0
    );
  }
  function getSOWActuals(data) {
    let totalActuals = 0.0;
    data.monthlyViewDataList.map((x) => {
      totalActuals =
        totalActuals +
        x.directCurrent +
        x.o2OIndirectCurrent +
        x.manualIndirectCurrent;
      return x;
    });
    const currPotObj = data.potentialDataList.find(
      (x) => x.yearId === data.yearId
    );
    let totalPot = currPotObj.potentialDirect + currPotObj.potentialIndirect;
    setSowActuals(
      totalPot > 0
        ? getRoundUptoXDecimals((totalActuals / totalPot) * 100, 0)
        : 0
    );
  }
  function getAchievementAgainstBudget(data) {
    let totalActuals = 0.0;
    data.monthlyViewDataList.map((x) => {
      totalActuals =
        totalActuals +
        x.directCurrent +
        x.o2OIndirectCurrent +
        x.manualIndirectCurrent;
      return x;
    });
    const currBudObj = data.budgetDataList.find(
      (x) => x.yearId === data.yearId
    );
    let totalBud = currBudObj.budgetDirect + currBudObj.budgetIndirect;
    setAchBudget(
      totalBud > 0
        ? getRoundUptoXDecimals((totalActuals / totalBud) * 100, 0)
        : 0
    );
  }
  function getPotentialGrowth(data) {
    let currTotalPot = 0.0,
      prevTotalPot = 0.0;
    data.potentialDataList.map((x) => {
      if (x.yearId === data.yearId) {
        currTotalPot = currTotalPot + x.potentialDirect + x.potentialIndirect;
      } else {
        prevTotalPot = prevTotalPot + x.potentialDirect + x.potentialIndirect;
      }
      return x;
    });
    setPotentialGrowth(
      prevTotalPot > 0
        ? getRoundUptoXDecimals((currTotalPot / prevTotalPot - 1) * 100, 0)
        : 0
    );
  }
  function getBudgetGrowth(data) {
    let currTotalBud = 0.0,
      prevTotalBud = 0.0;
    data.budgetDataList.map((x) => {
      if (x.yearId === data.yearId) {
        currTotalBud = currTotalBud + x.budgetDirect + x.budgetIndirect;
      } else {
        prevTotalBud = prevTotalBud + x.budgetDirect + x.budgetIndirect;
      }
      return x;
    });
    setBudgetGrowth(
      prevTotalBud > 0
        ? getRoundUptoXDecimals((currTotalBud / prevTotalBud - 1) * 100, 0)
        : 0
    );
  }
  function setInitState() {
    setCurrentFY(getCurrentFiscalYear());
    setSowBudget(0);
    setSowActuals(0);
    setAchBudget(0);
    setPotentialGrowth(0);
    setBudgetGrowth(0);
    setIsLoader(false);
  }
  function getDataOnLoad(filters) {
    setIsLoader(true);
      getSalesOrderInputData(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId, filters)
      .then((response) => {
        if (response.response) {
          const obj = response.objResult;
          setCurrentFY(obj.year);
          getSOWBudget(obj);
          getSOWActuals(obj);
          getAchievementAgainstBudget(obj);
          getPotentialGrowth(obj);
          getBudgetGrowth(obj);
          setIsLoader(false);
        } else {
          setInitState();
        }
      })
      .catch((error) => {
        console.log("Error : ", error);
        setIsLoader(false);
      });
  }
  useEffect(() => {
    if (props) {
      getDataOnLoad(props.filters);
    }
  }, [props, updateResponse]);
  return (
    <div className="dashboard-block">
      <h4 className="text-center">
        Business Summary{" "}
        {isLoader ? getProgressComponent() : "(" + currentFY + ")"}
      </h4>
      <p className="dashboard-text-block d-flex jc-space-bt">
        <span>Share of Wallet-Budget : </span>
        {isLoader ? (
          getProgressComponent()
        ) : (
          <span
            className=""
            style={{
              color:
                Math.sign(sowBudget) === -1
                  ? `var(--red)`
                  : Math.sign(sowBudget) === 1
                  ? `var(--petrol-green)`
                  : `var(--dark-blue)`,
            }}
          >
            {sowBudget} %
          </span>
        )}
      </p>
      <p className="dashboard-text-block d-flex jc-space-bt">
        <span>Share of Wallet-Actuals : </span>
        {isLoader ? (
          getProgressComponent()
        ) : (
          <span
            className=""
            style={{
              color:
                Math.sign(sowActuals) === -1
                  ? `var(--red)`
                  : Math.sign(sowActuals) === 1
                  ? `var(--petrol-green)`
                  : `var(--dark-blue)`,
            }}
          >
            {sowActuals} %
          </span>
        )}
      </p>
      <p className="dashboard-text-block d-flex jc-space-bt">
        <span>Ach. Against Budget : </span>
        {isLoader ? (
          getProgressComponent()
        ) : (
          <span
            className=""
            style={{
              color:
                Math.sign(achBudget) === -1
                  ? `var(--red)`
                  : Math.sign(achBudget) === 1
                  ? `var(--petrol-green)`
                  : `var(--dark-blue)`,
            }}
          >
            {achBudget} %
          </span>
        )}
      </p>
      <p className="dashboard-text-block d-flex jc-space-bt">
        <span>Potential Growth : </span>
        {isLoader ? (
          getProgressComponent()
        ) : (
          <span
            className=""
            style={{
              color:
                Math.sign(potentialGrowth) === -1
                  ? `var(--red)`
                  : Math.sign(potentialGrowth) === 1
                  ? `var(--petrol-green)`
                  : `var(--dark-blue)`,
            }}
          >
            {potentialGrowth} %
          </span>
        )}
      </p>
      <p className="dashboard-text-block d-flex jc-space-bt">
        <span>Budget Growth Plan : </span>
        {isLoader ? (
          getProgressComponent()
        ) : (
          <span
            className=""
            style={{
              color:
                Math.sign(budgetGrowth) === -1
                  ? `var(--red)`
                  : Math.sign(budgetGrowth) === 1
                  ? `var(--petrol-green)`
                  : `var(--dark-blue)`,
            }}
          >
            {budgetGrowth} %
          </span>
        )}
      </p>
    </div>
  );
}
