import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import MaterialTable, { MTableToolbar } from "material-table";
import {
    tableIcons,
    getCurrentFiscalYear,
} from "../../../../Utilities/Utility";
import { tableOptionsUser } from "../../../../Themes/LightTheme";
import { FormHelperText } from "@material-ui/core";
import { useSelector } from "react-redux";
import SnackbarCustom from "../../../../Components/Snackbar/Snackbar";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { FormControl } from "@material-ui/core";
import Select from "react-select";
import { theme, customStylesYear, customStylesDefault } from "../../../../Utilities/Utility";
import * as XLSX from "xlsx";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import { getYearDDL, getRoleDDL } from "../../../../Redux/APIs/api_Common";
import {
    getAllCustomerDatayear,
    getCustomerData,
    addCustomerData,
    updateCustomerData,
    deleteCustomerData,
    getFinYearData,
} from "../../../../Redux/APIs/api_MasterData";

export default function CustomerMaster() {
    const tableRef = useRef();
    const icon = () => {
        return (
            <GetAppRoundedIcon
                style={{ fontSize: 53, color: `var(--btn-primary-bg-color)` }}
            />
        );
    };

    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const [roleData, setRoleData] = useState([]);
    //Api
    let [yearData, setYearData] = useState([]);
    let [yearId, setYearId] = useState(0);
    async function getYearDetails() {
        let year = getCurrentFiscalYear();
        const response = await getYearDDL(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId);
        if (response && response.length > 0) {
            setYearData(response.map((item) => ({ id: item.id, name: item.name })));
            let obj = response?.find((x) => x.name == year);
            getTableData(obj.id);
            setYearId(obj.id);
            setDefaultYearInput({
                year: obj,
                yearText: obj?.name,
            });
        } else {
            setYearData([]);
        }
    }

    let [defaultyearInput, setDefaultYearInput] = useState({
        year: {},
        yearText: "",
    });
    let [yearInput, setYearInput] = useState({
        yearId: 0,
    });

    const handleYearChange = (e, obj) => {
        setDefaultYearInput({
            year: obj,
            yearText: obj?.name,
        });
        setYearInput({
            yearId: obj?.id,
        });
        getTableData(obj?.id);
    };
    const handleYearInputChange = (e, text) => {
        setDefaultYearInput({
            yearText: text,
        });
        let yearId = yearData.find((x) => x.name === text)?.id;
        setYearInput({
            yearId: yearId ? yearId : 0,
        });
    };
    const [tableData, setTableData] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpen(false);
    };
    function isInteger(value) {
        const re = /^\d*$/;
        return re.test(value);
    }
    function isIFAExists(value) {
        const obj = tableData.find((c) => c.ifaNumber == value);
        return obj ? true : false;
    }
    function isIFAExists(value, id) {
        const obj = tableData.find((c) => c.ifaNumber == value);
        return obj && obj.customerId !== id ? true : false;
    }
    function validateCustomer(value) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : { isValid: true, helperText: "" };
    }
    function validateIFA(value, id) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : !isInteger(value)
                ? { isValid: false, helperText: "Only integers" }
                : (!id && isIFAExists(value)) || (id && isIFAExists(value, id))
                    ? { isValid: false, helperText: "Already exists" }
                    : { isValid: true, helperText: "" };
    }
    function validateRole(value) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : { isValid: true, helperText: "" };
    }
    function renderRoleValue(value) {
        let obj = {};
        if (roleData && roleData.length > 0) {
            obj = roleData.find((c) => c.value == value);
        }
        if (obj) {
            return obj.label;
        } else {
            return "";
        }
    }
    const columns = [
        { title: "CustomerId", field: "customerId", hidden: true },
        {
            title: "Customer Name",
            field: "customerName",
            validate: (rowData) => validateCustomer(rowData.customerName),
        },
        {
            title: "IFA Number",
            field: "ifaNumber",
            //editable: 'onAdd',
            validate: (rowData) => validateIFA(rowData.ifaNumber, rowData.customerId),
        },
        {
            title: "City",
            field: "city",
        },
        {
            title: "Role",
            field: "role",
            //validate: (rowData) => validateRole(rowData.roleId),
            //editComponent: (props) => getRoleComponent(props),
            //render: (rowData) => <span>{renderRoleValue(rowData.roleId)}</span>,
            //customFilterAndSearch: (term, rowData) =>
            //    renderRoleValue(rowData.roleId)
            //        .toLowerCase()
            //        .includes(term.toLowerCase()),
        },
        {
            title: "Industry",
            field: "industry"
        },
        {
            title: "Industry Segment",
            field: "industrySegment"
        }
    ];
    const actions = [
        {
            icon: icon,
            tooltip: "Export to Excel",
            onClick: () => ExportToExcel(),
            isFreeAction: true,
        },
    ];
    function getRoleComponent(objProps) {
        const Role = objProps.rowData.roleId
            ? roleData.find((x) => x.value === objProps.rowData.roleId)
            : null;
        return (
            <FormControl error={!objProps.value ? true : false} fullWidth>
                <Select
                    theme={theme}
                    options={roleData}
                    value={
                        typeof objProps.value === "string" ||
                            typeof objProps.value === "number"
                            ? Role
                            : objProps.value
                    }
                    onChange={(opt) => {
                        objProps.onChange(opt?.value);
                    }}
                    styles={customStylesDefault}
                    placeholder=" Role"
                    isClearable
                />
                <>
                    <FormHelperText>{!objProps.value ? "*Required" : ""}</FormHelperText>
                </>
            </FormControl>
        );
    }
    function getTableData(value) {
        setIsLoader(true);
        getAllCustomerDatayear(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, value)
            .then((response) => {
                console.log(response);
                setTableData(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    async function getRoleDetails() {
        const response = await getRoleDDL(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId);
        if (response && response.length > 0) {
            setRoleData(
                response.map((item) => ({ value: item.id, label: item.name }))
            );
        } else {
            setRoleData([]);
        }
    }
    useEffect(() => {
        setIsLoader(true);
        getYearDetails();
        getRoleDetails()
    }, [userDetails]);

    const handleRowUpdate = (newTableData, oldTableData, resolve, reject) => {
        setIsLoader(true);
        newTableData.yearId = yearInput.yearId ? yearInput.yearId : yearId;
        newTableData.loggedinUserId = userDetails.userId;
        updateCustomerData(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, newTableData)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableData(newTableData.yearId);
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                reject();
                setIsLoader(false);
            });
    };
    const handleRowAdd = (newTableData, resolve, reject) => {
        setIsLoader(true);
        newTableData.yearId = yearInput.yearId ? yearInput.yearId : yearId;
        console.log(newTableData.yearId);
        newTableData.loggedinUserId = userDetails.userId;
        addCustomerData(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, newTableData)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableData(newTableData.yearId);
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                reject();
                setIsLoader(false);
            });
    };
    const handleRowDelete = (oldTableData, resolve, reject) => {
        setIsLoader(true);
        oldTableData.loggedinUserId = userDetails.userId;
        deleteCustomerData(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, oldTableData)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableData();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                reject();
                setIsLoader(false);
            });
    };
    function ExportToExcel() {
        const dataToDownload = tableRef?.current?.dataManager?.searchedData.map(
            (row) => {
                return {
                    customerName: row.customerName,
                    ifaNumber: row.ifaNumber,
                    city: row.city,
                    role: row.role,
                    industry: row.industry,
                    industryseg: row.industrySegment,

                };
            }
        );
        let headers = ["Customer Name", "IFA Number", "City", "Role", "Industry", "Industry Segment"];
        //const workSheet = XLSX.utils.json_to_sheet(newData);
        let ws = XLSX.utils.book_new();
        let wb = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(ws, [headers]);
        XLSX.utils.sheet_add_json(ws, dataToDownload, {
            origin: "A2",
            skipHeader: true,
            cellStyles: true,
        });
        ws["!cols"] = cellAdjustToContents(dataToDownload);
        XLSX.utils.book_append_sheet(wb, ws, "Customer");
        XLSX.write(wb, { bookType: "xlsx", type: "binary" });
        //Download
        XLSX.writeFile(wb, "CustomerMaster.xlsx");
    }
    function cellAdjustToContents(data) {
        return [
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.customerName ? c.customerName?.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.ifaNumber ? c.ifaNumber?.toString()?.length : 0
                    )
                ),
            },
        ];
    }
    return (
        <div>
            <div className="App">
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        {userDetails.roleId !== 10 ?
                            <MaterialTable
                                tableRef={tableRef}
                                columns={columns}
                                data={tableData}
                                isLoading={isLoader}
                                icons={tableIcons}
                                options={tableOptionsUser}
                                actions={actions}
                                components={{
                                    Toolbar: (props) => {
                                        return (
                                            <div className="Table-margin">
                                                <Grid container spacing={1}>
                                                    <Grid item xs={2}>
                                                        <Autocomplete
                                                            disableClearable
                                                            fullWidth
                                                            name="YearId"
                                                            value={defaultyearInput.year}
                                                            inputValue={defaultyearInput?.yearText}
                                                            select="true"
                                                            loading
                                                            loadingText="No Options"
                                                            id="YearId"
                                                            freeSolo
                                                            margin="normal"
                                                            options={yearData}
                                                            onChange={handleYearChange}
                                                            onInputChange={handleYearInputChange}
                                                            getOptionLabel={(option) =>
                                                                option.name ? option.name : ""
                                                            }
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Fiscal Year"
                                                                    variant="filled"
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        <MTableToolbar {...props} />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        );
                                    },
                                }}
                                editable={{
                                    onRowUpdate: (newTableData, oldTableData) =>
                                        new Promise((resolve, reject) => {
                                            handleRowUpdate(
                                                newTableData,
                                                oldTableData,
                                                resolve,
                                                reject
                                            );
                                        }),
                                    onRowAdd: (newTableData) =>
                                        new Promise((resolve, reject) => {
                                            handleRowAdd(newTableData, resolve, reject);
                                        }),
                                    onRowDelete: (oldTableData) =>
                                        new Promise((resolve, reject) => {
                                            handleRowDelete(oldTableData, resolve, reject);
                                        }),
                                }}
                            /> : <MaterialTable
                                tableRef={tableRef}
                                columns={columns}
                                data={tableData}
                                isLoading={isLoader}
                                icons={tableIcons}
                                options={tableOptionsUser}
                                actions={actions}
                                components={{
                                    Toolbar: (props) => {
                                        return (
                                            <div className="Table-margin">
                                                <Grid container spacing={1}>
                                                    <Grid item xs={2}>
                                                        <Autocomplete
                                                            disableClearable
                                                            fullWidth
                                                            name="YearId"
                                                            value={defaultyearInput.year}
                                                            inputValue={defaultyearInput?.yearText}
                                                            select="true"
                                                            loading
                                                            loadingText="No Options"
                                                            id="YearId"
                                                            freeSolo
                                                            margin="normal"
                                                            options={yearData}
                                                            onChange={handleYearChange}
                                                            onInputChange={handleYearInputChange}
                                                            getOptionLabel={(option) =>
                                                                option.name ? option.name : ""
                                                            }
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Fiscal Year"
                                                                    variant="filled"
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        <MTableToolbar {...props} />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        );
                                    },
                                }}
                                
                            />}
                        <>
                            <SnackbarCustom
                                open={open}
                                message={snackMessage}
                                alertType={alertType}
                                handleClose={handleClose}
                            />
                        </>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}
