import { Switch, Route, Redirect } from "react-router-dom";
import Home from "../Screens/Home/Home";
import OrderList from "../Screens/OrderList/OrderList";
//import Sales from "../Screens/OrderList/Sales";
import Delegate from "../Screens/OrderList/Delegate";
import OrderInput from "../Screens/OrderInput/InputRoot";
import Uploads from "../Screens/Uploads/Uploads";
import UserManagement from "../Screens/Administrator/UserManagement/UserManagement";
import MasterData from "../Screens/MasterData/MasterData";
import DumpReports from "../../src/Screens/Reports/DumpReports";
import Error from "../../src/Components/Error/Error404"

import UnauthorizedAccess from "../Components/UnauthorizedAccess/UnauthorizedAccess";

export const MasterRoute = (
    <Switch>
        <Redirect from="/?Code*" to="/" />
        <Route exact path="/" render={() => <Home />} />
        <Route exact path="/userManagement" render={() => <UserManagement />} />
        <Route exact path="/masterData" render={() => <MasterData />} />
        <Route exact path="/orderList" render={() => <OrderList />} />
       
        <Route exact path="/delegate" render={() => <Delegate />} />
        <Route exact path="/orderInput" render={() => <OrderInput />} />
        <Route exact path="/uploads" render={() => <Uploads />} />
        <Route exact path="/dumpReports" render={() => <DumpReports />} />
        <Route render={() => <Error />} />

    </Switch>
);
export const BMRoute = (
    <Switch>
        <Redirect from="/?Code*" to="/" />
        <Route exact path="/" render={() => <Home />} />
        <Route exact path="/userManagement" render={() => <UserManagement />} />
        <Route exact path="/masterData" render={() => <MasterData />} />
        <Route exact path="/orderList" render={() => <OrderList />} />

        <Route exact path="/delegate" render={() => <Delegate />} />
        <Route exact path="/orderInput" render={() => <OrderInput />} />
        <Route exact path="/uploads" render={() => <Uploads />} />
        <Route exact path="/dumpReports" render={() => <DumpReports />} />
        <Route render={() => <Error />} />

    </Switch>
);
export const GLRoute = (
    <Switch>
        <Redirect from="/?Code*" to="/" />
        <Route exact path="/" render={() => <Home />} />
        
        <Route exact path="/orderList" render={() => <OrderList />} />

        
        <Route exact path="/orderInput" render={() => <OrderInput />} />
      
        <Route exact path="/dumpReports" render={() => <DumpReports />} />
        <Route render={() => <Error />} />

    </Switch>
);
export const SERoute = (
    <Switch>
        <Redirect from="/?Code*" to="/" />
        <Route exact path="/" render={() => <Home />} />

        <Route exact path="/orderList" render={() => <OrderList />} />


        <Route exact path="/orderInput" render={() => <OrderInput />} />

        <Route exact path="/dumpReports" render={() => <DumpReports />} />
        <Route render={() => <Error />} />

    </Switch>
);
export const OtherRoute = (
    <Switch>
        <Redirect from="/?Code*" to="/" />
        <Route exact path="/" render={() => <Home />} />
        <Route
            exact
            path="/UnauthorizedAccess"
            render={() => <UnauthorizedAccess />}
        />
        <Route render={() => <Error />} />
    </Switch>
);

