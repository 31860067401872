import { combineReducers } from "redux";

let tokenInitialState = {
  token: "",
};
const saveTokenReducer = (state = tokenInitialState, action) => {
  switch (action.type) {
    case "saveAccessToken": {
      return {
        token: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
};
let userDataInitialState = {
  userData: {
    gid: "",
    roleId: 0,
    roleName: "",
    userEmail: "",
    userFirstName: "",
    userLastName: "",
    userId: 0,
  },
};
const storeUserDetailsReducer = (
  state = userDataInitialState,
  { type, payLoad }
) => {
  switch (type) {
    case "storeUserData": {
      return {
        userData: {
          ...payLoad,
        },
      };
    }
    default: {
      return state;
    }
  }
};
let userInitialState = {
  gid: "",
};
const authUserReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case "myIDLogin": {
      return {
        gid: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
};
let isUpdateResponse = { response: 0 };
const updateResponseReducer = (state = isUpdateResponse, action) => {
  switch (action.type) {
    case "updatePotential": {
      return { response: action.payLoad + state.response };
    }
    case "updateBudget": {
      return { response: action.payLoad + state.response };
    }
    case "updateManualIndirect": {
      return { response: action.payLoad + state.response };
    }
    default:
      return state;
  }
};
let editInitialState = {
  editable: "false",
}
const isEditableReducer = (state = editInitialState, action) => {
  switch (action.type) {
    case "edit": {
      return {
        editable: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
};
let edit = {
    editables: "false",
}
const isEditReducer = (state = edit, action) => {
    switch (action.type) {
        case "edits": {
            return {
                editables: action.payLoad,
            };
        }
        default: {
            return state;
        }
    }
};
let encryptedId = {
    id: "",
};
const saveUserIdReducer = (state = encryptedId, action) => {
    switch (action.type) {
        case "encryptedId": {
            return {
                id: action.payLoad,
            };
        }
        default: {
            return state;
        }
    }
};
let azureToken = {
    token: "",
};
const saveAzureTokenReducer = (state = azureToken, action) => {
    switch (action.type) {
        case "saveAzureToken": {
            return {
                token: action.payLoad,
            };
        }
        default: {
            return state;
        }
    }
};
const storeMenuItems = [];
const storeMenuItemsReducer = (state = storeMenuItems, action) => {
    switch (action.type) {
        case "saveMenuItems": {
            return {
                menuItems: action.payLoad,
            };
        }
        default: {
            return state;
        }
    }
}
const storeLandingMenuItemsReducer = (state = storeMenuItems, action) => {
    switch (action.type) {
        case "saveLandingMenuItems": {
            return {
                menuItems: action.payLoad,
            };
        }
        default: {
            return state;
        }
    }
}
export const masterReducer = combineReducers({
  saveTokenReducer: saveTokenReducer,
  storeUserDetailsReducer: storeUserDetailsReducer,
  authUserReducer: authUserReducer,
  updateResponseReducer: updateResponseReducer,
    isEditableReducer: isEditableReducer,
    isEditReducer: isEditReducer,
    saveAzureTokenReducer: saveAzureTokenReducer,
    saveUserIdReducer: saveUserIdReducer,
    storeMenuItemsReducer: storeMenuItemsReducer,
    storeLandingMenuItemsReducer: storeLandingMenuItemsReducer
});
