import { baseApiURL } from "../../Utilities/Utility";
import axios from "axios";


export function GetSalesOrderPotentialsReport(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        responseType: "arraybuffer",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId, },
        
    };
    return axios
        .post(
            baseApiURL + "/Reports/GetSalesOrderPotentialsReport?encUserId=" + encodeURIComponent(userId),
            data,
            options
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });

}
//export function GetSalesOrderPotentialsReport(siteToken, data) {
//    const options = {
//        method: "get",
//        url: baseApiURL + "/Reports/GetSalesOrderPotentialsReport",
//        headers: {
//            //"Access-Control-Allow-Origin": "*",
//            Authorization: "Bearer " + siteToken,
//        },
//        params: { data:data },
//    };
//    return (
//        axios(options)
//            //.then((response) => response.json())
//            .then((response) => {
//                return response.data.result;
//            })
//            .catch((error) => {
//                console.log("Error : ", error);
//            })
//    );
//}

export function GetSalesOrderActualsReport(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        responseType: "arraybuffer",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId, },
       
    };
    return axios
        .post(
            baseApiURL + "/Reports/GetSalesOrderActualsReport?encUserId=" + encodeURIComponent(userId),
            data,
            options
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });

}
