import React, { useState, useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import { FormControl, FormHelperText } from "@material-ui/core";
import Select from "react-select";
import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import {
    tableIcons,
    theme,
    customStylesDefault,
} from "../../../Utilities/Utility";
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";
import {
    addUserDetails,
    deleteUserDetails,
    getUserDetails,
    updateUserDetails,
} from "../../../Redux/APIs/api_MasterData";
import { tableOptionsUser } from "../../../Themes/LightTheme";
import {
    getEngineerDDL,
    getSalesBranchDDL,
    getRoleDDL,
} from "../../../Redux/APIs/api_Common";
import * as XLSX from "xlsx";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import { TRUE } from "sass";

export default function UserManagement() {
    const tableRef = useRef();
    const icon = () => {
        return <GetAppRoundedIcon style={{ fontSize: 51 }} />;
    };
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const [tableData, setTableData] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpen(false);
    };
    const [salesDistrict, setSalesDistrict] = useState([]);
    const [salesBranch, setSalesBranch] = useState([]);
    const [roleData, setRoleData] = useState([]);
    //Api
    async function getSalesBranchDetails() {
        const response = await getSalesBranchDDL(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId);
        const branchItems = response.map((item) => ({
            value: item.id,
            label: item.name,
        }));
        setSalesBranch(branchItems);
    }

    async function getEngineerDetails() {
        const response = await getEngineerDDL(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId);
        if (response && response.length > 0) {
            setSalesDistrict(
                response.map((item) => ({ value: item.id, label: item.name }))
            );
        } else {
            setSalesDistrict([]);
        }
    }
    async function getRoleDetails() {
        const response = await getRoleDDL(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId);
        if (response && response.length > 0) {
            setRoleData(
                response.map((item) => ({ value: item.id, label: item.name }))
            );
        } else {
            setRoleData([]);
        }
    }
    //renderValue
    function renderDistrictValue(value) {
        let obj = {};
        if (salesDistrict && salesDistrict.length > 0) {
            obj = salesDistrict.find((c) => c.value == value);
        }
        if (obj) {
            return obj.label;
        } else {
            return "";
        }
    }
    function renderSalesBranchValue(value) {
        let obj = {};
        if (salesBranch && salesBranch.length > 0) {
            obj = salesBranch.find((c) => c.value == value);
        }
        if (obj) {
            return obj.label;
        } else {
            return "";
        }
    }
    function renderRoleValue(value) {
        let obj = {};
        if (roleData && roleData.length > 0) {
            obj = roleData.find((c) => c.value == value);
        }
        if (obj) {
            return obj.label;
        } else {
            return "";
        }
    }

    //Validations
    function isValidEmail(email) {
        const re =
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        return re.test(String(email).toLowerCase());
    }
    function isValidNameField(name) {
        const re = /^[a-zA-Z ]*$/;
        return re.test(name);
    }
    
    function gidLowerCase(value) {
        const isLowerCase = value === (String(value).toLowerCase())
        if (isLowerCase) {
            return false;
        }
        else {
            return true;
        }
    }
    function isEmailExists(value) {
        const obj = tableData.find((c) => c.userEmail === value);
        return obj ? true : false;
    }
    function isEmailExists(value, id) {
        const obj = tableData.find((c) => c.userEmail === value);
        return obj && obj.userId !== id ? true : false;
    }
    function isGIDExists(value) {
        const obj = tableData.find((c) => c.gid === value);
        return obj ? true : false;
    }
    function isGIDValid(value) {
        const regexValidation = /^[a-zA-Z0-9]+$/;
        return regexValidation.test(value);
        
    }
    function isGIDlength(value) {
        if (value.length !== 8) {
            return false;
        }
        else {
            return true;
        }
       

    }

    function isGIDExists(value, id) {
        const obj = tableData.find((c) => c.gid === value);
        return obj && obj.userId !== id ? true : false;
    }
    function validateRole(value) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : { isValid: true, helperText: "" };
    }

    function validateFirstName(value) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : !isValidNameField(value)
                ? { isValid: false, helperText: "Only alphabets" }
                : { isValid: true, helperText: "" };
    }
    function validateLastName(value) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : !isValidNameField(value)
                ? { isValid: false, helperText: "Only alphabets" }
                : { isValid: true, helperText: "" };
    }
    function validateEmail(data) {
        return !data.userEmail
            ? { isValid: false, helperText: "*Required" }
            : !isValidEmail(data.userEmail)
                ? { isValid: false, helperText: "Invalid Email" }
                : (!data.userId && isEmailExists(data.userEmail)) ||
                    (data.userId && isEmailExists(data.userEmail, data.userId))
                    ? { isValid: false, helperText: "Already exists" }
                    : { isValid: true, helperText: "" };
    }
    function validateGID(data) {
        return (!data.gid )
            ? { isValid: false, helperText: "*Required" }

            : !isGIDValid(data.gid)
                ? { isValid: false, helperText: "only alphabets and numbers" }
                : !isGIDlength(data.gid)
                    ? { isValid: false, helperText: "gid should be of 8 characters" }
            : (!data.userId && isGIDExists(data.gid)) ||
                (data.userId && isGIDExists(data.gid, data.userId))
                ? { isValid: false, helperText: "Already exists" }
                : { isValid: true, helperText: "" };
    }
    function validateSDistrict(data) {
        return !isValidSDistrictPerRoleId(data.roleId) && !data.sdistrictId
            ? { isValid: false, helperText: "*Required" }
            : { isValid: true, helperText: "" };
    }
    function validateSBranch(data) {
        return !isValidSBranchPerRoleId(data.roleId) && !data.sbranchId
            ? { isValid: false, helperText: "*Required" }
            : { isValid: true, helperText: "" };
    }
    function isValidSDistrictPerRoleId(value) {
        const arr = [1, 5];
        return arr.includes(value);
    }
    function isValidSBranchPerRoleId(value) {
        const arr = [1, 6];
        return arr.includes(value);
    }
    //EditComponent
    function getSalesBranchDDLComponent(objProps) {
        if (isValidSBranchPerRoleId(objProps.rowData.roleId)) {
            objProps.rowData.sbranchId = "";
        }
        const BranchData = objProps.rowData.sbranchId
            ? salesBranch.find((x) => x.value === objProps.rowData.sbranchId)
            : null;
        return (
            <FormControl
                error={
                    !isValidSBranchPerRoleId(objProps.rowData.roleId) && !objProps.value
                        ? true
                        : false
                }
                fullWidth
            >
                <Select
                    theme={theme}
                    options={salesBranch}
                    value={
                        typeof objProps.value === "string" ||
                            typeof objProps.value === "number"
                            ? BranchData
                            : objProps.value
                    }
                    isDisabled={isValidSBranchPerRoleId(objProps.rowData.roleId)}
                    onChange={(opt) => {
                        objProps.onChange(opt?.value);
                    }}
                    styles={customStylesDefault}
                    placeholder=" SalesBranch"
                    isClearable
                />
                <>
                    <FormHelperText>
                        {!isValidSBranchPerRoleId(objProps.rowData.roleId) &&
                            !objProps.value
                            ? "*Required"
                            : ""}
                    </FormHelperText>
                </>
            </FormControl>
        );
    }

    function getSDistrictComponent(objProps) {
        if (isValidSDistrictPerRoleId(objProps.rowData.roleId)) {
            objProps.rowData.sdistrictId = "";
        }
        const DistrictData = objProps.rowData.sdistrictId
            ? salesDistrict.find((x) => x.value === objProps.rowData.sdistrictId)
            : null;
        return (
            <FormControl
                error={
                    !isValidSDistrictPerRoleId(objProps.rowData.roleId) && !objProps.value
                        ? true
                        : false
                }
                fullWidth
            >
                <Select
                    theme={theme}
                    options={salesDistrict}
                    value={
                        typeof objProps.value === "string" ||
                            typeof objProps.value === "number"
                            ? DistrictData
                            : objProps.value
                    }
                    isDisabled={isValidSDistrictPerRoleId(objProps.rowData.roleId)}
                    onChange={(opt) => {
                        objProps.onChange(opt?.value);
                    }}
                    styles={customStylesDefault}
                    placeholder=" SalesDistrict"
                    isClearable
                />
                <>
                    <FormHelperText>
                        {!isValidSDistrictPerRoleId(objProps.rowData.roleId) &&
                            !objProps.value
                            ? "*Required"
                            : ""}
                    </FormHelperText>
                </>
            </FormControl>
        );
    }
    function getRoleComponent(objProps) {
        const Role = objProps.rowData.roleId
            ? roleData.find((x) => x.value === objProps.rowData.roleId)
            : null;
        return (
            <FormControl error={!objProps.value ? true : false} fullWidth>
                <Select
                    theme={theme}
                    options={roleData}
                    value={
                        typeof objProps.value === "string" ||
                            typeof objProps.value === "number"
                            ? Role
                            : objProps.value
                    }
                    onChange={(opt) => {
                        objProps.onChange(opt?.value);
                    }}
                    styles={customStylesDefault}
                    placeholder=" Role"
                    isClearable
                />
                <>
                    <FormHelperText>{!objProps.value ? "*Required" : ""}</FormHelperText>
                </>
            </FormControl>
        );
    }
    function getTableData() {
        setIsLoader(true);
        getUserDetails(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {
                setTableData(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    useEffect(() => {
        getTableData();
    }, [userDetails]);

    const columns = [
        {
            title: "First Name",
            field: "userFirstName",
            validate: (rowData) => validateFirstName(rowData.userFirstName),
        },
        {
            title: "Last Name",
            field: "userLastName",
            validate: (rowData) => validateLastName(rowData.userLastName),
        },
        {
            title: "Email",
            field: "userEmail",
            validate: (rowData) => validateEmail(rowData),
        },
        {
            title: "GID", field: "gid", validate: (rowData) => validateGID(rowData),
            //render: (rowData) => <span>{(rowData.gid)?.toLowerCase()}</span>,
            render: (rowData) => <span>{(rowData.gid)}</span>,
        },
        {
            title: "Role",
            field: "roleId",
            validate: (rowData) => validateRole(rowData.roleId),
            editComponent: (props) => getRoleComponent(props),
            render: (rowData) => <span>{renderRoleValue(rowData.roleId)}</span>,
            customFilterAndSearch: (term, rowData) =>
                renderRoleValue(rowData.roleId)
                    .toLowerCase()
                    .includes(term.toLowerCase()),
        },
        {
            title: "Sales District",
            field: "sdistrictId",
            validate: (rowData) => validateSDistrict(rowData),
            editComponent: (props) => getSDistrictComponent(props),
            render: (rowData) => (
                <span>{renderDistrictValue(rowData.sdistrictId)}</span>
            ),
            customFilterAndSearch: (term, rowData) =>
                renderDistrictValue(rowData.sdistrictId)
                    .toLowerCase()
                    .includes(term.toLowerCase()),
        },
        {
            title: "Sales Branch",
            field: "sbranchId",
            validate: (rowData) => validateSBranch(rowData),
            render: (rowData) => (
                <span>{renderSalesBranchValue(rowData.sbranchId)}</span>
            ),
            editComponent: (props) => getSalesBranchDDLComponent(props),
            customFilterAndSearch: (term, rowData) =>
                renderSalesBranchValue(rowData.sbranchId)
                    .toLowerCase()
                    .includes(term.toLowerCase()),
        },
    ];

    useEffect(() => {
        getEngineerDetails();
        getSalesBranchDetails();
        getRoleDetails();
    }, []);

    const handleRowUpdate = (newTableData, oldTableData, resolve, reject) => {
        if (isValidSBranchPerRoleId(newTableData.roleId)) {
            newTableData.sbranchId = null;
            newTableData.sbranch = null;
        }
        if (isValidSDistrictPerRoleId(newTableData.roleId)) {
            newTableData.sdistrictId = null;
            newTableData.sdistrict = null;
        }
        setIsLoader(true);
        newTableData.loggedinUserId = userDetails.userId;
        updateUserDetails(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, newTableData)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableData();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                reject();
                setIsLoader(false);
            });
    };
    const handleRowAdd = (newTableData, resolve, reject) => {
        if (isValidSBranchPerRoleId(newTableData.roleId)) {
            newTableData.sbranchId = null;
            newTableData.sbranch = null;
        }
        if (isValidSDistrictPerRoleId(newTableData.roleId)) {
            newTableData.sdistrictId = null;
            newTableData.sdistrict = null;
        }
        setIsLoader(true);
        newTableData.loggedinUserId = userDetails.userId;
        addUserDetails(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, newTableData)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableData();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                reject();
                setIsLoader(false);
            });
    };
    const handleRowDelete = (oldTableData, resolve, reject) => {
        setIsLoader(true);
        oldTableData.loggedinUserId = userDetails.userId;
        deleteUserDetails(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, oldTableData)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableData();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                reject();
                setIsLoader(false);
            });
    };
    function ExportToExcel() {
        const dataToDownload = tableRef?.current?.dataManager?.searchedData.map(
            (row) => {
                return {
                    firstName: row.userFirstName,
                    lastName: row.userLastName,
                    email: row.userEmail,
                    role: row.role,
                    gid: row.gid,
                    sbranch: row.sbranch,
                    sdistrict: row.sdistrict,
                };
            }
        );
        let headers = [
            "First Name",
            "Last Name",
            "Email",
            "Role",
            "GID",
            "Sales Branch",
            "Sales District",
        ];
        //const workSheet = XLSX.utils.json_to_sheet(newData);
        let ws = XLSX.utils.book_new();
        let wb = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(ws, [headers]);
        XLSX.utils.sheet_add_json(ws, dataToDownload, {
            origin: "A2",
            skipHeader: true,
            cellStyles: true,
        });
        const dataForCellAdjustments = dataToDownload;
        const headerObj = {
            firstName: headers[0],
            lastName: headers[1],
            email: headers[2],
            role: headers[3],
            gid: headers[4],
            sbranch: headers[5],
            sdistrict: headers[6],
        };
        dataForCellAdjustments.push(headerObj);
        ws["!cols"] = cellAdjustToContents(dataToDownload);
        XLSX.utils.book_append_sheet(wb, ws, "Users");
        XLSX.write(wb, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(wb, "UserDetails.xlsx");
    }
    function cellAdjustToContents(data) {
        return [
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.firstName ? c.firstName?.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) => (c.lastName ? c.lastName?.toString()?.length : 0))
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) => (c.email ? c.email?.toString()?.length : 0))
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) => (c.role ? c.role?.toString()?.length : 0))
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) => (c.gid ? c.gid?.toString()?.length : 0))
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) => (c.sbranch ? c.sbranch?.toString()?.length : 0))
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.sdistrict ? c.sdistrict?.toString()?.length : 0
                    )
                ),
            },
        ];
    }
    return (
        <div>
            <div className="App">
                <Grid container spacing={1}>
                    <Grid item xs={12} className="d-flex jc-space-bt">
                        <Grid item xs={9} className="text-left ml-1">
                            <h2>User Data</h2>
                        </Grid>
                        <Grid item xs={3} className="text-right"></Grid>
                    </Grid>
                    
                    <Grid item xs={12}>
                        {userDetails.roleId !== 10 ?
                            <MaterialTable
                                title="User List"
                                columns={columns}
                                data={tableData}
                                tableRef={tableRef}
                                icons={tableIcons}
                                isLoading={isLoader}
                                options={tableOptionsUser}
                                actions={[
                                    {
                                        icon: icon,
                                        tooltip: "Export to Excel",
                                        onClick: () => ExportToExcel(),
                                        isFreeAction: true,
                                    },
                                ]}
                                editable={{
                                   
                                    onRowUpdate: (newTableData, oldTableData) =>
                                        new Promise((resolve, reject) => {
                                            handleRowUpdate(
                                                newTableData,
                                                oldTableData,
                                                resolve,
                                                reject
                                            );
                                        }),
                                    onRowAdd: (newTableData) =>
                                        new Promise((resolve, reject) => {
                                            handleRowAdd(newTableData, resolve, reject);
                                        }),
                                    onRowDelete: (oldTableData) =>
                                        new Promise((resolve, reject) => {
                                            handleRowDelete(oldTableData, resolve, reject);
                                        }),
                                }}
                            /> : <MaterialTable
                                title="User List"
                                columns={columns}
                                data={tableData}
                                tableRef={tableRef}
                                icons={tableIcons}
                                isLoading={isLoader}
                                options={tableOptionsUser}
                                actions={[
                                    {
                                        icon: icon,
                                        tooltip: "Export to Excel",
                                        onClick: () => ExportToExcel(),
                                        isFreeAction: true,
                                    },
                                ]}
                                
                            />}
                        <>
                            <SnackbarCustom
                                open={open}
                                message={snackMessage}
                                alertType={alertType}
                                handleClose={handleClose}
                            />
                        </>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}
