import React, { useState, useEffect, useRef } from "react";
import { TextField, Grid, Button, CircularProgress, TableBody, } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import VisibilityIcon from "@material-ui/icons/Visibility";
import "./OrderList.scss";
import MaterialTable, {
    MTableBody,
    MTableGroupRow,
    MTableHeader,
    MTablePagination,
    MTableActions
} from "material-table";
import * as XLSX from "xlsx";
import {
    getRoundUptoXDecimals,
    tableCellCurrentFY,
    tableCellPreviousFY,
    tableIcons,
    renderValueWithThousandSeparator,
    tdInputCurrentFY,
    tdTotalInPut,
} from "../../Utilities/Utility";
import {
    FormControl,

    FormHelperText,
    Dialog,
    DialogContent,
    Tooltip,

    DialogContentText,
    DialogActions,
    DialogTitle,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
} from "@material-ui/core";
import Icon from '@material-ui/core/Icon';
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import { getSalesOrdersList, getSalesDist } from "../../Redux/APIs/api_OrderList";

import { makeStyles } from "@material-ui/core/styles";
import { Buffer } from 'buffer';
import {
    customStylesDefault,
    tableIconsInputScreen,
    theme,
} from "../../../src/Utilities/Utility";
import CloseIcon from "@material-ui/icons/Close";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import Checkbox from "@material-ui/core/Checkbox";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useSelector } from "react-redux";
import { getCurrentFiscalYear } from "../../Utilities/Utility";
import {
    getCustomerDDL,
    getEngineerDDL,
    getGckDDL,
    getSalesBranchDDL,
    getSalesOfficeDDL,
    getSalesTeritoryDDL,
    getYearDDL,
    getGck,
    getCustomer
} from "../../Redux/APIs/api_Common";
import { lightTheme } from "../../Themes/LightTheme";
//import { getCurrentFiscalYear } from "../../Utilities/Utility";
import { sassNull } from "sass";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import { getBusinessSegmentData, getBusinessUnitData, addSales, updateUser } from "../../Redux/APIs/api_MasterData";
//import Filter from "./Filter";
const icon = <CheckBoxOutlineBlankIcon />;
const checkedIcon = <CheckBoxIcon />;
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));
export default function Delegate(props) {
    const tableRef = useRef();
    const icon = () => {
        return <GetAppRoundedIcon style={{ fontSize: 53 }} />;
    };
    const classes = useStyles();
    const filterOptions = createFilterOptions({
        matchFrom: "any",
        limit: 200,
    });
    function getProgressComponent() {
        return <CircularProgress style={{ height: `40px`, width: `40px` }} />;
    }
    const [formDialogTitle, setFormDialogTitle] = useState("Save");
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);

    // const handleClickOpenForm = () => {
    //   setFormDialogTitle("Save");
    //   setOpenForm(true);
    // };
    const [openForm, setOpenForm] = useState(false);
    const handleCloseForm = () => {
        //clearFormValues();
        setOpenForm(false);
    };
    //const siteToken = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    let [isLoader, setIsLoader] = useState(false);
    const [CustomerData, setCustomerData] = useState([]);
    const [yearData, setYearData] = useState([]);
    const [gckData, setGckData] = useState([]);
    const [engineerData, setEngineerData] = useState([]);
    const [engineer, setEngineer] = useState([]);
    const [salesOfficeData, setSalesOfficeData] = useState([]);
    const [businessUnit, setBusinessUnit] = useState([]);
    const [businessSegment, setBusinessSegment] = useState([]);
    const [salesTeritoryData, setSalesTeriotoryData] = useState([]);
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const [select, setSelect] = useState(false);
    let [year, setYear] = useState({
        id: props.filterData?.yearid,
        name: props.filterData?.yearName,
    });
    const token = useSelector((state) => state.saveTokenReducer.token);
    let [filterInput, setFilterInput] = useState({
        year: { id: 0, name: "" },
        yearText: "",
        customer: [],
        gck: [],

        gckText: "",
        engineer: [],
        office: [],
        teritory: [],
        segment: [],
    });
    let [yearText, setYearText] = useState(props.filterData?.yearName);
    const handleYearChange = (e, newValue) => {
        setFilterInput({
            ...filterInput,
            year: { id: newValue?.id, name: newValue?.name },
            yearText: newValue?.name,
            customer: [],
            gck: [],

            engineer: [],
            office: [],
            teritory: [],
            segment: [],
            business: [],
        });

        // props.setFilterData({
        //   ...props.filterData,
        //   yearId: newValue?.id,
        //   yearName: newValue?.name,
        // });
        setYear({ id: newValue?.id, name: newValue?.name });
        setYearText(newValue?.name);
    };
    const handleYearInputChange = (e, text) => {
        setFilterInput({
            ...filterInput,
            yearText: text,
        });
        setYearText(text);
    };
    function getCustomerDetails(yearId) {
        setIsLoader(true);
        getCustomerDDL(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId,yearId)
            .then((response) => {
                console.log("cust",response)
                setCustomerData(response);
                setIsLoader(false);
            })
            .catch((error) => {
                console.log(error);
                setIsLoader(false);
            });
    }
    function getYearDetails() {
        let year = getCurrentFiscalYear();
        getYearDDL(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {
                console.log(response);
                let obj = response?.find((x) => x.name === year);
                setFilterInput({
                    ...filterInput,
                    year: { id: obj?.id, name: obj?.name },
                    yearText: obj?.name,
                });
                setYearData(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    function getGckDetails(yearId) {
        getGckDDL(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, yearId)
            .then((response) => {
                console.log(response);
                setGckData(response);

            })
            .catch((error) => {
                console.log(error);
            });
    }
    function getEngineerDetails() {
        getEngineerDDL(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {
                setEngineerData(response);
                setEngineer(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    function getSalesOfficeDetails() {
        getSalesBranchDDL(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {
                setSalesOfficeData(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    function getSalesTeritoryDetails() {
        getSalesTeritoryDDL(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {
                setSalesTeriotoryData(response);
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    function getBusinessUnit() {
        getBusinessUnitData(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {
                setBusinessUnit(response);
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    function getBusinessSegment() {
        getBusinessSegmentData(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {
                setBusinessSegment(response);
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    function getCust(yearId, name) {
        getCustomer(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, yearId, name)
            .then((response) => {
                setBusinessSegment(response);
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    function hello() {
        console.log("hi gck");
    }
    function getGckmaster(yearId, gckId) {
        getGck(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, yearId, gckId)
            .then((response) => {
                setGckData(response);
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const id = localStorage.getItem("ApplicationId");
    const FilterResult = () => {
        //props.handleClickFilterResult();
        localStorage.setItem("cus", 1);
        console.log()
        props.setFilterData({
            ...props.filterData,
            yearId: filterInput?.year?.id,
            customer: filterInput?.customer,
            gck: filterInput?.gck,

            engineer: filterInput?.engineer,
            office: filterInput?.office,
            teritory: filterInput?.teritory,
            segment: filterInput?.segment,
        });


        setFormDialogTitle("Save");
        setOpenForm(true);

    };
    function handleAccept() {
        setIsLoader(true);
        //alert("handle accept");
        //var lst = tableData.filter(row => row.tableData.checked == true);
        //for (var i = 0; i < tableData.length; i++) {
        //    if (tableData[i].tableData.checked == true) {
        //        var lst = tableData[i]
        //    }
        //}
        //var id = lst.map(function (param) { return  param.salesOrderDetailsId  })
       // var c = id
       // console.log('c', c);
        /*var newData = lst.map(function (param) { return { "SalesOrderId": param.salesOrderDetailsId, "FromEngineerId": filterInput.engineer, "ToEngineerId": filterInput.engineerData } });*/
        var newData = {
            "FromEngineerId": filterInput.engineer.id,
            "ToEngineerId": filterInput.engineerData.id,
            "SalesOrderId":col
        }
    

        
        console.log("newData", newData);
        //newData = JSON.stringify(newData);

        updateUser(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, newData)

            //.then((response) => response.json())
            .then((response) => {
                //getCourseManagement(editUser);
                if (response.response) {
                    setIsLoader(false);
                    console.log("response", response);
                    handleSnackOpen(response.responseMsg, "success");
                    localStorage.setItem("Application", 0);
                    setTableData([])
                    setTotals([
                        {
                            totalPotentialCurrent: 0,
                            totalBudgetCurrent: 0,
                            totalActualsCurrent: 0,
                            sowPercent: 0,
                            totalPotentialPrevious: 0,
                            totalBudgetPrevious: 0,
                            totalActualsPrevious: 0,
                            achievementPercent: 0,
                        },
                    ]);
                    setFilterInput({
                        ...filterInput,
                       
                        customer: null,
                        gck:null,
                        engineer: null,
                        engineerData: null,
                        
                    });
                    

                }
                else {
                    setIsLoader(false);
                   // console.log("response", response);
                    handleSnackOpen(response.responseMsg, "error");
                }
            })
            .catch((error) => {
                setIsLoader(false);
            });

    }
    function getSalesOrderData() {
        setTotals([
            {
                totalPotentialCurrent: 0,
                totalBudgetCurrent: 0,
                totalActualsCurrent: 0,
                sowPercent: 0,
                totalPotentialPrevious: 0,
                totalBudgetPrevious: 0,
                totalActualsPrevious: 0,
                achievementPercent: 0,
            },
        ]);

        let yearname = yearData.find((x) => x.id == filterInput?.year?.id)?.name;
        let data = {}
        if (filterInput.gck === null && filterInput.customer === null) {
             data = {
                EngineerId: filterInput.engineer.id,
                GckId: filterInput.gck,
                CustomerId: filterInput.customer,
            }

        }
        else if (filterInput.gck === null) {
             data = {
                EngineerId: filterInput.engineer.id,
                GckId: filterInput.gck,
                CustomerId: filterInput.customer.id,
            }

        }
        else if (filterInput.customer === null) {
             data = {
                EngineerId: filterInput.engineer.id,
                GckId: filterInput.gck.id,
                CustomerId: filterInput.customer,
            }

        }
        else {
             data = {
                EngineerId: filterInput.engineer.id,
                GckId: filterInput.gck.id,
                CustomerId: filterInput.customer.id,
            }
        }
        setIsLoader(true);
        getSalesDist(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, data)
            .then((response) => {
                console.log("sales", response);




                if (response.response) {
                    setTableData(response.objResult.listPlanningDetails);
                    setTotals([{ ...response.objResult.totals }]);
                    if (yearname === "2020-21") {
                        setFinYear({
                            previousyear: "2019-20",
                            currentyear: response.objResult.listPlanningDetails[0].year,
                        });
                    } else {
                        setFinYear({
                            previousyear:
                                response.objResult.listPlanningDetails[0].previousYear,
                            currentyear: response.objResult.listPlanningDetails[0].year,
                        });
                    }

                    setIsLoader(false);

                } else {
                    setTableData([]);
                    handleSnackOpen(response.responseMsg, "info");

                    setIsLoader(false);
                }
            })
            .catch((error) => {
                console.log("Error : " + error, "error");
                handleSnackOpen("Exception occured while fetching order list", "error");
                setIsLoader(false);
            });
    }
    function clearFormValues() {
        props.setFilterData({
            ...props.filterData,
            yearId: "",
            customer: [],
            gck: [],


        });

    }
    function goToInputScreen(data) {
        const params =
            "yearId=" +
            data?.yearId +
            "&customerId=" +
            data?.customerId +
            "&customer=" +
            data?.customer +
            "&gckId=" +
            data?.gckId +
            "&gck=" +
            data?.gck +
            "&sdistrictId=" +
            data?.sdistrictId +
            "&sdistrict=" +
            data?.sdistrict;
        const url = "/orderInput?" + Buffer.from(params)?.toString("base64");
        window.open(url, "_blank");
    }
    const handleClose = (e) => {
        setOpen(false);
    };
    const [col, setCol] = useState([]);
    const logAddCustomer = () => {
        //props.handleClickFilterResult();
        let dist = filterInput?.engineer;
        if (userDetails.roleId == 1) {
            if (dist === "" || dist === null || dist === undefined || dist.length <= 0) {
                handleCloseForm();
                handleSnackOpen("please select  sales district", "error");
                return;
            }
        }
        let data =
        {
            yearid: filterInput?.year?.id,
            CustomerList: filterInput?.customer,
            GckList: filterInput?.gck,
            loggedinuserid: userDetails.userId,
            SdistrictList: filterInput?.engineer,
        };

        //props.setFilterData({
        //    ...props.filterData,
        //    yearId: filterInput?.year?.id,
        //    customer: filterInput?.customer,
        //    gck: filterInput?.gck,

        //    engineer: filterInput?.engineer,
        //    office: filterInput?.office,
        //    teritory: filterInput?.teritory,
        //    segment: filterInput?.segment,
        //});
        let customer = filterInput?.customer;
        let gck = filterInput?.gck;
        let yearId = filterInput?.year?.id;

        //let data = {
        //    yearid: filterInput?.year?.id,
        //    CustomerList: customer,
        //    GckList: gck,
        //    loggedinuserid: userDetails.userId,
        //    SdistrictList:dist,
        //}

        console.log(data);
        addSales(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, data)

            .then((response) => {
                console.log(response);
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    FilterResult();
                    handleCloseForm();
                    setIsLoader(false);
                    getYearDetails();

                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                setIsLoader(false);
            });
    };

    function func() {
        const Filter = () => {
            //props.handleClickFilterResult();

            props.setFilterData({
                ...props.filterData,
                yearid: filterInput?.year?.id,
                customerId: filterInput?.customer[0].id,
                gckId: filterInput?.gck[0].id,
                loggedinuserid: userDetails.userId,


            });
        };
        addSales(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    setIsLoader(false);

                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                setIsLoader(false);
            });





    }
    let [finYear, setFinYear] = useState({
        previousyear: "Previous FY",
        currentyear: "Current FY",
    });
    function getViewOrderDetailsButton(data) {
        return (
            <IconButton
                title="Go to input screen"
                onClick={() => goToInputScreen(data)}
            >
                <VisibilityIcon />
            </IconButton>
        );
    }
    var columns = [
        { title: "SalesOrderId", field: "salesOrderDetailsId", hidden: true },
        { title: "YearId", field: "yearId", hidden: true },
        {
            title: "View",
            field: "",
            render: (rowData) => getViewOrderDetailsButton(rowData),
        },
        {
            title: "Customer Name",
            field: "customer",
        },
        {
            title: "IFA Number",
            field: "ifaNumber",
        },
        {
            title: "City",
            field: "city",
        },
        {
            title: "Role",
            field: "role",
        },
        {
            title: "Industry",
            field: "industry",
        },
        {
            title: "Industry Segment",
            field: "industrySegment",
        },
        {
            title: "Sales Office",
            field: "sbranch",
        },
        {
            title: "Sales Teritory",
            field: "steritory",
        },
        {
            title: "Sales Engineer",
            field: "sdistrict",
        },

        {
            title: "GCK",
            field: "gck",
        },
        {
            title: "Potential",
            field: "potentialPrevious",
            cellStyle: tableCellPreviousFY,
            headerStyle: tableCellPreviousFY,
        },
        {
            title: "Budget",
            field: "budgetPrevious",
            cellStyle: tableCellPreviousFY,
            headerStyle: tableCellPreviousFY,
        },
        {
            title: "New Orders",
            field: "actualsPrevious",
            cellStyle: tableCellPreviousFY,
            headerStyle: tableCellPreviousFY,
        },
        {
            title: "SOW (%)",
            field: "sow",
            cellStyle: tableCellPreviousFY,
            headerStyle: tableCellPreviousFY,
            render: (rowData) => <>{getRoundUptoXDecimals(rowData.sow, 0)}</>,
        },
        {
            title: "Potential",
            field: "potentialCurrent",
            cellStyle: tableCellCurrentFY,
            headerStyle: tableCellCurrentFY,
        },
        {
            title: "Budget",
            field: "budgetCurrent",
            cellStyle: tableCellCurrentFY,
            headerStyle: tableCellCurrentFY,
        },
        {
            title: "New Orders",
            field: "actualsCurrent",
            cellStyle: tableCellCurrentFY,
            headerStyle: tableCellCurrentFY,
        },
        {
            title: "Achivement (%)",
            field: "achievement",
            cellStyle: tableCellCurrentFY,
            headerStyle: tableCellCurrentFY,
            render: (rowData) => <>{getRoundUptoXDecimals(rowData.achievement, 0)}</>,
        },
    ];
    function getCustomTHead(params) {
        return (
            <TableHead>
                <TableRow>
                    <TableCell colSpan={12} align="center"></TableCell>
                    <TableCell
                        className={classes.tableCellPreviousFY}
                        colSpan={4}
                        align="center"
                        style={{ fontWeight: "bold", background: `var(--light-red)`, }}
                    >
                        {finYear.previousyear}
                    </TableCell>
                    <TableCell
                        className={classes.tableCellCurrentFY}
                        colSpan={4}
                        align="center"
                        style={{ fontWeight: "bold", background: `var(--green-type-1)`, }}
                    >
                        {finYear.currentyear}
                    </TableCell>
                </TableRow>
            </TableHead>
        );
    }
    let [totals, setTotals] = useState([
        {
            totalPotentialCurrent: 0,
            totalBudgetCurrent: 0,
            totalActualsCurrent: 0,
            sowPercent: 0,
            totalPotentialPrevious: 0,
            totalBudgetPrevious: 0,
            totalActualsPrevious: 0,
            achievementPercent: 0,
        },
    ]);
    function getCustomTBodyForTotal(data) {
        return (
            <TableBody style={{ position: "sticky", top: 60 }}>
                {data.map((row) => (
                    <TableRow className={classes.tableCellTotal}>
                        <TableCell
                            align="center"
                            colSpan={12}
                            style={{ fontWeight: "bold", background: `var(--gold-type-1)`, }}
                        >
                            TOTAL
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold", background: `var(--gold-type-1)`, }}>
                            {renderValueWithThousandSeparator(
                                getRoundUptoXDecimals(row.totalPotentialPrevious, 2)
                            )}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold", background: `var(--gold-type-1)`, }}>
                            {renderValueWithThousandSeparator(
                                getRoundUptoXDecimals(row.totalBudgetPrevious, 2)
                            )}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold", background: `var(--gold-type-1)`, }}>
                            {renderValueWithThousandSeparator(
                                getRoundUptoXDecimals(row.totalActualsPrevious, 2)
                            )}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold", background: `var(--gold-type-1)`, }}>
                            {renderValueWithThousandSeparator(
                                getRoundUptoXDecimals(row.sowPercent, 0)
                            )}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold", background: `var(--gold-type-1)`, }}>
                            {renderValueWithThousandSeparator(
                                getRoundUptoXDecimals(row.totalPotentialCurrent, 2)
                            )}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold", background: `var(--gold-type-1)`, }}>
                            {renderValueWithThousandSeparator(
                                getRoundUptoXDecimals(row.totalBudgetCurrent, 2)
                            )}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold", background: `var(--gold-type-1)`, }}>
                            {renderValueWithThousandSeparator(
                                getRoundUptoXDecimals(row.totalActualsCurrent, 2)
                            )}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold", background: `var(--gold-type-1)`, }}>
                            {renderValueWithThousandSeparator(
                                getRoundUptoXDecimals(row.achievementPercent, 0)
                            )}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        );
    }

    function ExportToExcel() {
        const dataToDownload = tableRef?.current?.dataManager?.searchedData.map(
            (row) => {
                return {
                    customerName: row.customer,
                    ifaNumber: row.ifaNumber,
                    salesOffice: row.sbranch,
                    salesTeritory: row.steritory,
                    salesEngineer: row.sdistrict,
                    gck: row.gck,
                    PreviousPotential: row.potentialPrevious,
                    PreviousBudget: row.budgetPrevious,
                    PreviousNewOrders: row.actualsPrevious,
                    sow: row.sow,
                    CurrentPotential: row.potentialCurrent,
                    CurrentBudget: row.budgetCurrent,
                    CurrentNewOrders: row.actualsCurrent,
                    Acheivement: row.achievement,
                };
            }
        );
        let headers = [
            "Customer Name",
            "IFA Number",
            "Sales Office",
            "Sales Teritory",
            "Sales Engineer",
            "GCK",
            "Previous Potential",
            "Previous Budget",
            "Previous New Orders",
            "SOW",
            "Current Potential",
            "Current Budget",
            "Current New Orders",
            "Acheivement",
        ];
        //const workSheet = XLSX.utils.json_to_sheet(newData);
        let ws = XLSX.utils.book_new();
        let wb = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(ws, [headers]);
        XLSX.utils.sheet_add_json(ws, dataToDownload, {
            origin: "A2",
            skipHeader: true,
            cellStyles: true,
        });
        const dataForCellAdjustments = dataToDownload;
        const headerObj = {
            customerName: headers[0],
            ifaNumber: headers[1],
            salesOffice: headers[2],
            salesTeritory: headers[3],
            salesEngineer: headers[4],
            gck: headers[5],
            PreviousPotential: headers[6],
            PreviousBudget: headers[7],
            PreviousNewOrders: headers[8],
            sow: headers[9],
            CurrentPotential: headers[10],
            CurrentBudget: headers[11],
            CurrentNewOrders: headers[12],
            Acheivement: headers[13],
        };
        dataForCellAdjustments.push(headerObj);
        ws["!cols"] = cellAdjustToContents(dataForCellAdjustments);
        XLSX.utils.book_append_sheet(wb, ws, "SalesOrder");
        XLSX.write(wb, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(wb, "SalesOrders.xlsx");
    }
    function cellAdjustToContents(data) {
        return [
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.customerName ? c.customerName?.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.ifaNumber ? c.ifaNumber?.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.salesOffice ? c.salesOffice?.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.salesTeritory ? c.salesTeritory?.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.salesEngineer ? c.salesEngineer?.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) => (c.gck ? c.gck?.toString()?.length : 0))
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.PreviousPotential ? c.PreviousPotential?.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.PreviousBudget ? c.PreviousBudget?.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.PreviousNewOrders ? c.PreviousNewOrders?.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) => (c.sow ? c.sow?.toString()?.length : 0))
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.CurrentPotential ? c.CurrentPotential?.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.CurrentBudget ? c.CurrentBudget?.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.CurrentNewOrders ? c.CurrentNewOrders?.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.Acheivement ? c.Acheivement?.toString()?.length : 0
                    )
                ),
            },
        ];
    }
    
    function selectRow( rowData) {


        console.log("rowdata", rowData)
        if (rowData.length >= 1) {
            setSelect(true);
            localStorage.setItem("Application", 1);
        }
        else {
            setSelect(false);
            localStorage.setItem("Application", 2);
        }
        
        setCol(rowData.map(function (param) { return param.salesOrderDetailsId }))
        console.log(col)
        //if (rowData !== undefined && e.length!=0) {
        //    rowData.isSelected = rowData.isSelected ? false : true;
        //    console.log("row", rowData.isSelected)
        //    //if (rowData.isSelected === true) {
        //    //    setTableData(rowData)
        //    //}
            
        //    c
        //    localStorage.setItem("Application", 1);
        //    console.log("select", select);
        //    setTableData(
        //        tableData.map((item) => {
        //            return item.salesOrderDetailsId === rowData.salesOrderDetailsId ? rowData : item;
        //        })
        //    );

        //    console.log("table", tableData);
        //}
        //else {
        //    if (e.length == 0) {
        //        localStorage.setItem("Application", 2);
        //        setSelect(false);
        //        console.log("select", select);
        //        //uncheckAll
        //        for (var i = 0; i < tableData.length; i++) {
        //            tableData[i].isSelected = false;
        //            tableData[i].tableData.checked = false;
        //        }

        //    }
        //    if (e.length == tableData.length) {
               
        //        //checkAll
        //        for (var i = 0; i < e.length; i++) {
        //            e[i].isSelected = true;
        //            //checked=e[i].selected
        //            e[i].tableData.checked = true;
        //            tableData[i] = e[i]
        //            rowData = tableData[i]
        //           // tableData[i].tableData.checked = rowData.isSelected
        //            rowData.isSelected = tableData[i].tableData.checked
        //        }
        //        setTableData(tableData)
        //        //setSelectAll(true);
        //        //for (var i = 0; i < courseManagementList.length; i++) {
        //        //    courseManagementList[i].isSelected = true;
        //        //}
        //        //setCourseManagementList(courseManagementList.map((item) => {
        //        //    return item;
        //        //}));
                



        //    }
        //    //else if (e.length < courseManagementList.length && e.length != 0) {
        //    //    //partialcheck;
        //    //    setSelectAll(true);
        //    //    for (var i = 0; i < courseManagementList.length; i++) {
        //    //        for (var j = 0; j < e.length; j++) {
        //    //            e[j].isSelected = true;
        //    //            setCourseManagementList(
        //    //                courseManagementList.map((item) => {
        //    //                    return item.id === e[j].id ? e[j] : item;
        //    //                })
        //    //            );
        //    //        }
        //    //    }
        //    //    //setCourseManagementList(courseManagementList.map((item) => {
        //    //      //return item;
        //    //   //}));
        //    //    //GetAcceptButton();


        //    //}
        //}
    }
    //function GetAcceptButton() {
    //    return (

    //        <Button variant="contained" className="leap-button--primary rseume-course-btn" onClick={(event, rowData) => handleAccept(rowData)} title="Save" >
    //            Save
    //        </Button >

    //    );
    //}
    const [tableData, setTableData] = useState([]);
    const set = localStorage.getItem("Application");
    useEffect(() => {
        getYearDetails();
        getEngineerDetails();
       /* getSalesOfficeDetails();*/
        //getSalesTeritoryDetails();
        //getBusinessUnit();
        //getBusinessSegment();
        localStorage.setItem("cus", 0);
        localStorage.setItem("Application", 0);
    }, []);

    useEffect(() => {
        if (filterInput?.year?.id > 0) {
            getCustomerDetails(filterInput?.year?.id);
            getGckDetails(filterInput?.year?.id);
        }
        if (filterInput?.year?.id > 0 && filterInput?.gck?.id > 0) {
            getGckmaster(filterInput?.year?.id, filterInput?.gck?.id);
        }
    }, [filterInput?.year]);

    useEffect(() => {
        if (filterInput?.year?.id > 0) {
            switch (userDetails.roleId) {
                case 6:
                    let engineer = [
                        { id: userDetails?.sdistrictId, name: userDetails?.sdistrict },
                    ];
                    setFilterInput({
                        ...filterInput,
                        engineer: engineer,
                    });
                    // props.setFilterData({
                    //   ...props.filterData,
                    //   engineer: engineer,
                    //   yearId: filterInput?.year?.id,
                    //   customer: [],
                    //   gck: [],
                    // });
                    break;
                case 5:
                    let office = [
                        { id: userDetails?.sbranchId, name: userDetails?.sbranch },
                    ];
                    setFilterInput({
                        ...filterInput,
                        office: office,
                    });
                    // props.setFilterData({
                    //   ...props.filterData,
                    //   office: office,
                    //   yearId: filterInput?.year?.id,
                    //   customer: [],
                    //   gck: [],
                    //   engineer: [],
                    //   teritory: [],
                    // });
                    break;
                default:
                    // props.setFilterData({
                    //   ...props.filterData,
                    //   yearId: filterInput?.year?.id,
                    //   customer: [],
                    //   gck: [],
                    //   engineer: [],
                    //   office: [],
                    //   teritory: [],
                    // });
                    break;
            }
        }
    }, [userDetails, filterInput?.year?.id]);

    return (
        <>
            {isLoader ? (
                <Grid item xs={12} sm={12} className="d-flex jc-center pd-2">
                    {getProgressComponent()}
                </Grid>
            ) : (
                    <>
                        <Grid container >
                            <Grid item xs={12} sm={4} className="pd-1">
                                <Autocomplete
                                    filterOptions={filterOptions}

                                    loading
                                    loadingText="No Options"
                                    // disableClearable
                                    freeSolo
                                    id="Engineer Data"
                                    disabled={!engineerData?.length > 0}
                                    options={engineerData}
                                    fullWidth
                                    value={filterInput.engineer}
                                    onChange={(event, value) => {
                                        setFilterInput((old) => {
                                            return {
                                                ...old,
                                                engineer: value,
                                            };
                                        });
                                    }}
                                    limitTags={2}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => (option.name ? option.name : "")}

                                    renderInput={(params) => (
                                        <TextField {...params} label="&nbsp;&nbsp;Sales District" />
                                    )}
                                />
                            </Grid>
                           
                            <Grid
                                item
                                xs={12}
                                sm={4}
                                className="pd-1"
                            >
                                <Autocomplete
                                    filterOptions={filterOptions}
                                    //multiple
                                    //disableClearable
                                    loading
                                    loadingText="No Options(customer is not there in the database)"
                                    freeSolo
                                    id="Customer"
                                    name="Customer"
                                    //disabled={!CustomerData?.length > 0}
                                    options={CustomerData}
                                    value={filterInput.customer}
                                    onChange={(event, value) => {
                                        setFilterInput((old) => {
                                            return {
                                                ...old,
                                                customer: value,
                                            };
                                        });
                                    }}
                                    fullWidth
                                    limitTags={1}
                                    disableCloseOnSelect

                                    getOptionLabel={(option) => (option.name ? option.name : "")}
                                    
                                    renderInput={(params) => (
                                        <TextField {...params} label="&nbsp;&nbsp;Customer" />
                                    )}

                                />

                            </Grid>

                            <Grid item xs={12} sm={4} className="pd-1">
                                <Autocomplete
                                    filterOptions={filterOptions}
                                    //multiple
                                    //disableClearable
                                    loading
                                    loadingText="No Options(gck is not there in the database)"
                                    freeSolo
                                    id="gck"
                                    disabled={!gckData?.length > 0}
                                    options={gckData}
                                    value={filterInput.gck}
                                    onChange={(event, value) => {
                                        setFilterInput((old) => {
                                            return {
                                                ...old,
                                                gck: value,

                                            };
                                        });
                                    }}
                                    fullWidth
                                    limitTags={2}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => (option.name ? option.name : "")}
                                   
                                    renderInput={(params) => (
                                        <TextField {...params} label="&nbsp;&nbsp;GCK" />
                                    )}
                                />
                            </Grid>
                   
                          

                               
                            {set == 1 ? (
                                <Grid item xs={12} sm={4} className="pd-1">
                                    <Autocomplete
                                        filterOptions={filterOptions}

                                        loading
                                        loadingText="No Options"
                                        //disableClearable
                                        freeSolo
                                        id="Engineer Data"
                                        disabled={!engineer?.length > 0}
                                        options={engineer}
                                        fullWidth
                                        value={filterInput.engineerData}
                                        onChange={(event, value) => {
                                            setFilterInput((old) => {
                                                return {
                                                    ...old,
                                                    engineerData: value,
                                                };
                                            });
                                        }}
                                        limitTags={2}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => (option.name ? option.name : "")}

                                        renderInput={(params) => (
                                            <TextField {...params} label="&nbsp;&nbsp;Delegate Sales District" />
                                        )}
                                    />
                                </Grid>
                            ) : (
                                <></>
                            )}

                     


                    <Grid item xs={12} sm={2} className=" d-flex jc-center pd-1">
                        <Button onClick={getSalesOrderData} className="pt-button--secondary">
                            Filter Result
                        </Button>
                            </Grid>
                            {filterInput.engineer!=null&& filterInput.engineerData!=null? (
                                <Grid item xs={12} sm={2} className=" d-flex jc-center pd-1">
                                    <Button disabled={userDetails.roleId===10} onClick={handleAccept} className="pt-button--secondary">
                                Delegate
                            </Button>
                        </Grid>
                    ) : (

                        <></>
                            )}
                        </Grid>
                </>
            )}
           
                    <Grid container spacing={2} className="">
                        <Grid item xs={12} className="d-flex fd-col pd-1">
                            {/*<h4 className="d-flex jc-center">List View</h4>*/}
                            <MaterialTable
                                title="List View"
                                tableRef={tableRef}
                                columns={columns}
                                isLoading={isLoader}
                                data={tableData}
                                icons={tableIcons}
                                actions={[
                                    {
                                        icon: icon,
                                        tooltip: "Export to Excel",
                                        onClick: () => ExportToExcel(),
                                        isFreeAction: true,
                                    },
                                    ]}
                                    onSelectionChange={(rows) => selectRow( rows)}
                                //onSelectionChange={(event, rowData) => selectRow(event, rowData)}
                                options={{
                                    draggable: false,
                                    sorting: true,
                                    showTitle: false,
                                    searchFieldAlignment: "left",
                                    toolbarButtonAlignment: "left",
                                    //paginationPosition: "top",
                                    //pageSize: dataLength > 5 ? 5 : dataLength,
                                    //pageSizeOptions: [5, 10, 20, 30],
                                    maxBodyHeight: "400px",
                                    padding: "dense",
                                    //actionsColumnIndex: selectedCheckbox,
                                    selection: true,
                                    //selectionProps: (rowData) => ({

                                    //    checked: rowData.isSelected ,


                                    //}),


                                    showTextRowsSelected: false,
                                }}



                                components={{
                                   
                                    Header: (props) => (
                                        <>
                                            {getCustomTHead()}
                                            <MTableHeader {...props} />
                                            {getCustomTBodyForTotal(totals)}
                                        </>
                                    ),
                                    Body: (props) => (
                                        <>
                                            <MTableBody {...props} />
                                        </>
                                    ),


                                }}
                            />
                        </Grid>
                    </Grid>
               


            <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleClose}
            />

        </>
    );
}
