import React, { useState, useEffect ,useRef} from "react";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import { Button } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { tableIcons, theme, customStylesDefault, GetAddButton, GetEditButton } from "../../../../Utilities/Utility";
import { tableOptionsUser } from "../../../../Themes/LightTheme";
import { useSelector } from "react-redux";
import SnackbarCustom from "../../../../Components/Snackbar/Snackbar";
import SalesOfficeForm from "../MasterDataForms/SalesTeritoryForms/SalesOfficeForm";
import * as XLSX from 'xlsx';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import { getSalesOfficeData, addSalesOfficeData, updateSalesOfficeData, deleteSalesOfficeData } from "../../../../Redux/APIs/api_MasterData";
export default function SOfficeMaster(props) {
    const tableRef = useRef();
    const icon=()=>{
        return(
        <GetAppRoundedIcon  style={{ fontSize: 53,color:`var(--btn-primary-bg-color)` }}/>
        );
    }
    const columns = [
        { title: 'Sales OfficeId', field: 'salesOfficeId', hidden: true },
        { title: 'Sales Office', field: 'salesOffice' },
        {
            title: 'Sales Branch', field: 'sbranchId',
            render: (rowData) => <span>{props.renderSBranchValue(rowData.sbranchId)}</span>,
            customFilterAndSearch: (term, rowData) =>
            props.renderSBranchValue(rowData.sbranchId)
              .toLowerCase()
              .includes(term.toLowerCase()),
        },
        {
            title: 'Sales Teritory', field: 'steritoryId',
            render: (rowData) => <span>{props.renderSTeritoryValue(rowData.steritoryId)}</span>,
            customFilterAndSearch: (term, rowData) =>
            props.renderSTeritoryValue(rowData.steritoryId)
              .toLowerCase()
              .includes(term.toLowerCase()),
        },
    ];
    const actions= [
        {
            icon:GetAddButton,
            onClick: () => handleClickOpenDialog(),
            isFreeAction: true,
        },
        {
            icon: icon,
            tooltip:"Export to Excel",
            onClick:()=>ExportToExcel(),
            isFreeAction:true
         },
        rowData => ({
            icon: GetEditButton,
            onClick: (event, rowData) => handleClickOpenDialog(rowData),
            isFreeAction: true,
        })
    ]
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const [tableData, setTableData] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpen(false);
    };
    const [rowData, setRowData] = useState("");
    
    const [openDialog, setOpenDialog] = useState(false);

    const handleClickOpenDialog = (data) => {
        setRowData(data);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);

    };
    const handleSubmit = () => {
        handleCloseDialog(true);
 
    }
  
    function getTableData() {
        setIsLoader(true);
        getSalesOfficeData(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {
                setTableData(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    useEffect(() => {
        getTableData();
        props.getSalesBranchDetails();
        props.getSalesTeritoryDetails()
    }, [userDetails])


    const handleUpdateData= (data) => {
        setIsLoader(true);
        updateSalesOfficeData(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, data)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");   
                    setIsLoader(false);
                    getTableData();
                } else {
                    handleSnackOpen(response.responseMsg, "error");      
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                setIsLoader(false);
            });
    };
  
    function handleAddData(data) {
        setIsLoader(true);
        addSalesOfficeData(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, data)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    setIsLoader(false);
                    getTableData();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                setIsLoader(false);
            });
    }
    const handleRowDelete = (oldTableData, resolve, reject) => {
        setIsLoader(true);
        oldTableData.loggedinUserId = userDetails.userId;
        deleteSalesOfficeData(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, oldTableData)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableData();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                reject();
                setIsLoader(false);
            });
    };
    function ExportToExcel() {
        const dataToDownload = tableRef?.current?.dataManager?.searchedData.map(
          (row) => {
            return {
                salesOffice: row.salesOffice,
                salesBranch: row.salesBranch,
                salesTeritory:row.salesTerritory
            };
          }
        );
        let headers = [
          "Sales Office",
          "Sales Branch",
          "Sales Teritory",
        ];
        //const workSheet = XLSX.utils.json_to_sheet(newData);
        let ws = XLSX.utils.book_new();
        let wb = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(ws, [headers]);
        XLSX.utils.sheet_add_json(ws, dataToDownload, {
          origin: "A2",
          skipHeader: true,
          cellStyles: true,
        });
        const dataForCellAdjustments=dataToDownload;
        const headerObj={
            salesOffice: headers[0],
            salesBranch: headers[1],
            salesTeritory:headers[2]
        }
        dataForCellAdjustments.push(headerObj);
        ws["!cols"] = cellAdjustToContents(dataToDownload);
        XLSX.utils.book_append_sheet(wb, ws, "SalesOffice");
        XLSX.write(wb, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(wb, "SalesOfficeMaster.xlsx");
      }
      function cellAdjustToContents(data) {
        return [
          {
            wch: Math.max(
              ...data?.map((c) =>
                c.salesOffice ? c.salesOffice?.toString()?.length : 0
              )
            ),
          },
          {
            wch: Math.max(
              ...data?.map((c) =>
                c.salesBranch ? c.salesBranch.toString()?.length : 0
              )
            ),
          },
          {
            wch: Math.max(
              ...data?.map((c) =>
                c.salesTeritory ? c.salesTeritory?.toString()?.length : 0
              )
            ),
          },
        
        ];
      }
    return (
        <div>
            <div className="App">
                <Grid container spacing={1}>

                    <Grid item xs={12}>
                        {userDetails.roleId !== 10 ?
                            <MaterialTable
                                title=""
                                tableRef={tableRef}
                                columns={columns}
                                data={tableData}
                                isLoading={isLoader}
                                icons={tableIcons}
                                options={tableOptionsUser}
                                editable={{

                                    onRowDelete: (oldTableData) =>
                                        new Promise((resolve, reject) => {
                                            handleRowDelete(oldTableData, resolve, reject);
                                        }),
                                }}
                                actions={actions}
                            /> : <MaterialTable
                                title=""
                                tableRef={tableRef}
                                columns={columns}
                                data={tableData}
                                isLoading={isLoader}
                                icons={tableIcons}
                                options={tableOptionsUser}
                               
                               // actions={actions}
                            />}
                        <Dialog
                        open={openDialog}
                        onClose={handleCloseDialog}
                        fullWidth
                        maxWidth="md"
                        aria-labelledby="form-dialog-title">

                        <DialogContent >
                            <SalesOfficeForm
                                openDialog={openDialog}
                                handleCloseDialog={handleCloseDialog}
                                tableData={tableData}
                                rowData={rowData}
                                handleSubmit={handleSubmit}
                                handleUpdateData={handleUpdateData}
                                handleAddData={handleAddData}
                                steritoryData={props.steritoryData.find(x => x.value === rowData?.steritoryId)}
                                sbranchData={props.sbranchData.find(x => x.value === rowData?.sbranchId)}
                               
                            />
                        </DialogContent>

                    </Dialog>
                        <>
                            <SnackbarCustom
                                open={open}
                                message={snackMessage}
                                alertType={alertType}
                                handleClose={handleClose}
                            />
                        </>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}