import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import { Grid, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import DialogActions from "@material-ui/core/DialogActions";
import {
  getBusinessUnitDDL,
  getBusinessSegmentDDL,
  getBusinessSubSegmentDDL,
} from "../../../../../Redux/APIs/api_Common";

export default function GckInputForm(props) {
  const token = useSelector((state) => state.saveTokenReducer.token);
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
  const useStyles = makeStyles(() => ({
    inputForm: {
      padding: "85px 50px",
    },
  }));

  // API
  let [bunitData, setBunitData] = useState([]);
  function getBusinessUnitDetails() {
      getBusinessUnitDDL(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId)
      .then((response) => {
        if (response && response.length > 0) {
          setBunitData(
            response.map((item) => ({ value: item.id, label: item.name }))
          );
        } else {
          setBunitData([]);
        }
      })
      .catch((error) => console.log(error));
  }
  let [bsegmentData, setBsegmentData] = useState([]);

  function getBusinessSegmentDetails(buid) {
      getBusinessSegmentDDL(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId, buid)
      .then((response) => {
        if (response && response.length > 0) {
          setBsegmentData(
            response.map((item) => ({ value: item.id, label: item.name }))
          );
        } else {
          setBsegmentData([]);
        }
      })
      .catch((error) => console.log(error));
  }

  let [bsubsegmentData, setBsubsegmentData] = useState([]);
  function getBusinessSubSegmentDetails(buid, bsid) {
      getBusinessSubSegmentDDL(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId, buid, bsid)
      .then((response) => {
        if (response && response.length > 0) {
          setBsubsegmentData(
            response.map((item) => ({ value: item.id, label: item.name }))
          );
        } else {
          setBsegmentData([]);
        }
      })
      .catch((error) => console.log(error));
  }
  const [isexistError, setIsExistError] = useState("");
  const [errorTexts, setErrorTexts] = useState({
    gck: "",
    bunit: "",
    bsegment: "",
    bsubSegment: "",
    common: "",
  });
  let [defaultInputs, setDefaultInputs] = useState({
    // gck: "",
    // bunit: {},
    // bunitText: "",
    // bsegment: {},
    // bsegmentText: "",
    // bsubSegment: {},
    // bsubSegmentText: "",
    gck: props?.rowData?.gck,
    bunit: {
      value: props?.rowData?.bunitId,
      label: props?.rowData?.businessUnit,
    },
    bunitText: props?.rowData?.businessUnit,
    bsegment: {
      value: props?.rowData?.bsegmentId,
      label: props?.rowData?.businessSegment,
    },
    bsegmentText: props?.rowData?.businessSegment,
    bsubSegment: {
      value: props?.rowData?.bsubSegmentId,
      label: props?.rowData?.businesssubSegment,
    },
    bsubSegmentText: props?.rowData?.businesssubSegment,
  });
  let [inputs, setInputs] = useState({
    // gck: "",
    // bunitId: 0,
    // bsegmentId: 0,
    // bsubSegmentId: 0,
    yearId: props?.yearId,
    gckId: props?.rowData?.gckId,
    gck: props?.rowData?.gck,
    bunitId: props?.rowData?.bunitId,
    bsegmentId: props?.rowData?.bsegmentId,
    bsubSegmentId: props?.rowData?.bsubSegmentId,
  });

  const handleGckChange = (e) => {
    const value = e.target.value;
    setDefaultInputs({
      ...defaultInputs,
      gck: value,
    });
    setInputs({
      ...inputs,
      gck: value,
    });
    setErrorTexts({
      ...errorTexts,
      gck: "",
    });
    setIsExistError("");
    validateAutocompleteComponents(e, value);
  };

  const handleAutocompleteChange = (event, obj) => {
    let elem = event.target.id;
    let elemId = !elem
      ? ""
      : elem.includes("-")
      ? elem.substr(0, elem.indexOf("-"))
      : elem;
    switch (elemId) {
      case "bunit":
        setDefaultInputs({
          ...defaultInputs,
          [elemId]: obj,
          [elemId + "Text"]: obj?.label,
        });

        setInputs({
          ...inputs,
          [elemId + "Id"]: obj?.value,
        });
        setErrorTexts({
          ...errorTexts,
          bunit: "",
        });
        setIsExistError("");

        getBusinessSegmentDetails(obj.value);
        break;
      case "bsegment":
        setDefaultInputs({
          ...defaultInputs,
          [elemId]: obj,
          [elemId + "Text"]: obj?.label,
        });
        setInputs({
          ...inputs,
          [elemId + "Id"]: obj?.value,
        });
        setErrorTexts({
          ...errorTexts,
          bsegment: "",
        });
        setIsExistError("");
        getBusinessSubSegmentDetails(inputs.bunitId, obj.value);
        break;
      case "bsubSegment":
        setDefaultInputs({
          ...defaultInputs,
          [elemId]: obj,
          [elemId + "Text"]: obj?.label,
        });
        setInputs({
          ...inputs,
          [elemId + "Id"]: obj?.value,
        });
        setErrorTexts({
          ...errorTexts,
          bsubSegment: "",
        });
        setIsExistError("");
        break;

      default:
        break;
    }
    validateAutocompleteComponents(event, obj?.label);
  };
  const handleAutocompleteTextChange = (event, text) => {
    let elem = event.target.id;
    let elemId = !elem
      ? ""
      : elem.includes("-")
      ? elem.substr(0, elem.indexOf("-"))
      : elem;
    switch (elemId) {
      case "bunit":
        setDefaultInputs({
          ...defaultInputs,
          [elemId + "Text"]: text,
        });
        let bunitId = bunitData.find((x) => x.label === text)?.value;
        setInputs({
          ...inputs,
          [elemId + "Id"]: bunitId ? bunitId : 0,
        });
        break;
      case "bsegment":
        setDefaultInputs({
          ...defaultInputs,
          [elemId + "Text"]: text,
        });
        let bsegmentId = bsegmentData.find((x) => x.label === text)?.value;
        setInputs({
          ...inputs,
          [elemId + "Id"]: bsegmentId ? bsegmentId : 0,
        });
        break;
      case "bsubSegment":
        setDefaultInputs({
          ...defaultInputs,
          [elemId + "Text"]: text,
        });
        let bsubSegmentId = bsubsegmentData.find(
          (x) => x.label === text
        )?.value;
        setInputs({
          ...inputs,
          [elemId + "Id"]: bsubSegmentId ? bsubSegmentId : 0,
        });
        break;
      default:
        break;
    }
    validateAutocompleteComponents(event, text);
  };

  const handleSubmit = (e) => {
    if (!validateFieldsOnSubmit(e)) {
      e.preventDefault();
      return;
    } else {
      if (props?.rowData) {
        props.handleUpdateData(inputs);
        props.handleCloseDialog(true);
      } else {
        props.handleAddData(inputs);
        props.handleCloseDialog(true);
      }
    }
    
  };

  function isGckExists(gck, bu, bs, bss) {
    const obj = props.tableData.find(
      (c) =>
        c.gck === gck &&
        c.bunitId === bu &&
        c.bsegmentId === bs &&
        c.bsubSegmentId === bss
    );
    return obj ? true : false;
  }
  const validateAutocompleteComponents = (e, value) => {
    let elem = e.target.id;
    let elemId = !elem
      ? ""
      : elem.includes("-")
      ? elem.substr(0, elem.indexOf("-"))
      : elem;
    switch (elemId) {
      case "gck":
        setErrorTexts({
          ...errorTexts,
          [elemId]: !value ? "Mandatory" : "",
          common: "",
        });
        break;
      case "bunit":
        setErrorTexts({
          ...errorTexts,
          [elemId]: !value
            ? "Mandatory"
            : !bunitData.find((x) => x.label === value)
            ? "Invalid selection"
            : "",
          bsegment: !defaultInputs?.bsegmentText ? "Mandatory" : "",
          common: "",
        });
        break;
      case "bsegment":
        setErrorTexts({
          ...errorTexts,
          [elemId]: !value
            ? "Mandatory"
            : !bsegmentData.find((x) => x.label === value)
            ? "Invalid selection"
            : "",
          bsubSegment: !defaultInputs?.bsubSegmentText ? "Mandatory" : "",
          common: "",
        });
        break;
      case "bsubSegment":
        setErrorTexts({
          ...errorTexts,
          [elemId]: !value
            ? "Mandatory"
            : !bsubsegmentData.find((x) => x.label === value)
            ? "Invalid selection"
            : "",
          common: "",
        });
        break;
      default:
        break;
    }
  };
  const validateFieldsOnSubmit = (e) => {
    let ret = true;
    let errors = {};
    if (!defaultInputs?.gck) {
      errors.gck = "Mandatory";
      ret = false;
    }
    if (!defaultInputs?.bunitText) {
      errors.bunit = "Mandatory";
      ret = false;
    } else if (!bunitData.find((x) => x.label === defaultInputs?.bunitText)) {
      errors.bunit = "Invalid selection";
      ret = false;
    }
    if (!defaultInputs?.bsegmentText) {
      errors.bunit = "Mandatory";
      ret = false;
    } else if (
      !bsegmentData.find((x) => x.label === defaultInputs?.bsegmentText)
    ) {
      errors.bsegment = "Invalid selection";
      ret = false;
    }
    if (!defaultInputs?.bsubSegmentText) {
      errors.bunit = "Mandatory";
      ret = false;
    } else if (
      !bsubsegmentData.find((x) => x.label === defaultInputs?.bsubSegmentText)
    ) {
      errors.bsubSegment = "Invalid selection";
      ret = false;
    }
    if (
      (!props?.rowData?.gckId &&
        props?.tableData.find(
          (x) =>
            x.gck === defaultInputs?.gck &&
            x.bunitId === defaultInputs?.bunit?.value &&
            x.bsegmentId === defaultInputs?.bsegment?.value &&
            x.bsubSegmentId === defaultInputs?.bsubSegment?.value
        )) ||
      (props?.rowData?.gckId &&
        props?.tableData.find(
          (x) =>
            x.gckId !== props?.rowData?.gckId &&
            x.gck === defaultInputs?.gck &&
            x.bunitId === defaultInputs?.bunit?.value &&
            x.bsegmentId === defaultInputs?.bsegment?.value &&
            x.bsubSegmentId === defaultInputs?.bsubSegment?.value
        ))
    ) {
      errors.common =
        "This GCK combination already exists, please try different.";
      ret = false;
    }
    setErrorTexts({
      ...errorTexts,
      gck: errors.gck,
      bunit: errors.bunit,
      bsegment: errors.bsegment,
      bsubSegment: errors.bsubSegment,
      common: errors.common,
    });
    return ret;
  };
  const handleClose = () => {
    props.handleCloseDialog(true);
  };
  useEffect(() => {
    getBusinessUnitDetails();
    if (props?.rowData?.bunitId) {
      getBusinessSegmentDetails(props.rowData.bunitId);
    }
    if (props?.rowData?.bunitId && props?.rowData?.bsegmentId) {
      getBusinessSubSegmentDetails(
        props.rowData.bunitId,
        props.rowData.bsegmentId
      );
    }
  }, [props]);

  // useEffect(() => {
  //   if (
  //     props?.rowData &&
  //     props?.bunitData &&
  //     props?.bsegmentData &&
  //     props?.bsubsegmentData
  //   ) {
  //     const gck = props?.rowData?.gck;
  //     const bunit = props?.bunitData;
  //     const bsegment = props?.bsegmentData;
  //     const bsubsegment = props?.bsubsegmentData;
  //     setDefaultInputs({
  //       ...defaultInputs,
  //       gck: gck,
  //       bunit: bunit,
  //       bunitText: bunit?.label,
  //       bsegment: bsegment,
  //       bsegmentText: bsegment?.label,
  //       bsubSegment: bsubsegment,
  //       bsubSegmentText: bsubsegment?.label,
  //     });
  //   }
  // }, [props]);

  const classes = useStyles();
  return (
    <div className={classes.inputForm}>
      <Grid>
        <h3 className="text-center m-0  dialog-header">
          {props?.rowData ? "Edit Data" : "Add Data"}
        </h3>
      </Grid>
      <Grid>
        <h4 style={{ color: "red" }}>{errorTexts?.common}</h4>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            className="mt-1"
            fullWidth
            value={defaultInputs?.gck}
            onChange={handleGckChange}
            error={errorTexts.gck}
            helperText={errorTexts.gck}
            name="gck"
            type="text"
            label="GCK"
            variant="filled"
            id="gck"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Autocomplete
            className="mt-1"
            fullWidth
            name="bunit"
            disableClearable
            freeSolo
            value={defaultInputs?.bunit}
            inputValue={defaultInputs?.bunitText}
            select
            id="bunit"
            options={bunitData}
            disabled={!bunitData.length > 0}
            onChange={handleAutocompleteChange}
            onInputChange={handleAutocompleteTextChange}
            getOptionLabel={(option) => (option.label ? option.label : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                error={errorTexts.bunit}
                helperText={errorTexts.bunit}
                label="Business Unit"
                variant="filled"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Autocomplete
            className="mt-1"
            disableClearable
            freeSolo
            fullWidth
            name="bsegment"
            value={defaultInputs?.bsegment}
            inputValue={defaultInputs?.bsegmentText}
            select
            id="bsegment"
            options={bsegmentData}
            disabled={!bsegmentData.length > 0}
            onChange={handleAutocompleteChange}
            onInputChange={handleAutocompleteTextChange}
            getOptionLabel={(option) => (option.label ? option.label : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                error={errorTexts.bsegment}
                helperText={errorTexts.bsegment}
                label="Business Segment"
                variant="filled"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Autocomplete
            className="mt-1"
            disableClearable
            freeSolo
            fullWidth
            name="bsubSegment"
            value={defaultInputs?.bsubSegment}
            inputValue={defaultInputs?.bsubSegmentText}
            onChange={handleAutocompleteChange}
            onInputChange={handleAutocompleteTextChange}
            select
            id="bsubSegment"
            options={bsubsegmentData}
            disabled={!bsubsegmentData.length > 0}
            getOptionLabel={(option) => (option.label ? option.label : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                error={errorTexts.bsubSegment}
                helperText={errorTexts.bsubSegment}
                label="BusinessSubSegment"
                variant="filled"
              />
            )}
          />
        </Grid>
      </Grid>
      <DialogActions>
        <Button
          onClick={handleClose}
          className="pr-button--secondary "
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          type="submit"
          form="myform"
          className="pt-button--primary button-submit"
          disabled={
            errorTexts?.gck ||
            errorTexts?.bunit ||
            errorTexts?.bsegment ||
            errorTexts?.bsubSegment ||
            errorTexts?.common
          }
        >
          Submit
        </Button>
      </DialogActions>
    </div>
  );
}
