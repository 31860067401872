import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
import {
  getRoundUptoXDecimals,
  tableIconsInputScreen,
} from "../../../Utilities/Utility";
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";
import {
  getSalesOrderInputData,
  updateBudget,
} from "../../../Redux/APIs/api_OrderInputDetails";
import { storeUpdateBudgetResponse } from "../../../Redux/Actions/actions";

export default function BudgetGrid(props) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.saveTokenReducer.token);
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const gridEditable = useSelector((state) => state.isEditableReducer.editable);
    const id = JSON.parse(localStorage.getItem("toggle"));   
    const accessable = useSelector((state) => state.isEditReducer.editables);

  let [data, setData] = useState([]);
  let [potentialData, setPotentialData] = useState([]);
  let [FYs, setFYs] = useState([]);
  let [isLoader, setIsLoader] = useState(true);

  //for Snack Alert
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpen(true);
  };
  const handleClose = (e) => {
    setOpen(false);
  };

  function renderYearValue(value) {
    let obj = {};
    if (FYs && FYs.length > 0) {
      obj = FYs.find((c) => c.id === value);
    }
    if (obj) {
      return obj.name;
    } else {
      return "";
    }
  }
  function renderSOW(data) {
    let objPotential = {};
    if (data && potentialData && potentialData.length > 0) {
      objPotential = potentialData.find(
        (x) => x.salesOrderDetailsId === data?.salesOrderDetailsId
      );
      let totalBudget = data?.budgetDirect + data?.budgetIndirect;
      let totalPotential =
        objPotential?.potentialDirect + objPotential?.potentialIndirect;
      return !totalPotential
        ? 0
        : getRoundUptoXDecimals((totalBudget / totalPotential) * 100, 0);
    } else {
      return "";
    }
  }
  const isFirstRowEditable = false;
    const isSecondRowEditable = false;
 
  var columns = [
    {
      title: "SalesOrderId",
      field: "salesOrderDetailsId",
      hidden: true,
    },
    {
      title: "Year",
      field: "yearId",
      render: (rowData) => <>{renderYearValue(rowData.yearId)}</>,
      editable: "never",
    },
    {
      title: "Direct",
      field: "budgetDirect",
      type: "numeric",
      //editable: isFirstRowEditable == false ? "never" : "onUpdate"
    },
    {
      title: "Indirect",
      field: "budgetIndirect",
        type: "numeric",
       // editable: isFirstRowEditable == false ? "never" : "onUpdate"
    },
    {
      title: "Total",
      field: "total",
      render: (rowData) => (
        <>
          {getRoundUptoXDecimals(
            rowData?.budgetDirect + rowData?.budgetIndirect,
            3
          )}
        </>
      ),
      editable: "never",
    },
    {
      title: "SoW%",
      field: "sow",
      render: (rowData) => <>{renderSOW(rowData)}</>,
      editable: "never",
    },
  ];
  const handleRowUpdate = (newData, oldData, resolve, reject) => {
    setIsLoader(true);
    newData.loggedInUserId = userDetails.userId;
      updateBudget(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId, newData)
        .then((response) => {
            console.log("budget", response);
        if (response.potentialcheck==true) {
          handleSnackOpen(response.responseMsg, "success");
          resolve();
          setIsLoader(false);
          dispatch(storeUpdateBudgetResponse(response.response));
          getDataOnLoad(props.filters);
        } else {
          handleSnackOpen(response.responseMsg, "error");
          reject();
          setIsLoader(false);
        }
      })
      .catch((error) => {
        handleSnackOpen("Exception : " + error, "error");
        reject();
        setIsLoader(false);
      });
  };
  function getFYs(data) {
    setFYs(data);
  }
  function getDataOnLoad(filters) {
    setIsLoader(true);
      getSalesOrderInputData(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId, filters)
      .then((response) => {
        if (response.response) {
          const obj = response.objResult;
          setData(obj.budgetDataList);
          setPotentialData(obj.potentialDataList);
          const callBackBudget = obj?.budgetDataList?.map((c) => {
            return {
              yearId: c.yearId,
              total: getRoundUptoXDecimals(
                c?.budgetDirect + c?.budgetIndirect,
                3
              ),
            };
          });
          props.setBudgetData([...callBackBudget]);
          setIsLoader(false);
        } else {
          setData([]);
          setPotentialData([]);
          setIsLoader(false);
          handleSnackOpen(response.responseMsg, "info");
        }
      })
      .catch((error) => {
        console.log("Error : ", error);
        handleSnackOpen(
          "Exception occured while fetching budget data",
          "error"
        );
        setIsLoader(false);
      });
  }

    function getDisableTooltip(value, type) {
        return value === true
            ? "This action is restricted"
            : type === "Edit"
                ? "Edit"
                : "Delete";
      
  }

  useEffect(() => {
    if (props) {
      getFYs(props.fys);
      }
      console.log("grid",gridEditable);
  }, [props]);
  useEffect(() => {
    if (props.filters) {
      // console.log("Filters : ", props.filters);
      getDataOnLoad(props.filters);
    }
  }, [props.filters]);
  return (
    <>
          <div className="order-input-table">
              {userDetails.roleId !== 10 && accessable != false || (userDetails.roleId != 1 && accessable != false) || userDetails.roleId == 1 ?
                  <MaterialTable
                      title="Budget"
                      columns={columns}
                      data={data}
                      icons={tableIconsInputScreen}
                      isLoading={isLoader}
                      localization={{
                          header: {
                              actions: "Edit",
                          },
                      }}
                      options={{
                          pageSize: 2,
                          toolbar: false,
                          paging: false,
                      }}
                      editable={{
                          isEditable: (rowData) => userDetails.roleId !== 10 ?rowData.isPotBudLockedForCurrentFy === false || rowData.isPotBudLockedForPreviousFy === false ? true : false: false,
                          editTooltip: (rowData) =>
                              getDisableTooltip(rowData.isReadOnly, "Edit"),
                          onRowUpdate: (newData, oldData) =>
                              new Promise((resolve, reject) => {
                                  handleRowUpdate(newData, oldData, resolve, reject);
                              }),
                      }}
                  /> : <MaterialTable
                      title="Budget"
                      columns={columns}
                      data={data}
                      icons={tableIconsInputScreen}
                      isLoading={isLoader}
                      localization={{
                          header: {
                              actions: "Edit",
                          },
                      }}
                      options={{
                          pageSize: 2,
                          toolbar: false,
                          paging: false,
                      }}
                      
                  />}
      </div>
      <>
        <SnackbarCustom
          open={open}
          message={snackMessage}
          alertType={alertType}
          handleClose={handleClose}
        />
      </>
    </>
  );
}
