import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { Grid, makeStyles } from '@material-ui/core';
import { useSelector } from "react-redux";
import DialogActions from '@material-ui/core/DialogActions';
import {getSalesBranchDDL, getSalesTeritoryDDL } from '../../../../../Redux/APIs/api_Common';


export default function SalesOfficeForm(props) {
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const useStyles = makeStyles(() => ({
        inputForm: {
            padding: "85px 50px"

        }
    }));
   
    // API
    let [steritoryData, setSteritoryData] = useState([]);
    function getSalesTeritoryDetails() {
        getSalesTeritoryDDL(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId).then(response => {
            if (response && response.length > 0) {
                setSteritoryData(
                    response.map((item) => ({ value: item.id, label: item.name }))

                );
            } else {
                setSteritoryData([]);
            }
        }).catch(error => console.log(error));
    }
    let [sbranchData, setSbranchData] = useState([]);
    function getSalesBranchDetails() {
        getSalesBranchDDL(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId).then(response => {
            if (response && response.length > 0) {
                setSbranchData(
                    response.map((item) => ({ value: item.id, label: item.name }))

                );
            } else {
                setSbranchData([]);
            }
        }).catch(error => console.log(error));
    }
    const [errorTexts, setErrorTexts] = useState({
        soffice: "",
        steritory: "",
        sbranch:"",
        common: "",
      });
    let [defaultInputs, setDefaultInputs] = useState({
        soffice:props?.rowData?.salesOffice,
        steritory: {
        value: props?.rowData?.steritoryId,
        label: props?.rowData?.salesTerritory,
        },
        steritoryText: props?.rowData?.salesTerritory,
        sbranch: {
        value: props?.rowData?.sbranchId,
        label: props?.rowData?.salesBranch,
        },
        sbranchText: props?.rowData?.salesBranch,
    });
    let [inputs, setInputs] = useState({
        salesOffice: props?.rowData?.salesOffice,
        salesOfficeId:props.rowData?.salesOfficeId,
        steritoryId: props?.rowData?.steritoryId,
        sbranchId : props?.rowData?.sbranchId,
    });
  
    const handleSOfficeChange = (e) => {
        const value = e.target.value;
        setDefaultInputs({
            ...defaultInputs,
            soffice: value,
        });
        setInputs({
            ...inputs,
            salesOffice: value,
        });
        setErrorTexts({
            ...errorTexts,
            soffice: "",
          });
        validateAutocompleteComponents(e, value);
    };

    const handleAutocompleteChange = (event, obj) => {
        let elem = event.target.id;
        let elemId = !elem
            ? ""
            : elem.includes("-")
                ? elem.substr(0, elem.indexOf("-"))
                : elem;
                
        switch (elemId) {
            case "steritory":
                setDefaultInputs({
                    ...defaultInputs,
                    [elemId]: obj,
                    [elemId + "Text"]: obj?.label,
                });

                setInputs({
                    ...inputs,
                    [elemId + "Id"]: obj?.value,
                });
                setErrorTexts({
                    ...errorTexts,
                    steritory: "",
                  });
                break;
            case "sbranch":
                setDefaultInputs({
                    ...defaultInputs,
                    [elemId]: obj,
                    [elemId + "Text"]: obj?.label,
                });
                setInputs({
                    ...inputs,
                    [elemId + "Id"]: obj?.value,
                });
                setErrorTexts({
                    ...errorTexts,
                    sbranch: "",
                  });
                break;
            default:
                break;
        }
        validateAutocompleteComponents(event, obj?.label);
    };
    const handleAutocompleteTextChange = (event, text) => {
        let elem = event.target.id;
        let elemId = !elem
            ? ""
            : elem.includes("-")
                ? elem.substr(0, elem.indexOf("-"))
                : elem;
        switch (elemId) {

            case "steritory":
                setDefaultInputs({
                    ...defaultInputs,
                    [elemId + "Text"]: text,
                });
                let steritoryId = steritoryData.find((x) => x.label === text)?.value;
                setInputs({
                    ...inputs,
                    [elemId + "Id"]: steritoryId ? steritoryId : 0,
                });
                break;
            case "sbranch":
                setDefaultInputs({
                    ...defaultInputs,
                    [elemId + "Text"]: text,
                });
                let sbranchId = sbranchData.find((x) => x.label === text)?.value;
                setInputs({
                    ...inputs,
                    [elemId + "Id"]: sbranchId ? sbranchId : 0,
                });
                break;
          
            default:
                break;
        }
        validateAutocompleteComponents(event, text);
    };
  
    const handleSubmit = (e) => {
       
        if (!validateFieldsOnSubmit(e)) {
            e.preventDefault();
            return;
          } else {
            if (props?.rowData) {
              props.handleUpdateData(inputs);
              props.handleCloseDialog(true);
            } else {
              props.handleAddData(inputs);
              props.handleCloseDialog(true);
            }
          }
    }
    const handleClose = () => {
        props.handleCloseDialog(true);
    }
    const validateAutocompleteComponents = (e, value) => {
        let elem = e.target.id;
        let elemId = !elem
          ? ""
          : elem.includes("-")
          ? elem.substr(0, elem.indexOf("-"))
          : elem;
        switch (elemId) {
          case "soffice":
            setErrorTexts({
              ...errorTexts,
              [elemId]: !value ? "Mandatory" : "",
              common:"",
            });
            break;
          case "steritory":
            setErrorTexts({
              ...errorTexts,
              [elemId]: !value
                ? "Mandatory"
                : !steritoryData.find((x) => x.label === value)
                ? "Invalid selection"
                : "",
                common:"",
            });
            break;
          case "sbranch":
            setErrorTexts({
              ...errorTexts,
              [elemId]: !value
                ? "Mandatory"
                : !sbranchData.find((x) => x.label === value)
                ? "Invalid selection"
                : "",
                common:"",
            });
            break;
          default:
            break;
        }
      };
      const validateFieldsOnSubmit = (e) => {
        let ret = true;
        let errors = {};
        if (!defaultInputs?.soffice) {
          errors.soffice = "Mandatory";
          ret = false;
        }
        if (!defaultInputs?.steritoryText) {
          errors.steritory = "Mandatory";
          ret = false;
        } else if (!steritoryData.find((x) => x.label === defaultInputs?.steritoryText)) {
          errors.steritory = "Invalid selection";
          ret = false;
        }
        if (!defaultInputs?.sbranchText) {
           errors.sbranch = "Mandatory";
          ret = false;
        } else if (
          !sbranchData.find((x) => x.label === defaultInputs?.sbranchText)
        ) {
          errors.sbranch = "Invalid selection";
          ret = false;
        }
        if (
          (!props?.rowData?.salesOfficeId &&
            props?.tableData.find(
              (x) =>
                x.salesOffice === defaultInputs?.soffice &&
                x.steritoryId === defaultInputs?.steritory?.value &&
                x.sbranchId === defaultInputs?.sbranch?.value
            )) ||
          (props?.rowData?.salesOfficeId &&
            props?.tableData.find(
              (x) =>
                x.salesOfficeId !== props?.rowData?.salesOfficeId &&
                x.salesOffice === defaultInputs?.soffice &&
                x.steritoryId === defaultInputs?.steritory?.value &&
                x.sbranchId === defaultInputs?.sbranch?.value
            ))
        ) {
          errors.common =
            "This Sales Office combination already exists, please try different.";
          ret = false;
        }
        setErrorTexts({
          ...errorTexts,
          steritory: errors.steritory,
          sbranch: errors.sbranch,
          soffice: errors.soffice,
          common: errors.common,
        });
        return ret;
      };
    useEffect(() => {
        getSalesTeritoryDetails();
        getSalesBranchDetails();   
    }, []);

    const classes = useStyles();
    return (
        <div className={classes.inputForm} >
            <Grid>
                <h3 className="text-center m-0  dialog-header">
                    {props?.rowData?"Edit Data":"Add Data"}
                </h3>
            </Grid>
            <Grid>
            <h4 style={{ color: "red" }}>{errorTexts?.common}</h4>
          </Grid>
            <Grid container spacing={3}>

                <Grid item xs={12} sm={12} md={6}>

                    <TextField
                        className="mt-1"
                        fullWidth
                        value={defaultInputs?.soffice}
                        onChange={handleSOfficeChange}
                        error={errorTexts.soffice}
                        helperText={errorTexts.soffice}
                        name="soffice"
                        type="text"
                        label="Sales Office"
                        variant="filled"
                        id="soffice"
                    />

                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Autocomplete
                        className="mt-1"
                        fullWidth
                        name="steritory"
                        disableClearable
                        freeSolo
                        value={defaultInputs?.steritory}
                        inputValue={defaultInputs?.steritoryText}
                        select
                        id="steritory"
                        options={steritoryData}
                        disabled={!steritoryData.length > 0}
                        onChange={handleAutocompleteChange}
                        onInputChange={handleAutocompleteTextChange}
                        getOptionLabel={(option) => (option.label ? option.label : "")}
                        renderInput={(params) => (
                            <TextField {...params} 
                            error={errorTexts.steritory}
                            helperText={errorTexts.steritory}
                            label="Sales Teritory"
                             variant="filled" />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Autocomplete
                        className="mt-1"
                        disableClearable
                        freeSolo
                        fullWidth
                        name="sbranch"
                        value={defaultInputs?.sbranch}
                        inputValue={defaultInputs?.sbranchText}
                        select
                        id="sbranch"
                        options={sbranchData}
                        disabled={!sbranchData.length > 0}
                        onChange={handleAutocompleteChange}
                        onInputChange={handleAutocompleteTextChange}
                        getOptionLabel={(option) => (option.label ? option.label : "")}
                        renderInput={(params) => (
                            <TextField {...params} 
                            label="Sales Branch"
                            error={errorTexts.sbranch}
                            helperText={errorTexts.sbranch}
                             variant="filled" />
                        )}
                    />
                </Grid>
             
            </Grid>
            <DialogActions>
                <Button onClick={handleClose} className="pr-button--secondary " variant="contained">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} type="submit" form="myform" className="pt-button--primary button-submit" 
                   disabled={
                    errorTexts?.steritory||
                    errorTexts?.sbranch ||
                    errorTexts?.soffice ||
                    errorTexts?.common
                   }>
                    Submit
                </Button>
            </DialogActions>
        </div>

    );
}
