import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { Grid, makeStyles } from '@material-ui/core';
import { useSelector } from "react-redux";
import DialogActions from '@material-ui/core/DialogActions';
import {getSalesBranchDDL, getSalesTeritoryDDL,getSalesOfficeDDL,getSalesDepartmentDDL} from '../../../../../Redux/APIs/api_Common';


export default function SalesDistrictForm(props) {
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const useStyles = makeStyles(() => ({
        inputForm: {
            padding: "85px 50px"

        }
    }));

    // API
    let [steritoryData, setSteritoryData] = useState([]);
    function getSalesTeritoryDetails() {
        getSalesTeritoryDDL(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId).then(response => {
            if (response && response.length > 0) {
                setSteritoryData(
                    response.map((item) => ({ value: item.id, label: item.name }))

                );
            } else {
                setSteritoryData([]);
            }
        }).catch(error => console.log(error));
    }
    let [sbranchData, setSbranchData] = useState([]);
    function getSalesBranchDetails() {
        getSalesBranchDDL(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId).then(response => {
            if (response && response.length > 0) {
                setSbranchData(
                    response.map((item) => ({ value: item.id, label: item.name }))

                );
            } else {
                setSbranchData([]);
            }
        }).catch(error => console.log(error));
    }
    let [sofficeData, setSofficeData] = useState([]);
    function getSalesOfficeDetails(st,sb) {
        getSalesOfficeDDL(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId,st,sb).then(response => {
            if (response && response.length > 0) {
                setSofficeData(
                    response.map((item) => ({ value: item.id, label: item.name }))

                );
            } else {
                setSofficeData([]);
            }
        }).catch(error => console.log(error));
    }
    let [sdepartmentData, setSdepartmentData] = useState([]);
    function getSalesDepartmentDetails(st,sb,so) {
        getSalesDepartmentDDL(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId,st,sb,so).then(response => {
            if (response && response.length > 0) {
                setSdepartmentData(
                    response.map((item) => ({ value: item.id, label: item.name }))

                );
            } else {
                setSdepartmentData({});
            }
        }).catch(error => console.log(error));
    }
    const [errorTexts, setErrorTexts] = useState({
        sdistrict:"",
        sdepartment: "",
        sbranch:"",
        soffice:"",
        steritory: "",
        common: "",
      });
    let [defaultInputs, setDefaultInputs] = useState({
        sdistrict: props?.rowData?.salesDistrict,
        sdepartment:{
            value:props?.rowData?.sdepartmentId,
            label:props?.rowData?.salesdepartment
        },
        sdepartmentText:props?.rowData?.salesdepartment,
        soffice:{
            value:props?.rowData?.sofficeId,
            label:props?.rowData?.salesoffice
        },
        sofficeText:props?.rowData?.salesoffice,
        steritory: {
        value: props?.rowData?.steritoryId,
        label: props?.rowData?.salesteritory,
        },
        steritoryText: props?.rowData?.salesteritory,
        sbranch: {
        value: props?.rowData?.sbranchId,
        label: props?.rowData?.salesbranch,
        },
        sbranchText: props?.rowData?.salesbranch,
    });
    let [inputs, setInputs] = useState({
        salesDistrictId: props?.rowData?.salesDistrictId,
        salesDistrict: props?.rowData?.salesDistrict,
        sdepartmentId:props?.rowData?.sdepartmentId,
        sofficeId:props?.rowData?.sofficeId,
        steritoryId: props?.rowData?.steritoryId,
        sbranchId : props?.rowData?.sbranchId,
    });
   
    const handleSDistrictChange = (e) => {
        const value = e.target.value;
        setDefaultInputs({
            ...defaultInputs,
            sdistrict: value,
        });
        setInputs({
            ...inputs,
            salesDistrict: value,
        });
        setErrorTexts({
            ...errorTexts,
            sdistrict: "",
          });
        validateAutocompleteComponents(e, value);
    };

    const handleAutocompleteChange = (event, obj) => {
        let elem = event.target.id;
        let elemId = !elem
            ? ""
            : elem.includes("-")
                ? elem.substr(0, elem.indexOf("-"))
                : elem;
        switch (elemId) {
            case "steritory":
                setDefaultInputs({
                    ...defaultInputs,
                    [elemId]: obj,
                    [elemId + "Text"]: obj?.label,
                });

                setInputs({
                    ...inputs,
                    [elemId + "Id"]: obj?.value,
                });
                setErrorTexts({
                    ...errorTexts,
                    steritory: "",
                  });
                break;
            case "sbranch":
                setDefaultInputs({
                    ...defaultInputs,
                    [elemId]: obj,
                    [elemId + "Text"]: obj?.label,
                });
                setInputs({
                    ...inputs,
                    [elemId + "Id"]: obj?.value,
                });
                setErrorTexts({
                    ...errorTexts,
                    sbranch: "",
                  });
                getSalesOfficeDetails(inputs.steritoryId,obj.value)
                break;
                case "soffice":
                    setDefaultInputs({
                        ...defaultInputs,
                        [elemId]: obj,
                        [elemId + "Text"]: obj?.label,
                    });
                    setInputs({
                        ...inputs,
                        [elemId + "Id"]: obj?.value,
                    });
                    setErrorTexts({
                        ...errorTexts,
                        soffice: "",
                      });
                    getSalesDepartmentDetails(inputs.steritoryId,inputs.sbranchId,obj.value)
                    break;
                    case "sdepartment":
                        setDefaultInputs({
                            ...defaultInputs,
                            [elemId]: obj,
                            [elemId + "Text"]: obj?.label,
                        });
                        setInputs({
                            ...inputs,
                            [elemId + "Id"]: obj?.value,
                        });
                        setErrorTexts({
                            ...errorTexts,
                            sdepartment: "",
                          });
                        break;
            default:
                break;
        }
        validateAutocompleteComponents(event, obj?.label);
    };
    const handleAutocompleteTextChange = (event, text) => {
        let elem = event.target.id;
        let elemId = !elem
            ? ""
            : elem.includes("-")
                ? elem.substr(0, elem.indexOf("-"))
                : elem;
        switch (elemId) {

            case "steritory":
                setDefaultInputs({
                    ...defaultInputs,
                    [elemId + "Text"]: text,
                });
                let steritoryId = steritoryData.find((x) => x.label === text)?.value;
                setInputs({
                    ...inputs,
                    [elemId + "Id"]: steritoryId ? steritoryId : 0,
                });
                break;
            case "sbranch":
                setDefaultInputs({
                    ...defaultInputs,
                    [elemId + "Text"]: text,
                });
                let sbranchId = sbranchData.find((x) => x.label === text)?.value;
                setInputs({
                    ...inputs,
                    [elemId + "Id"]: sbranchId ? sbranchId : 0,
                });
                break;
                case "soffice":
                    setDefaultInputs({
                        ...defaultInputs,
                        [elemId + "Text"]: text,
                    });
                    let sofficeId = sofficeData.find((x) => x.label === text)?.value;
                    setInputs({
                        ...inputs,
                        [elemId + "Id"]: sofficeId ? sofficeId : 0,
                    });
                    break;
                    case "sdepartment":
                        setDefaultInputs({
                            ...defaultInputs,
                            [elemId + "Text"]: text,
                        });
                        let sdepartmentId = sdepartmentData.find((x) => x.label === text)?.value;
                        setInputs({
                            ...inputs,
                            [elemId + "Id"]: sdepartmentId? sdepartmentId : 0,
                        });
                        break;
            default:
                break;
        }
        validateAutocompleteComponents(event, text);
    };
  
    const handleSubmit = (e) => {
        if (!validateFieldsOnSubmit(e)) {
            e.preventDefault();
            return;
          } else {
            if (props?.rowData) {
              props.handleUpdateData(inputs);
              props.handleCloseDialog(true);
            } else {
              props.handleAddData(inputs);
              props.handleCloseDialog(true);
            }
          }
    }
    const validateAutocompleteComponents = (e, value) => {
        let elem = e.target.id;
        let elemId = !elem
          ? ""
          : elem.includes("-")
          ? elem.substr(0, elem.indexOf("-"))
          : elem;
        switch (elemId) {
            case "sdistrict":
                setErrorTexts({
                  ...errorTexts,
                  [elemId]: !value ? "Mandatory"
                  : "",
                  common:""
                });
                break;
          
          case "steritory":
            setErrorTexts({
              ...errorTexts,
              [elemId]: !value
                ? "Mandatory"
                : !steritoryData.find((x) => x.label === value)
                ? "Invalid selection"
                : "",
                common:""
            });
            break;
          case "sbranch":
            setErrorTexts({
              ...errorTexts,
              [elemId]: !value
                ? "Mandatory"
                : !sbranchData.find((x) => x.label === value)
                ? "Invalid selection"
                : "",
                 soffice: !defaultInputs?.sofficeText ? "Mandatory" : "",
                 common:"",
             
            });
            break;
            case "soffice":
                setErrorTexts({
                  ...errorTexts,
                  [elemId]: !value ? "Mandatory" : !sofficeData.find((x)=>x.label===value)
                  ?"Invalid selection"
                  :"",
                  sdepartment: !defaultInputs?.sdepartmentText ? "Mandatory" : "",
                  common:"",
                });
                break;
                case "sdepartment":
                    setErrorTexts({
                      ...errorTexts,
                      [elemId]: !value ? "Mandatory"
                      :!sdepartmentData.find((x)=>x.label===value)
                      ?"Invalid selection"
                      : "",
                      common:""
                    });
                    break;
          default:
            break;
        }
      };
      const validateFieldsOnSubmit = (e) => {
        let ret = true;
        let errors = {};
        if (!defaultInputs?.sdistrict) {
            errors.sdistrict = "Mandatory";
            ret = false;
          }
          if (!defaultInputs?.steritoryText) {
            errors.steritory = "Mandatory";
            ret = false;
          } else if (!steritoryData.find((x) => x.label === defaultInputs?.steritoryText)) {
            errors.steritory = "Invalid selection";
            ret = false;
          }
          if (!defaultInputs?.sbranchText) {
            errors.sbranch = "Mandatory";
           ret = false;
         } else if (
           !sbranchData.find((x) => x.label === defaultInputs?.sbranchText)
         ) {
           errors.sbranch = "Invalid selection";
           ret = false;
         }
        if (!defaultInputs?.sofficeText) {
          errors.steritory = "Mandatory";
          ret = false;
        } else if (!sofficeData.find((x) => x.label === defaultInputs?.sofficeText)){
            errors.soffice = "Invalid selection";
            ret = false;
        }
        if (!defaultInputs?.sdepartmentText) {
            errors.steritory  = "Mandatory";
            ret = false;
          } else if (!sdepartmentData.find((x) => x.label === defaultInputs?.sdepartmentText)) {
            errors.sdepartment = "Invalid selection";
            ret = false;
          }
      
      
        if (
          (!props?.rowData?.salesDistrictId&&
            props?.tableData.find(
              (x) =>
                x.salesDistrict=== defaultInputs?.sdistrict &&
                x.sdepartmentId===defaultInputs?.sdepartment?.value&&
                x.sofficeId=== defaultInputs?.soffice?.value &&
                x.steritoryId === defaultInputs?.steritory?.value &&
                x.sbranchId === defaultInputs?.sbranch?.value
            )) ||
          (props?.rowData?.salesDistrictId &&
            props?.tableData.find(
              (x) =>
              x.salesDistrictId !== props?.rowData?.salesDistrictId&&
                x.salesDistrict=== defaultInputs?.sdistrict &&
                x.sdepartmentId===defaultInputs?.sdepartment?.value&&
                x.sofficeId === defaultInputs?.soffice?.value &&
                x.steritoryId === defaultInputs?.steritory?.value &&
                x.sbranchId === defaultInputs?.sbranch?.value
            ))
        ) {
          errors.common =
            "This Sales Department combination already exists, please try different.";
          ret = false;
        }
        setErrorTexts({
          ...errorTexts,
          steritory: errors.steritory,
          sbranch: errors.sbranch,
          soffice: errors.soffice,
          sdepartment:errors.sdepartment,
          sdistrict:errors.sdistrict,
          common: errors.common,
        });
        return ret;
      };
    const handleClose = () => {
        props.handleCloseDialog(true);
    }
    useEffect(() => {
        getSalesTeritoryDetails();
        getSalesBranchDetails();
        if (props?.rowData?.steritoryId && props?.rowData?.sbranchId) {
            getSalesOfficeDetails(props.rowData.steritoryId && props.rowData.sbranchId);
        }
        if (props?.rowData?.steritoryId && props?.rowData?.sbranchId && props?.rowData.sofficeId) {
            getSalesDepartmentDetails(props.rowData.steritoryId && props.rowData.sbranchId && props?.rowData.sofficeId);
        }
       
    }, [props]);


    const classes = useStyles();
    return (
        <div className={classes.inputForm} >
            <Grid>
                <h3 className="text-center m-0  dialog-header">
                    {props?.rowData?"Edit Data":"Add Data"}
                </h3>
            </Grid>
            <Grid>
            <h4 style={{ color: "red" }}>{errorTexts?.common}</h4>
          </Grid>
            <Grid container spacing={3}>

                <Grid item xs={12} sm={12} md={6}>

                    <TextField
                        className="mt-1"
                        fullWidth
                        value={defaultInputs?.sdistrict}
                        onChange={handleSDistrictChange}
                        error={errorTexts.sdistrict}
                        helperText={errorTexts.sdistrict}
                        name="sdistrict"
                        type="text"
                        label="Sales Engineer"
                        variant="filled"
                        id="sdistrict"
                    />

                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Autocomplete
                        className="mt-1"
                        fullWidth
                        name="steritory"
                        disableClearable
                        freeSolo
                        value={defaultInputs?.steritory}
                        inputValue={defaultInputs?.steritoryText}
                        select
                        id="steritory"
                        options={steritoryData}
                        disabled={!steritoryData.length > 0}
                        onChange={handleAutocompleteChange}
                        onInputChange={handleAutocompleteTextChange}
                        getOptionLabel={(option) => (option.label ? option.label : "")}
                        renderInput={(params) => (
                            <TextField {...params} label="Sales Teritory" variant="filled"
                            error={errorTexts.steritory}
                            helperText={errorTexts.steritory}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Autocomplete
                        className="mt-1"
                        disableClearable
                        freeSolo
                        fullWidth
                        name="sbranch"
                        value={defaultInputs?.sbranch}
                        inputValue={defaultInputs?.sbranchText}
                        select
                        id="sbranch"
                        options={sbranchData}
                         disabled={!sbranchData.length > 0}
                        onChange={handleAutocompleteChange}
                        onInputChange={handleAutocompleteTextChange}
                        getOptionLabel={(option) => (option.label ? option.label : "")}
                        renderInput={(params) => (
                            <TextField {...params} label="Sales Branch" variant="filled"
                            error={errorTexts.sbranch}
                            helperText={errorTexts.sbranch}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                <Autocomplete
                    className="mt-1"
                    disableClearable
                    freeSolo
                    fullWidth
                    name="soffice"
                    value={defaultInputs?.soffice}
                    inputValue={defaultInputs?.sofficeText}
                    select
                    id="soffice"
                    options={sofficeData}
                    disabled={!sofficeData.length > 0}
                    onChange={handleAutocompleteChange}
                    onInputChange={handleAutocompleteTextChange}
                    getOptionLabel={(option) => (option.label ? option.label : "")}
                    renderInput={(params) => (
                        <TextField {...params} label="Sales Office" variant="filled" 
                        error={errorTexts.soffice}
                        helperText={errorTexts.soffice}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
            <Autocomplete
                className="mt-1"
                disableClearable
                freeSolo
                fullWidth
                name="sdepartment"
                value={defaultInputs?.sdepartment}
                inputValue={defaultInputs?.sdepartmentText}
                select
                id="sdepartment"
                options={sdepartmentData}
               disabled={!sdepartmentData.length > 0}
                onChange={handleAutocompleteChange}
                onInputChange={handleAutocompleteTextChange}
                getOptionLabel={(option) => (option.label ? option.label : "")}
                renderInput={(params) => (
                    <TextField {...params} label="Sales Department" variant="filled"
                    error={errorTexts.sdepartment}
                    helperText={errorTexts.sdepartment}
                    />
                )}
            />
        </Grid>
            </Grid>
            <DialogActions>
                <Button onClick={handleClose} className="pr-button--secondary " variant="contained">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} type="submit"
                disabled={
                    errorTexts?.sdistrict||
                    errorTexts?.sdepartment||
                    errorTexts?.steritory||
                    errorTexts?.sbranch ||
                    errorTexts?.soffice ||
                    errorTexts?.common
                   }
                  form="myform" className="pt-button--primary button-submit" >
                    Submit
                </Button>
            </DialogActions>
        </div>

    );
}
