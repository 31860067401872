import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import { Grid, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import DialogActions from "@material-ui/core/DialogActions";
import { getBusinessUnitDDL } from "../../../../../Redux/APIs/api_Common";

export default function BusinessSegmentForm(props) {
  const token = useSelector((state) => state.saveTokenReducer.token);
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
  const useStyles = makeStyles(() => ({
    inputForm: {
      padding: "85px 50px",
    },
  }));
  const [errorTexts, setErrorTexts] = useState({
    bunit: "",
    businessSegment: "",
    common: "",
  });
  // API
  let [bunitData, setBunitData] = useState([]);
  function getBusinessUnitDetails() {
      getBusinessUnitDDL(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId)
      .then((response) => {
        if (response && response.length > 0) {
          setBunitData(
            response.map((item) => ({ value: item.id, label: item.name }))
          );
        } else {
          setBunitData([]);
        }
      })
      .catch((error) => console.log(error));
  }

  let [defaultInputs, setDefaultInputs] = useState({
    bunit: {
      value: props?.rowData?.bunitId,
      label: props?.rowData?.bunit,
    },
    bunitText: props?.rowData?.bunit,
    businessSegment: props?.rowData?.businessSegment,
  });
  let [inputs, setInputs] = useState({
    bunitId: props?.rowData?.bunitId,
    businessSegment: props?.rowData?.businessSegment,
    businessSegmentId: props?.rowData?.businessSegmentId,
  });
  const handleBsChange = (e) => {
    const value = e.target.value;
    setDefaultInputs({
      ...defaultInputs,
      businessSegment: value,
    });
    setInputs({
      ...inputs,
      businessSegment: value,
    });
    validateAutocompleteComponents(e, value);
  };
  const handleAutocompleteChange = (event, obj) => {
    let elem = event.target.id;
    let elemId = !elem
      ? ""
      : elem.includes("-")
      ? elem.substr(0, elem.indexOf("-"))
      : elem;
    switch (elemId) {
      case "bunit":
        setDefaultInputs({
          ...defaultInputs,
          [elemId]: obj,
          [elemId + "Text"]: obj?.label,
        });

        setInputs({
          ...inputs,
          [elemId + "Id"]: obj?.value,
        });
        break;
      default:
        break;
    }
    validateAutocompleteComponents(event, obj?.label);
  };
  const handleAutocompleteTextChange = (event, text) => {
    let elem = event.target.id;
    let elemId = !elem
      ? ""
      : elem.includes("-")
      ? elem.substr(0, elem.indexOf("-"))
      : elem;
    switch (elemId) {
      case "bunit":
        setDefaultInputs({
          ...defaultInputs,
          [elemId + "Text"]: text,
        });
        let bunitId = bunitData.find((x) => x.label === text)?.value;
        setInputs({
          ...inputs,
          [elemId + "Id"]: bunitId ? bunitId : 0,
        });
        break;
      default:
        break;
    }
    validateAutocompleteComponents(event, text);
  };
  const validateAutocompleteComponents = (e, value) => {
    let elem = e.target.id;
    let elemId = !elem
      ? ""
      : elem.includes("-")
      ? elem.substr(0, elem.indexOf("-"))
      : elem;
    switch (elemId) {
      case "businessSegment":
        setErrorTexts({
          ...errorTexts,
          [elemId]: !value ? "Mandatory" : "",
          common: "",
        });
        break;
      case "bunit":
        setErrorTexts({
          ...errorTexts,
          [elemId]: !value
            ? "Mandatory"
            : !bunitData.find((x) => x.label === value)
            ? "Invalid selection"
            : "",
          common: "",
        });
        break;
      default:
        break;
    }
  };
  const validateFieldsOnSubmit = (e) => {
    let ret = true;
    let errors = {};
    if (!defaultInputs?.businessSegment) {
      errors.businessSegment = "Mandatory";
      ret = false;
    }
    if (!defaultInputs?.bunitText) {
      errors.bunit = "Mandatory";
      ret = false;
    } else if (!bunitData.find((x) => x.label === defaultInputs?.bunitText)) {
      errors.bunit = "Invalid selection";
      ret = false;
    }
    if (
      (!props?.rowData?.businessSegmentId &&
        props?.tableData.find(
          (x) =>
            x.businessSegment === defaultInputs?.businessSegment &&
            x.bunitId === defaultInputs?.bunit?.value
        )) ||
      (props?.rowData?.businessSegmentId &&
        props?.tableData.find(
          (x) =>
            x.businessSegmentId !== props?.rowData?.businessSegmentId &&
            x.businessSegment === defaultInputs?.businessSegment &&
            x.bunitId === defaultInputs?.bunit?.value
        ))
    ) {
      errors.common =
        "This Business Segment combination already exists, please try different.";
      ret = false;
    }
    setErrorTexts({
      ...errorTexts,
      bunit: errors.bunit,
      businessSegment: errors.businessSegment,
      common: errors.common,
    });
    return ret;
  };
  const handleSubmit = (e) => {
    if (!validateFieldsOnSubmit(e)) {
      e.preventDefault();
      return;
    } else {
      if (props?.rowData) {
        props.handleUpdateData(inputs);
        props.handleCloseDialog(true);
      } else {
        props.handleAddData(inputs);
        props.handleCloseDialog(true);
      }
    }
    // if(props?.rowData){
    //     let EditInputs={
    //         businessSegmentId:props.rowData?.businessSegmentId,
    //         businessSegment: inputs.businessSegment? inputs.businessSegment: props.rowData.businessSegment,
    //         bunitId: inputs.bunitId? inputs.bunitId: props.rowData.bunitId,
    //     };

    //     props.handleUpdateData(EditInputs)
    // }
    // else{
    //     let AddInputs={
    //         businessSegment: inputs.businessSegment,
    //         bunitId: inputs.bunitId
    //     };

    //    props.handleAddData(AddInputs)
    // }
    // props.handleCloseDialog(true);
  };
  const handleClose = () => {
    props.handleCloseDialog(true);
  };
  useEffect(() => {
    getBusinessUnitDetails();
  }, [props]);

  //   useEffect(() => {
  //     if (props?.rowData && props?.bunitData) {
  //       const businessSegment = props?.rowData?.businessSegment;
  //       const bunit = props?.bunitData;

  //       setDefaultInputs({
  //         ...defaultInputs,
  //         businessSegment: businessSegment,
  //         bunit: bunit,
  //         bunitText: bunit?.label,
  //       });
  //     }
  //   }, [props]);

  const classes = useStyles();
  return (
    <div className={classes.inputForm}>
      <Grid>
        <h3 className="text-center m-0  dialog-header">
          {props?.rowData ? "Edit Data" : "Add Data"}
        </h3>
      </Grid>
      <Grid>
        <h4 style={{ color: "red" }}>{errorTexts?.common}</h4>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            className="mt-1"
            fullWidth
            value={defaultInputs?.businessSegment}
            onChange={handleBsChange}
            name="businessSegment"
            type="text"
            label="Business Segment"
            variant="filled"
            id="businessSegment"
            error={errorTexts.businessSegment}
            helperText={errorTexts.businessSegment}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Autocomplete
            className="mt-1"
            fullWidth
            name="bunit"
            disableClearable
            freeSolo
            value={defaultInputs?.bunit}
            inputValue={defaultInputs?.bunitText}
            select
            id="bunit"
            options={bunitData}
            disabled={!bunitData.length > 0}
            onChange={handleAutocompleteChange}
            onInputChange={handleAutocompleteTextChange}
            getOptionLabel={(option) => (option.label ? option.label : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Business Unit"
                variant="filled"
                error={errorTexts.bunit}
                helperText={errorTexts.bunit}
              />
            )}
          />
        </Grid>
      </Grid>
      <DialogActions>
        <Button onClick={handleClose} className="pr-button--secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          type="submit"
          form="myform"
          className="pt-button--primary button-submit"
          disabled={
            errorTexts?.bunit ||
            errorTexts?.businessSegment ||
            errorTexts?.common
          }
        >
          Submit
        </Button>
      </DialogActions>
    </div>
  );
}
