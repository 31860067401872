import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Button } from "@material-ui/core";
import {
  getBusinessSubSegmentData,
  addBusinessSubSegmentData,
  updateBusinessSubSegmentData,
  deleteBusinessSubSegmentData,
} from "../../../../Redux/APIs/api_MasterData";
import {
  GetAddButton,
  GetEditButton,
  tableIcons,
} from "../../../../Utilities/Utility";
import { tableOptionsUser } from "../../../../Themes/LightTheme";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import SnackbarCustom from "../../../../Components/Snackbar/Snackbar";
import {
  getBusinessUnitDDL,
  getBusinessSegmentDDL,
} from "../../../../Redux/APIs/api_Common";
import BusinessSubSegmentForm from "../MasterDataForms/CustomerGckSegmentsForms/BusinessSubSegmentForm";
export default function BusinessSubSegmentMaster(props) {
  const tableRef = useRef();
  const icon = () => {
    return (
      <GetAppRoundedIcon
        style={{ fontSize: 53, color: `var(--btn-primary-bg-color)` }}
      />
    );
  };
  const token = useSelector((state) => state.saveTokenReducer.token);
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
  const [tableData, setTableData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpen(true);
  };
  const handleClose = (e) => {
    setOpen(false);
  };
  const [rowData, setRowData] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpenDialog = (data) => {
    setRowData(data);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleSubmit = () => {
    handleCloseDialog(true);
  };
  const columns = [
    {
      title: "BusinessSubSegment Id",
      field: "businessSubSegmentId",
      hidden: true,
    },
    { title: "Business SubSegment", field: "businessSubSegment" },
    {
      title: "Business Segment",
      field: "bsegmentId",
      render: (rowData) => (
        <span>{renderBSegmentValue(rowData.bsegmentId)}</span>
      ),
      customFilterAndSearch: (term, rowData) =>
        renderBSegmentValue(rowData.bsegmentId)
          .toLowerCase()
          .includes(term.toLowerCase()),
    },
    {
      title: "Business Unit",
      field: "bunitId",
      render: (rowData) => <span>{renderBunitValue(rowData.bunitId)}</span>,
      customFilterAndSearch: (term, rowData) =>
        renderBunitValue(rowData.bunitId)
          .toLowerCase()
          .includes(term.toLowerCase()),
    },
  ];
  const actions = [
    {
      icon: GetAddButton,
      tooltip: "Add Business Sub Segment",
      onClick: () => handleClickOpenDialog(),
      isFreeAction: true,
    },
    {
      icon: icon,
      tooltip: "Export to Excel",
      onClick: () => ExportToExcel(),
      isFreeAction: true,
    },
    (rowData) => ({
      icon: GetEditButton,
      disabled:rowData.isreadonlybsubsegmentgck,
      tooltip:getDisableTooltip(rowData.isreadonlybsubsegmentgck,"Edit"),
      onClick: (event, rowData) => handleClickOpenDialog(rowData),
      isFreeAction: true,
    }),
  ];
  
  //Api
  let [bunitData, setBunitData] = useState([]);
  async function getBusinessUnitDetails() {
      const response = await getBusinessUnitDDL(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId);
    if (response && response.length > 0) {
      setBunitData(
        response.map((item) => ({ value: item.id, label: item.name }))
      );
    } else {
      setBunitData([]);
    }
  }
  let [bsegmentData, setBsegmentData] = useState([]);
  async function getBusinessSegmentDetails() {
      const response = await getBusinessSegmentDDL(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId);
    if (response && response.length > 0) {
      setBsegmentData(
        response.map((item) => ({ value: item.id, label: item.name }))
      );
    } else {
      setBsegmentData([]);
    }
  }
  //render value
  function renderBunitValue(bunit) {
    let obj = {};
    if (bunitData && bunitData.length > 0) {
      obj = bunitData.find((c) => c.value == bunit);
    }
    if (obj) {
      return obj.label;
    } else {
      return "";
    }
  }

  function renderBSegmentValue(bsegment) {
    let obj = {};
    if (bsegmentData && bsegmentData.length > 0) {
      obj = bsegmentData.find((c) => c.value == bsegment);
    }
    if (obj) {
      return obj.label;
    } else {
      return "";
    }
  }

  function getTableData() {
    setIsLoader(true);
      getBusinessSubSegmentData(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId)
      .then((response) => {
        setTableData(response);
        setIsLoader(false);
      })
      .catch((error) => {
        handleSnackOpen("Error : " + error, "error");
        setIsLoader(false);
      });
  }
  useEffect(() => {
    getTableData();
    getBusinessUnitDetails();
    getBusinessSegmentDetails();
  }, [userDetails]);

  useEffect(()=>{
    getTableData();  
},[props.gckSegments.gck])

  const handleUpdateData = (data) => {
    setIsLoader(true);
    data.loggedinUserId = userDetails.userId;
      updateBusinessSubSegmentData(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId, data)
      .then((response) => {
        if (response.response) {
          handleSnackOpen(response.responseMsg, "success");
          setIsLoader(false);
          getTableData();
          props.setGckSegments({
            ...props.gckSegments,
            bsubsegment:(props.gckSegments.bsubsegment)+1
          });
        } else {
          handleSnackOpen(response.responseMsg, "error");
          setIsLoader(false);
        }
      })
      .catch((error) => {
        handleSnackOpen("Exception : " + error, "error");
        setIsLoader(false);
      });   
  };

  function handleAddData(data) {
    setIsLoader(true);
    data.loggedinUserId = userDetails.userId;
      addBusinessSubSegmentData(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId, data)
      .then((response) => {
        if (response.response) {
          handleSnackOpen(response.responseMsg, "success");
          setIsLoader(false);
          getTableData();
          props.setGckSegments({
            ...props.gckSegments,
            bsubsegment:(props.gckSegments.bsubsegment)+1
          });
        } else {
          handleSnackOpen(response.responseMsg, "error");
          setIsLoader(false);
        }
      })
      .catch((error) => {
        handleSnackOpen("Exception : " + error, "error");
        setIsLoader(false);
      });
      
  }
  const handleRowDelete = (oldTableData, resolve, reject) => {
    setIsLoader(true);
    oldTableData.loggedinUserId = userDetails.userId;
      deleteBusinessSubSegmentData(siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId, oldTableData)
      .then((response) => {
        if (response.response) {
          handleSnackOpen(response.responseMsg, "success");
          resolve();
          setIsLoader(false);
          getTableData();
          props.setGckSegments({
            ...props.gckSegments,
            bsubsegment:(props.gckSegments.bsubsegment)+1
          });
        } else {
          handleSnackOpen(response.responseMsg, "error");
          reject();
          setIsLoader(false);
        }
      })
      .catch((error) => {
        handleSnackOpen("Exception : " + error, "error");
        reject();
        setIsLoader(false);
      });
  };
  function ExportToExcel() {
    const dataToDownload = tableRef?.current?.dataManager?.searchedData.map(
      (row) => {
        return {
            businessSubSegment: row.businessSubSegment,
            businessSegment: row.bsegment,
            businessUnit: row.bunit,     
        };
      }
    );
    let headers = ["Business Sub-Segment", "Business Segment", "Business Unit"];
    //const workSheet = XLSX.utils.json_to_sheet(newData);
    let ws = XLSX.utils.book_new();
    let wb = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, [headers]);
    XLSX.utils.sheet_add_json(ws, dataToDownload, {
      origin: "A2",
      skipHeader: true,
      cellStyles: true,
    });
    ws["!cols"] = cellAdjustToContents(dataToDownload);
    XLSX.utils.book_append_sheet(wb, ws, "BusinessSubSegment");
    XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(wb, "BusinessSubSegmentMaster.xlsx");
  }
  function cellAdjustToContents(data) {
    return [
        {
            wch: Math.max(
              ...data?.map((c) =>
                c.businessSubSegment ? c.businessSubSegment?.toString()?.length : 0
              )
            ),
          },
      {
        wch: Math.max(
          ...data?.map((c) =>
            c.businessSegment ? c.businessSegment?.toString()?.length : 0
          )
        ),
      },
      {
        wch: Math.max(
          ...data?.map((c) =>
            c.businessUnit ? c.businessUnit?.toString()?.length : 0
          )
        ),
      },
    ];
  }
  function getDisableTooltip(gck,type) {
    return gck === true
      ? "Business SubSegment is already Associated with Gck"
      : type === "Edit"
      ? ""
      : "Delete";
  }
  return (
    <div>
      <div className="App">
        <Grid container spacing={1}>
                  <Grid item xs={12}>
                      {userDetails.roleId !== 10 ?
                          <MaterialTable
                              title=""
                              columns={columns}
                              tableRef={tableRef}
                              data={tableData}
                              isLoading={isLoader}
                              icons={tableIcons}
                              options={tableOptionsUser}
                              actions={actions}
                              editable={{
                                  isDeletable: (rowData) => !rowData.isreadonlybsubsegmentgck,
                                  deleteTooltip: (rowdata) => getDisableTooltip(rowdata.isreadonlybsubsegmentgck, "Delete"),
                                  onRowDelete: (oldTableData) =>
                                      new Promise((resolve, reject) => {
                                          handleRowDelete(oldTableData, resolve, reject);
                                      }),
                              }}
                          /> : <MaterialTable
                              title=""
                              columns={columns}
                              tableRef={tableRef}
                              data={tableData}
                              isLoading={isLoader}
                              icons={tableIcons}
                              options={tableOptionsUser}
                              //actions={actions}
                              
                          />}
            <Dialog
              open={openDialog}
              onClose={handleCloseDialog}
              fullWidth
              maxWidth="md"
              aria-labelledby="form-dialog-title"
            >
              <DialogContent>
                <BusinessSubSegmentForm
                  openDialog={openDialog}
                  handleCloseDialog={handleCloseDialog}
                  rowData={rowData}
                  tableData={tableData}
                  handleSubmit={handleSubmit}
                  handleUpdateData={handleUpdateData}
                  handleAddData={handleAddData}
                  bunitData={bunitData.find(
                    (x) => x.value === rowData?.bunitId
                  )}
                  bsegmentData={bsegmentData.find(
                    (x) => x.value === rowData?.bsegmentId
                  )}
                />
              </DialogContent>
            </Dialog>
            <>
              <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleClose}
              />
            </>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
